import * as React from 'react';
import { useRef, useEffect} from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import "./menuitem.css"
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ResponsiveAppBar from '../header/header.js';
import Foot from "../header/footer.js"
import { useNavigate, useParams } from "react-router-dom";
import MainFeaturedPost from './MainFeaturedPost';
import FeaturedPost from './FeaturedPost';
import Item from "./item";
import Divider from '@material-ui/core/Button';
import {Carousel } from "./carousel";
import InputLabel from '@mui/material/InputLabel';
import MuiMenuItem from "@material-ui/core/MenuItem";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { withStyles } from "@material-ui/core/styles";

const scrollToSection = (elementRef) => {
  window.scrollTo({
    top: elementRef.current.offsetTop,
    behavior: "smooth",
  });
};

const MenuItem = withStyles({
  root: {
    flexDirection: "column",
    margin: 1
  }
})(MuiMenuItem);

const theme = createTheme({
});

const TestComponent = () => {
    const navigate = useNavigate();

    return (
        <Button variant="contained"style={{
          
          backgroundColor: "#2196F3", borderRadius:"5%"
          
      }} onClick={() => navigate('/test')}>Passer le test</Button>
    );
};

const mainFeaturedPost = {
    title: 'Guide : Comment choisir le chauffe-eau électrique parfait pour votre logement ?', 
    description:
      " Découvrez comment choisir le chauffe-eau électrique idéal pour votre maison en suivant notre guide complet et pratique.",
    image: 'https://www.espace-aubade.fr/uploads/fiches-conseils/part/630x410/fiche-conseils-bien-choisir-chauffe-eau-instantane-02.jpg',
    imageText: 'main image description',
    url: "https://www.simucho.fr/chauffe_eau/chauffe-eau_electrique/46",
    linkText: 'Continuer à lire…',
  };
  
  const featuredPosts = [
    {
      title: 'Guide : Comment choisir le meilleur chauffe-eau thermodynamique',
      description:
        " Optimisez votre confort et économisez sur vos factures d'énergie grâce à notre guide pour choisir le meilleur chauffe-eau thermodynamique pour votre maison.",
      image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ5ciQZCDq-c669FMJSDwnLuTVK1aJHbLFQeIv4FxU8yfGbJmPMxkveVK67Ccn6IJunKz0&usqp=CAU',
      imageText: 'main image description',
      url: "https://www.simucho.fr/chauffe_eau/chauffe_eau_thermodynamique/5",
      linkText: 'Continuer à lire…',
    },
    {
      title: 'Guide : Comment fonctionne un chauffe-eau solaire (CESI)',
      description:
        "Découvrez les principes de base de la technologie solaire thermique pour l'eau chaude sanitaire dans notre guide complet sur le fonctionnement d'un chauffe-eau solaire (CESI).",
      image: 'https://www.papillonjaune.fr/images/chauffeeausolaire/cesi-toitures.jpg',
      url: "https://www.simucho.fr/chauffe_eau/chauffe_eau_solaire/2",
      imageLabel: 'Continuer à lire…',
    },
  ];


export default function ChauffeEau() {
  let {id} = useParams();
  const souscategorie = useRef(null);
  const [souscate, setSouscate] = React.useState(id);
  const [newcate, setNewcate]= React.useState(id)


   const handleChange = (event) => {
    
    setSouscate(event.target.value);
    setNewcate(souscate)
   
  };

  function Carou() {
    
    useEffect(() => {
     
    }, [newcate,souscate,id]); 
    // <- add empty brackets here
  
    return <Carousel souscategorie={newcate} categorie={"chauffe_eau"} ></Carousel>;
  }
  
  useEffect(() => {
      
      setNewcate(souscate)
      
     
  }, [souscate]); 

  useEffect(() => {
      
    setNewcate(id)
    
   
}, [id]); 

  return (
    <ThemeProvider theme={theme}>
   
      <AppBar position="relative">
        <ResponsiveAppBar></ResponsiveAppBar>
      </AppBar>
      <main>
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: '#F4F9F9',
            pt: 8,
            pb: 6,
          }}
        >
          <Box
          sx={{
            marginLeft: "10%",
            width:"80%",
            pt: 5,
            pb: 6,
          }}
        >
        
          <MainFeaturedPost post={mainFeaturedPost} />
          <Grid container  spacing={4} >
          
            {featuredPosts.map((post) => (
             
              <FeaturedPost key={post.title} post={post} />
             
            ))}
         
          </Grid>
          </Box>
          <Stack
              sx={{ pt: 7 }}
              direction="row"
              spacing={2}
              display= "flex" alignItems="center" justifyContent="center"
            >
               <Divider
          flexItem
          
          style={{backgroundColor: "#1976D2", width:'50%',height:"50%" }} > </Divider>
        <Button variant="outlined" size="large"  style={{ borderRadius:"5%" }} onClick={() => scrollToSection(souscategorie)}>  Nos Derniers Articles Par Sous-Categorie </Button>
        <Divider
          flexItem
          style={{backgroundColor: "#1976D2", width:'50%', height:"50%"  }} > </Divider>
        </Stack>
          <Box
          sx={{
            marginLeft: "5%",
            width:"90%",
            pt: 5,
            pb: 6,
          }}
          ref={souscategorie} 
         
        >
        <Box className="box1"   sx={{ minWidth: 50, maxWidth:180,  }} >
       
      <FormControl className="choice">
        <InputLabel>Sous-Catégorie</InputLabel>
        <Select
          value={id != undefined ? newcate : "chauffe_eau_thermodynamique"}
          onChange={handleChange}
          autoWidth
          
        
        >
        

         
        <MenuItem  value={"chauffe_eau_thermodynamique"}>Chauffe-Eau Thermodynamique</MenuItem>
          <MenuItem  value={'chauffe_eau_gaz'}>Chauffe-Eau Gaz</MenuItem>
          <MenuItem   value={'chauffe_eau_electrique'}>Chauffe-Eau Electrique</MenuItem>
          <MenuItem   value={'chauffe_eau_solaire'}>Chauffe-Eau Solaire</MenuItem>
       
        </Select>
      </FormControl >
      </Box>
          <Carou ></Carou>
          
       </Box>
        </Box>
      </main>
      {/* Footer */}
      <Box   component="footer">
        <Foot></Foot>
      </Box>
      {/* End footer */}
    </ThemeProvider>
  );
}