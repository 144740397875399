import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(Type, MaPrimeRenov, EstimationsCEE, Total) {
  return { Type, MaPrimeRenov, EstimationsCEE, Total};
}

const rows = [
  createData('Chaudières à granulés', '5000' , "2730", "6730"),
  createData('Chaudières à bûches', "4000", "2730", "5730"),
  createData('Pompes à chaleur air/eau', "3000", "2730", "4730"),
  createData('Poêles à granulés', "1500", "550", "2050"),
  createData('Poêles à bûches',"1500", "50", "1550"),
  createData('Foyers fermés, inserts',"800", "550", "1350"),
  createData('Chaudières à gaz très haute performance',"0", "200", "1400"),
  createData('Chauffe-eau thermodynamiques',"400", "90", "490"),
  createData('Pompes à chaleur air/air',"0", "290", "290"),
];

export default function CustomizedTables() {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell> Ma Prime Renov' Violet </StyledTableCell>
            <StyledTableCell align="right">MaPrimeRenov</StyledTableCell>
            <StyledTableCell align="right">EstimationsCEE </StyledTableCell>
            <StyledTableCell align="right">Total</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.Type}>
              <StyledTableCell component="th" scope="row">
                {row.Type}
              </StyledTableCell>
              <StyledTableCell align="right">{row.MaPrimeRenov}</StyledTableCell>
              <StyledTableCell align="right">{row.EstimationsCEE}</StyledTableCell>
              <StyledTableCell align="right">{row.Total}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}