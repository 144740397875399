import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Drawer from "@material-ui/core/Drawer";
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@material-ui/core/Button';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { useNavigate } from "react-router-dom";
import ButtonGroup from '@mui/material/ButtonGroup';
import  {Link} from "react-router-dom";
import { StyledEngineProvider } from "@mui/material/styles";
import Fade from '@mui/material/Fade';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import logosimucho from "./../logosimucho.png";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MuiListItem from "@material-ui/core/ListItem";
import SimpleMenu from './mouse';
import SimpleClickMenu from "./click.js"
/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1, color: "#1976D2"}} /> */

const pages = ['APropos', 'Contact',"Articles"];

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [open, setOpen] = React.useState(true);
  let navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    console.log("Bonj")
    setAnchorElNav(event.currentTarget);
    
  };

 

  const List = withStyles({
    root: {
      "&$selected": {
        backgroundColor: "white",
        color: "#1976D2",
        "& .MuiListItemIcon-root": {
          color: "#1976D2"
        }
      },
      "&$selected:hover": {
        backgroundColor: "white",
        color: "#1976D2",
        "& .MuiListItemIcon-root": {
          color: "#1976D2"
        }
      },
      "&:hover": {
        backgroundColor: "white",
        color: "#1976D2",
        "& .MuiListItemIcon-root": {
          color: "#1976D2"
        }
      }
    },
    selected: {}
  })(MuiListItem);

  const Listo = withStyles({
    
  })(MuiListItem);

  const flexContainer = {
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
    BackgroundColor: "#fff"
  };
  
  const flexContainrow = {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    BackgroundColor: "#fff"
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
    console.log("Bonj1")
    
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleClick = () => {
    setOpen(!open);
  };
  return (

    <StyledEngineProvider injectFirst>
    <AppBar position="static" sx={{ bgcolor: "#fff" }}>
   
      <Container maxWidth="xl">
        <Toolbar disableGutters>
        <div className="containerlogobis">
        <a  href="/">
          <img className="logohead" src={logosimucho}></img>
        </a>
        </div>
          <Box sx={{flexGrow: 1, display: { xs: 'flex', md: 'none' }}}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              
              style={{
                
                color: "#1976D2", borderRadius:"30%"
                
            }}
            >
              <MenuIcon />
            </IconButton>
            
               <Drawer className="marge"
              id="menu-appbar"
              anchorEl={anchorElNav}
              TransitionComponent={Fade}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                 
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: {  xs: 'block', md: 'none'},
              }}
             >
              
              {pages.map((page) => (page == "APropos" || page == "Contact") ?
                (
                <Box m={1.5} pt={0.5} display="flex" flexDirection="column">
                <MenuItem key={page}  onClick={handleCloseNavMenu} >

                  <Link to={"/"+page} style={{ textDecoration: 'none' }}>
                  
                  <Typography
            variant="h6"
            
            sx={{
             
              color: '#1976D2',
             
            }}
          >
            <b>
            {page}
            </b>
          </Typography>
              </Link>
              
                </MenuItem>
                </Box>
          
              ):
              
              <Box m={1.5}  pt={0.5}>
                <MenuItem key={page}  >
                  <Listo style={flexContainrow}>
                <ListItemButton onClick={handleClick}>
    
       
          <ListItemText>
          <Typography
            variant="h6"
            
            sx={{
             
              color: '#1976D2',
             
            }}
          >
            <b>
            Articles
            </b>
            </Typography>
            </ListItemText>
        {open ?  <ExpandMore /> : <ExpandLess /> }
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Listo component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }}>
          
            <ListItemText >
           
            <Typography
            variant="h7"
            
            sx={{
             
              color: '#1976D2',
             
            }}
          >
             <SimpleClickMenu tags={[{ id: "Pompe à Chaleur Air-Eau", url: "/pompe_a_chaleur/pompe_à_chaleur_air_eau" },{id: "Pompe à Chaleur Air-Air",url: "/pompe_a_chaleur/pompe_à_chaleur_air_air"},{id: "Pompe à Chaleur Geothermique",url: "/pompe_a_chaleur/pompe_à_chaleur_geothermique"}]} title="Pompe à Chaleur"></SimpleClickMenu>
            </Typography>
       
            </ListItemText>
           </ListItemButton>
            </Listo>

            <Listo>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemText>

            <Typography
            variant="h7"
             sx={{ color: '#1976D2'}}>
            <SimpleClickMenu tags={[{ id: "Poêle & Inserts à Bois ou Hybrides", url: "/poele/poêle_et_inserts_à_bois" },{id: "Poêle & Inserts à Pellets",url: "/poele/poêle_et_inserts_à_pellets"},{id: "Poêle de Masse, Hydro, Canalisable",url: "/poele/poêle_hydro"}]}  title="Poêle"></SimpleClickMenu>
            </Typography>
            </ListItemText>
            </ListItemButton>
            </Listo>

          <Listo>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemText>
            
            <Typography
            variant="h7"
            
            sx={{
             
              color: '#1976D2',
             
            }}
          >
            <SimpleClickMenu  tags={[{ id: "Chauffe-Eau Thermodynamique", url: "/chauffe_eau/chauffe_eau_thermodynamique" },{id: "Chauffe-Eau Gaz",url: "/chauffe_eau/chauffe_eau_gaz"},{id: "Chauffe-Eau Electrique",url: "/chauffe_eau/chauffe-eau_electrique"},{id: "Chauffe-Eau Solaire",url: "/chauffe_eau/chauffe_eau_solaire"}]}   title="Chauffe-Eau"></SimpleClickMenu>
            </Typography>
       
            </ListItemText>
            </ListItemButton>
            </Listo>

            <Listo>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemText>
          
            <Typography
            variant="h7"
            
            sx={{
             
              color: '#1976D2',
             
            }}
          >
             <SimpleClickMenu tags={[{ id: "Chaudière à Bois", url: "/chaudiere/chaudière_à_bois" },{id: "Chaudière à Pellets",url: "/chaudiere/chaudière_à_pellets"},{id: "Chaudière Hybride / Gaz",url: "/chaudiere/chaudière_hybride_et_gaz"}]}  title="Chaudière"></SimpleClickMenu>
            </Typography>
            </ListItemText>
            </ListItemButton>
            </Listo>

          <Listo>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemText>
           
            <Typography
            variant="h7"
            
            sx={{
             
              color: '#1976D2',
             
            }}
          >
            <SimpleClickMenu tags={[{ id: "Convecteur Electrique", url: "/radiateur/convecteur_electrique" },{id: "Radiateur à Eau",url: "/radiateur/radiateur_à_eau"}]}  title="Radiateur"></SimpleClickMenu>
            </Typography>
          
            </ListItemText>
            </ListItemButton>
          </Listo>

      </Collapse>
      </Listo>
                </MenuItem>
                </Box>
            
              )}
              
          </Drawer>
          
            
          </Box>
          <div className='containerlogo'>
          <a href='/'>
          <img className='logoheadersmallscreen' src={logosimucho}></img>
          </a>
            </div>
          <Box sx={{flexGrow: 1, display: { xs: 'none', md: 'flex' }}}>
            
            {pages.map((page) =>  (page == "APropos" || page == "Contact") ?
                (
            
              <Box  ml={1.5} pt={0.5} display="inline-flex" >
            
              <Link to={"/"+page} style={{ textDecoration: 'none' }}>
              <Typography
            variant="h6"
            
            sx={{
             
              color: '#1976D2',
             
            }}
          >
            {page}
          </Typography>
              </Link>
             
              </Box>

               ):
               <Box ml={1.5}  >
               <List
               style={flexContainer}
             >
             
                 
                
                 <ListItemButton disableGutters onClick={handleClick}>
     
        
           <ListItemText>
           <Typography variant="h6" sx={{ color: '#1976D2'}} >
             Articles
             </Typography>
             </ListItemText>
         {open ?  <ExpandMore /> : <ExpandLess /> }
       </ListItemButton>
       <Collapse in={open} timeout="auto" unmountOnExit>
        
           <ListItemButton disableGutters >
           
             <ListItemText >
             <Link to={"/pompe_a_chaleur"} style={{ textDecoration: 'none' }}>
             <Typography
             variant="h7"
             sx={{
              
               color: '#1976D2',
              
             }}
           >
             <SimpleMenu tags={[{ id: "Pompe à Chaleur Air-Eau", url: "/pompe_a_chaleur/pompe_à_chaleur_air_eau" },{id: "Pompe à Chaleur Air-Air",url: "/pompe_a_chaleur/pompe_à_chaleur_air_air"},{id: "Pompe à Chaleur Geothermique",url: "/pompe_a_chaleur/pompe_à_chaleur_geothermique"}]} title="Pompe à Chaleur"></SimpleMenu>
             </Typography>
             </Link>
             </ListItemText>
            </ListItemButton>
          
           <ListItemButton >
             <ListItemText>
             <Link to={"/poele"} style={{ textDecoration: 'none' }}>
             <Typography
             variant="h7"
             
             sx={{
              
               color: '#1976D2',
              
             }}
           >
            <SimpleMenu tags={[{ id: "Poêle & Inserts à Bois ou Hybrides", url: "/poele/poêle_et_inserts_à_bois" },{id: "Poêle & Inserts à Pellets",url: "/poele/poêle_et_inserts_à_pellets"},{id: "Poêle de Masse, Hydro, Canalisable",url: "/poele/poêle_hydro"}]}  title="Poêle & Inserts"></SimpleMenu>
             
             </Typography>
             </Link>
             </ListItemText>
             </ListItemButton>
           
 
           <ListItemButton >
             <ListItemText>
             <Link to={"/chauffe_eau"} style={{ textDecoration: 'none' }}>
             <Typography
             variant="h7"
             sx={{
              
               color: '#1976D2',
              
             }}
           >
            <SimpleMenu  tags={[{ id: "Chauffe-Eau Thermodynamique", url: "/chauffe_eau/chauffe_eau_thermodynamique" },{id: "Chauffe-Eau Gaz",url: "/chauffe_eau/chauffe_eau_gaz"},{id: "Chauffe-Eau Electrique",url: "/chauffe_eau/chauffe-eau_electrique"},{id: "Chauffe-Eau Solaire",url: "/chauffe_eau/chauffe_eau_solaire"}]}     title="Chauffe-Eau"></SimpleMenu>
             
             </Typography>
             </Link>
             </ListItemText>
             </ListItemButton>
           
           <ListItemButton >
             <ListItemText>
             <Link to={"/chaudiere"} style={{ textDecoration: 'none' }}>
             <Typography
             variant="h7"
             sx={{
              
               color: '#1976D2',
              
             }}
           >
             <SimpleMenu tags={[{ id: "Chaudière à Bois", url: "/chaudiere/chaudière_à_bois" },{id: "Chaudière à Pellets",url: "/chaudiere/chaudière_à_pellets"},{id: "Chaudière Hybride / Gaz",url: "/chaudiere/chaudière_hybride_et_gaz"}]}   title="Chaudière"></SimpleMenu>
             
             </Typography>
             </Link>
             </ListItemText>
             </ListItemButton>
             
           <ListItemButton >
             <ListItemText>
             <Link to={"/radiateur"} style={{ textDecoration: 'none' }}>
             <Typography
             variant="h7"
             sx={{
              
               color: '#1976D2',
              
             }}
           >
            <SimpleMenu tags={[{ id: "Convecteur Electrique", url: "/radiateur/convecteur_electrique" },{id: "Radiateur à Eau",url: "/radiateur/radiateur_à_eau"}]}  title="Radiateur"></SimpleMenu>
            
             </Typography>
             </Link>
             </ListItemText>
             </ListItemButton>
 
       </Collapse>
      
                
                
            </List>
            </Box>
                 )}

           </Box>
      


          <Box  pt={0.5}  display="flex" sx={{ flexDirection: 'row-reverse' }}>
          <a  href="https://www.linkedin.com/in/benjamin-audidier-8806081a1/" ><LinkedInIcon style={{color: "#1976D2", marginLeft:"55%"}}></LinkedInIcon> </a>
          <a  href="http://instagram.com" ><InstagramIcon  style={{color: "#1976D2", marginLeft:"25%", marginRight:"25%"}}></InstagramIcon></a>
          <a  href="https://twitter.com/benjam1audidier?lang=fr" ><TwitterIcon style={{color: "#1976D2", marginRight:"25%"}}></TwitterIcon></a>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            
            <Menu
              sx={{ mt: '45px'}}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
             
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
    </StyledEngineProvider>
  );
};
export default ResponsiveAppBar;