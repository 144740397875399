import React, { useState, useEffect } from 'react';
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from '@mui/material/Typography';
import  {Link} from "react-router-dom";
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MuiListItem from "@material-ui/core/ListItem";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

const Listo = withStyles({
    
})(MuiListItem);


const flexContainrow = {
  display: 'flex',
  flexDirection: 'column',
  padding: 0,
  BackgroundColor: "#fff"
};



function SimpleClickMenu(props) {
 
  const [op, setOp] = React.useState(null);

  
  

  return (
    <Box m={0.25}  pt={0.25}>
           
                  <Listo style={flexContainrow}>
                <ListItemButton onClick={()=>{setOp(!(op))}}>
    
       
          <ListItemText>
          <Typography
            variant="h7"
            
            sx={{
             
              color: '#1976D2',
             
            }}
          >
             <b>
            {props.title}
            </b>
            </Typography>
            </ListItemText>
            {op ?  <ExpandMore /> : <ExpandLess /> }
      </ListItemButton>

      
      <Collapse in={op} timeout="auto" unmountOnExit>

            {props.tags.map((subArray, index) => {
          return (
            <Listo>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemText>
              <Link to={subArray.url} style={{ textDecoration: 'none' }}>
              <Typography
              variant="h8"
              
              sx={{
               
                color: '#1976D2',
               
              }}
            >
             
              {subArray.id}
              </Typography>
              </Link>
              </ListItemText>
              </ListItemButton>
              </Listo>
             
              )})}
     
      </Collapse>
      </Listo>
               
                </Box>
       
       
  );
}

export default SimpleClickMenu;
