import React, { useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { Button } from "@material-ui/core";
import'./Style.css'
import Primeble from './primeb.js';
import PrimeJone from './primej.js';
import Primeviolet from './primev.js';
import Primerosee from './primer.js';
import ResponsiveAppBar from './header/header.js';
import Foot from "./header/footer.js"
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';
import InfoIcon from '@mui/icons-material/Info';
import Typography from '@mui/material/Typography';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import SimpleSlide from "./ventilation.js";
import SimpleBois from "./ventilationbois";
import SimpleThermo from "./ventilationthermo";
import SimplePompe from "./ventilationpompe.js"
import SimpleSolaire from "./ventilationsolaire.js";
import SimplePoele from "./ventilationpoele.js";
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import poele from "./poele.PNG";
import Stack from '@mui/material/Stack';
import  {Link} from "react-router-dom";
import Primeb from './primebleue.js';
import Primej from './primejaune.js';
import Primer from './primerose.js';
import Primev from './primeviolet.js';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Divider} from '@mui/material';
import { fontSize } from '@mui/system';
import Newcarousel from "./ktgories/newitem.js"


const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));


const Insert = (props) => {
  
  const[ins, setIns] = useState([])
  var temp = +localStorage.getItem("temperature")
 
    
   useEffect(() => {
     fetch("https://www.simucho.fr/insert")
     .then(response => response.json())
        
     .then(data => setIns(data))
   },[])
 
   return (
     <div >
        { Array.isArray(ins.Insert)
         ? ins.Insert.map(tp => (
             <div key={tp.Longueur}>
               {(tp.Longueur == (props.Longueur)) && (
                 <div> 
                  
                  <h3> Notre estimation des dimensions de votre cheminée actuelle 
                  <Tooltip title={ <div>Si vous souhaitez installer un insert, celui-ci devra etre de meme dimension ou plus petit que votre foyer de cheminee actuel. </div>} placement="top" enterTouchDelay={0}>
             <InfoIcon style={{color: "#1976D2"}}></InfoIcon></Tooltip> </h3> 
                  <Typography align="center">
                   Longueur : {props.Longueur} cm <br/>
                   Hauteur : {tp.Hauteur} cm <br/>
                   Profondeur : {tp.Profondeur} cm <br/>
                  </Typography>
                  </div> 

               )} 
     </div>
     
 
     ))
   : null}
   </div>
   );
 }

const Foyer = (props) => {
  
  const[foy, setFoy] = useState([]);
  const[foyer, setFoyer] = useState([]);
  const[url, setUrl] = useState([]);
  const[rendement,setRendement] = useState([]);
  const[emissionCO,setEmissionco] = useState([]);
  const[emissionNox,setEmissionnox] = useState([]);
  const[tog, setTog] = useState(0);
  var deperd = +localStorage.getItem("deperditions");
  
 
  useEffect(() => {
   
     fetch("https://www.simucho.fr/insert_data")
     .then(response => response.json())
     .then(data => setFoy(data))
    },[])
     
     
   
 
   return (
     <div >
      
        { Array.isArray(foy)
         ? foy.map(tp => (
             <div key={tp.name}>
              
               {(tog==0) && (tp.type == 'Foyer ferme/Insert - bois-buches') && (tp.puissance * 1000 > deperd && tp.puissance * 900 < deperd) && (
                 <div> 
                  {setFoyer(foyer => [...foyer, tp.name])}
                  {setUrl(url => [...url, tp.url])}
                  {setRendement(rendement => [...rendement, tp.rendement])}
                  {setEmissionco(emissionCO => [...emissionCO, tp.emissionCO])}
                  {setEmissionnox(emissionNox => [...emissionNox, tp.emissionNox])}
                  
                  
                  </div>
                  
                 
                
               )} 
               {(tp.name == "WODTKE SPENCER") && tog < 2 && (
                 <div> 
                  {setTog(tog+1)}
                  {console.log("foyer")}
                  {console.log(foyer)}
                  
                  
                  
                  </div>
                  
                 
                
               )} 

             
              
      
     </div>
     
     
     ))
   : null}
   {(!!foyer && foyer.length > 1) &&(
   <div>
    <h2 > Foyers
      <Tooltip title={ <div>1er : Marque & Produit <br/> 2ème: Rendement <br/> 3ème : Emission de CO <br/> 4ème : Emission de NOx</div>} placement="top" enterTouchDelay={0}>
             <InfoIcon style={{color: "#1976D2"}}></InfoIcon>
          </Tooltip> </h2>
    <Newcarousel  name = {foyer} photo = {url} prop1 = {rendement} prop2 = {emissionCO} prop3 = {emissionNox}></Newcarousel>
    </div>
    )}
   </div>
   );
 }

 const Cumulus = (props) => {
  
  const[cumu, setCumu] = useState([]);
  const[name, setName] = useState([]);
  const[url, setUrl] = useState([]);
  const[brand,setBrand] = useState([]);
  const[garantie,setGarantie] = useState([]);
  const[resistance,setResistance] = useState([]);
  const[tog, setTog] = useState(0);
  var volume = +localStorage.getItem("volumeecs60");
  
 
  useEffect(() => {
   
     fetch("https://www.simucho.fr/cumulus")
     .then(response => response.json())
     .then(data => setCumu(data))
    },[])
     
     
   
 
   return (
     <div >
      
        { Array.isArray(cumu)
         ? cumu.map(tp => (
             <div key={tp.name}>
              
               {(tog==0) && (tp.ECS > volume && tp.ECS * 0.80 < volume) && (
                 <div> 
                  {setName(name => [...name, tp.name])}
                  {setUrl(url => [...url, tp.url])}
                  {setBrand(brand => [...brand, tp.Brand])}
                  {setGarantie(garantie => [...garantie, tp.Garantie])}
                  {setResistance(resistance => [...resistance, tp.resistance])}
                  
                  
                  </div>
                  
                 
                
               )} 
               {(tp.name == "STABLE 570 MM MONOPHASE EU CLASSE C") && tog < 2 && (
                 <div> 
                  {setTog(tog+1)}
                  {console.log(tog)}
                  {console.log(name)}
                  
                  
                  
                  </div>
                  
                 
                
               )} 

             
              
      
     </div>
     
     
     ))
   : null}
   {(!!name && name.length > 1) && (
   <div>
     <h2 > Cumulus : 
      <Tooltip title={ <div>1er : Produit <br/> 2ème: Marque <br/> 3ème: Garantie <br/> 4ème : Type de Resistance </div>} placement="top" enterTouchDelay={0}>
             <InfoIcon style={{color: "#1976D2"}}></InfoIcon>
          </Tooltip> </h2>
    <Newcarousel name = {name} photo = {url} prop1= {brand} prop2 = {garantie} prop3= {resistance}></Newcarousel>
    </div>
    )}
   </div>
   );
 }

 const Cei = (props) => {
  
  const[cumu, setCumu] = useState([]);
  const[name, setName] = useState([]);
  const[url, setUrl] = useState([]);
  const[brand,setBrand] = useState([]);
  const[garantie,setGarantie] = useState([]);
  const[resistance,setResistance] = useState([]);
  const[tog, setTog] = useState(0);
  
 
  useEffect(() => {
   
     fetch("https://www.simucho.fr/cumulus")
     .then(response => response.json())
     .then(data => setCumu(data))
    },[])
     
     
   
 
   return (
     <div >
      
        { Array.isArray(cumu)
         ? cumu.map(tp => (
             <div key={tp.name}>
              
               {(tog==0) && (tp.utilisation == props.utilisation) && (tp.Type != "Chauffe-bain gaz naturel") && (
                 <div> 
                  {setName(name => [...name, tp.name])}
                  {setUrl(url => [...url, tp.url])}
                  {setBrand(brand => [...brand, tp.Brand])}
                  {setGarantie(garantie => [...garantie, tp.Garantie])}
                  {setResistance(resistance => [...resistance, tp.debit])}
                  
                  
                  </div>
                  
                 
                
               )} 

               {(tog==0) && (tp.utilisation == props.utilisation) && (localStorage.hasOwnProperty("question15") && localStorage.getItem("question15") == tp.raccord) && (props.cuisine == "Oui" && ((props.utilisation == "douche" && props.proximite == "Oui") || props.utilisation == "lavabo") ) && (
                 <div> 
                  {setName(name => [...name, tp.name])}
                  {setUrl(url => [...url, tp.url])}
                  {setBrand(brand => [...brand, tp.Brand])}
                  {setGarantie(garantie => [...garantie, tp.raccord])}
                  {setResistance(resistance => [...resistance, tp.debit])}
                  
                  
                  </div>
                  
                 
                
               )} 
               {(tp.name == "STABLE 570 MM MONOPHASE EU CLASSE C") && tog < 2 && (
                 <div> 
                  {setTog(tog+1)}
                  {console.log(tog)}
                  {console.log(name)}
                  
                  
                  
                  </div>
                  
                 
                
               )} 

             
              
      
     </div>
     
     
     ))
   : null}
   {(!!name && name.length > 1) && (
   <div>
     <h2 > Chauffe-eau instantané: 
      <Tooltip title={ <div>1er : Produit <br/> 2ème: Marque <br/> 3ème: Garantie ou raccord fumisterie (si chauffe-bain Gaz) <br/> 4ème : Débit </div>} placement="top" enterTouchDelay={0}>
             <InfoIcon style={{color: "#1976D2"}}></InfoIcon>
          </Tooltip> </h2>
    <Newcarousel name = {name} photo = {url} prop1 = {brand} prop2 = {garantie} prop3 = {resistance}></Newcarousel>
    </div>
    )}
   </div>
   );
 }

 

 const Gazcondens = (props) => {
  
  const[gaz, setGaz] = useState([]);
  const[name, setName] = useState([]);
  const[url, setUrl] = useState([]);
  const[brand,setBrand] = useState([]);
  const[garantie,setGarantie] = useState([]);
  const[pose,setPose] = useState([]);
  const[tog, setTog] = useState(0);
  var deperd = +localStorage.getItem("deperditions");
  var debit = +localStorage.getItem("debit");
  
 
  useEffect(() => {
   
     fetch("https://www.simucho.fr/chaudieregas")
     .then(response => response.json())
     .then(data => setGaz(data))
    },[])
     
     
   
 
   return (
     <div >
      
        { Array.isArray(gaz)
         ? gaz.map(tp => (
             <div key={tp.name}>
              
               {(tog==0) && (props.question1 === 'Eau Chaude Chauffage') && (tp.prod != "Chauffage seul option bal on separe") && (tp.puissance * 1000 > deperd) && (tp.debt > debit && tp.debt * 0.7 < debit) && (
                 <div> 
                  {setName(name => [...name, tp.name])}
                  {setUrl(url => [...url, tp.url])}
                  {setBrand(brand => [...brand, tp.Brand])}
                  {setGarantie(garantie => [...garantie, tp.Garantie])}
                  {setPose(pose => [...pose, tp.pose])}
                  
                  
                  </div>
                  
                 
                
               )} 
               {(tog==0) && (props.question1 === 'Chauffage') && (tp.prod == "Chauffage seul option bal on separe") && (tp.puissance * 1000 > deperd && tp.puissance * 800 < deperd )  && (
                 <div> 
                  {setName(name => [...name, tp.name])}
                  {setUrl(url => [...url, tp.url])}
                  {setBrand(brand => [...brand, tp.Brand])}
                  {setGarantie(garantie => [...garantie, tp.Garantie])}
                  {setPose(pose => [...pose, tp.pose])}
                  
                  
                  </div>
                  
                 
                
               )} 
               
               {(tp.name == "CHAUDIERE A CONDENSATION Modulens G 25/V 100 HL") && tog < 2 && (
                 <div> 
                  {setTog(tog+1)}
                  {console.log(tog)}
                  {console.log('Bonj')}
                  {console.log(name)}
                  
                  
                  
                  </div>
                  
                 
                
               )} 

             
              
      
     </div>
     
     
     ))
   : null}
   {(!!name && name.length > 1) &&(
   <div>
    <h2 > Chaudiere Gaz Condensation  
      <Tooltip title={ <div>1er : Produit <br/> 2ème: Marque <br/> 3ème: Garantie <br/> 4ème : Pose </div>} placement="top" enterTouchDelay={0}>
             <InfoIcon style={{color: "#1976D2"}}></InfoIcon>
          </Tooltip> </h2>
    <Newcarousel  name = {name} photo = {url} prop1 = {brand} prop2 = {garantie} prop3 = {pose}></Newcarousel>
    </div>
    )}
   
    
   </div>
   );
 }

 const Basnox = (props) => {
  
  const[bas, setBas] = useState([]);
  const[name, setName] = useState([]);
  const[url, setUrl] = useState([]);
  const[brand,setBrand] = useState([]);
  const[garantie,setGarantie] = useState([]);
  const[fumisterie,setFumisterie] = useState([]);
  const[tog, setTog] = useState(0);
  var deperd = +localStorage.getItem("deperditions");
  var debit = +localStorage.getItem("debit");
  
 
  useEffect(() => {
   
     fetch("https://www.simucho.fr/chaudbasnox")
     .then(response => response.json())
     .then(data => setBas(data))
    },[])
     
     
   
 
   return (
     <div >
      
        { Array.isArray(bas)
         ? bas.map(tp => (
             <div key={tp.name}>
              
               {(tog==0) && (props.question1 === 'Eau Chaude Chauffage') && (tp.prod != "Chauffage Seul") && (props.fumi == tp.fumisterie) && (tp.puissance * 1000 > deperd) && (tp.debit > debit && tp.debit * 0.7 < debit) && (
                 <div> 
                  {setName(name => [...name, tp.name])}
                  {setUrl(url => [...url, tp.url])}
                  {setBrand(brand => [...brand, tp.Brand])}
                  {setGarantie(garantie => [...garantie, tp.Garantie])}
                  {setFumisterie(fumisterie => [...fumisterie, tp.fumisterie])}
                  
                  
                  </div>
                  
                 
                
               )} 
               {(tog==0) && (props.question1 === 'Chauffage') && (tp.prod == "Chauffage Seul") && (props.fumi == tp.fumisterie) && (tp.puissance * 1000 > deperd && tp.puissance * 800 < deperd )  && (
                 <div> 
                  {setName(name => [...name, tp.name])}
                  {setUrl(url => [...url, tp.url])}
                  {setBrand(brand => [...brand, tp.Brand])}
                  {setGarantie(garantie => [...garantie, tp.Garantie])}
                  {setFumisterie(fumisterie => [...fumisterie, tp.fumisterie])}
                  
                  
                  </div>
                  
                 
                
               )} 
               
               {(tp.name == "CHAUDIeRE MURALE MIXTE CLAS B ONE PLUS 35") && tog < 2 && (
                 <div> 
                  {setTog(tog+1)}
                  {console.log(tog)}
                  {console.log('Bonj')}
                  {console.log(name)}
                  
                  
                  
                  </div>
                  
                 
                
               )} 

             
              
      
     </div>
     
     
     ))
   : null}
   {(!!name && name.length > 1) &&(
   <div>
    <h2 > Chaudiere Gaz Bas Nox  
      <Tooltip title={ <div>1er : Produit <br/> 2ème: Marque <br/> 3ème: Garantie <br/> 4ème : Pose </div>} placement="top" enterTouchDelay={0}>
             <InfoIcon style={{color: "#1976D2"}}></InfoIcon>
          </Tooltip> </h2>
    <Newcarousel  name = {name} photo = {url} prop1 = {brand} prop2 = {garantie} prop3 = {fumisterie}></Newcarousel>
    </div>
    )}
   
    
   </div>
   );
 }

 const Pac = (props) => {
  
  const[pac, setPac] = useState([]);
  const[name, setName] = useState([]);
  const[url, setUrl] = useState([]);
  const[brand,setBrand] = useState([]);
  const[units,setUnits] = useState([]);
  const[etas,setEtas] = useState([]);
  const[tog, setTog] = useState(0);
  var deperd = +localStorage.getItem("deperditions");
  var volume = +localStorage.getItem("volumeecs60");
  
 
  useEffect(() => {
   
     fetch("https://www.simucho.fr/pac")
     .then(response => response.json())
     .then(data => setPac(data))
    },[])
     
     
   
 
   return (
     <div >
      
        { Array.isArray(pac)
         ? pac.map(tp => (
             <div key={tp.name}>
              
               {(tog==0) && (props.question1 === 'Eau Chaude Chauffage') && (tp.type !== "CET") && (tp.prod == "Heating +  DHW + low temp") && (tp.Prated * 1000 > deperd) && (tp.ecs60 > volume && tp.ecs60 * 0.7 < volume) && (!!props.question16 && props.question16 > 3) && ((props.radiateur =="Radiateur BT ou PC" && tp.WTOL < 60) || (props.radiateur =="Radiateur HT" && tp.WTOL >= 60)) && (
                 <div> 
                  {setName(name => [...name, tp.name])}
                  {setUrl(url => [...url, tp.url])}
                  {setBrand(brand => [...brand, tp.Brand])}
                  {setUnits(units => [...units, tp.Units])}
                  {setEtas(etas => [...etas, tp.ETAS])}
                  
                  
                  </div>
                  
                 
                
               )} 
               {(tog==0) && (props.question1 === 'Eau Chaude Chauffage') && (tp.type !== "CET") && (tp.prod == "Heating +  DHW + low temp") && (tp.Prated * 1000 > deperd) && (!!props.question16 && props.question16 <= 3) && ((props.radiateur =="Radiateur BT ou PC" && tp.WTOL < 60) || (props.radiateur =="Radiateur HT" && tp.WTOL >= 60)) && (
                 <div> 
                  {setName(name => [...name, tp.name])}
                  {setUrl(url => [...url, tp.url])}
                  {setBrand(brand => [...brand, tp.Brand])}
                  {setUnits(units => [...units, tp.Units])}
                  {setEtas(etas => [...etas, tp.ETAS])}
                  
                  
                  </div>
                  
                 
                
               )} 
               {(tog==0) && (props.question1 === 'Chauffage') && (tp.prod !== "Heating + DHW + low temp") && (tp.type !== "CET") && (tp.Prated * 1000 > deperd && tp.Prated * 850 < deperd) && ((props.radiateur =="Radiateur BT ou PC" && tp.WTOL < 60) || (props.radiateur =="Radiateur HT" && tp.WTOL >= 60)) && (
                 <div> 
                  {setName(name => [...name, tp.name])}
                  {setUrl(url => [...url, tp.url])}
                  {setBrand(brand => [...brand, tp.Brand])}
                  {setUnits(units => [...units, tp.Units])}
                  {setEtas(etas => [...etas, tp.ETAS])}
                  
                  
                  </div>
                  
                 
                
               )} 
              
               
               {(tp.name == "WWK 302") && tog < 2 && (
                 <div> 
                  {setTog(tog+1)}
                  {console.log('paccccc')}
                  {console.log(name)}
                  
                  
                  
                  </div>
                  
                 
                
               )} 

             
              
      
     </div>
     
     
     ))
   : null}
    {(!!name && name.length > 1) && (props.question1 === 'Chauffage' || props.question1 === 'Eau Chaude Chauffage') &&(
   <div>
   <h2 > Pompe a chaleur air-eau 
     <Tooltip title={ <div>1er : Produit <br/> 2ème: Marque <br/> 3ème: Unité <br/> 4ème : Efficacité Energétique Saisonnière </div>} placement="top" enterTouchDelay={0}>
            <InfoIcon style={{color: "#1976D2"}}></InfoIcon>
         </Tooltip> </h2>
   <Newcarousel  name = {name} photo = {url} prop1 = {brand} prop2 = {units} prop3 = {etas}></Newcarousel>
   </div>
    )}
 
    
   </div>
   );
 }
 const Cet = (props) => {
  
  const[pac, setPac] = useState([]);
  const[name, setName] = useState([]);
  const[url, setUrl] = useState([]);
  const[brand,setBrand] = useState([]);
  const[eff,setEfficiency] = useState([]);
  const[cop,setCop] = useState([]);
  const[tog, setTog] = useState(0);
 
  var volume = +localStorage.getItem("volumeecs60");
  
 
  useEffect(() => {
   
     fetch("https://www.simucho.fr/pac")
     .then(response => response.json())
     .then(data => setPac(data))
    },[])
     
     
   
 
   return (
     <div >
      
        { Array.isArray(pac)
         ? pac.map(tp => (
             <div key={tp.name}>
              
               
               {(tog==0) && (props.question1 === 'Eau Chaude') && (tp.type === "CET")  && (tp.ecs60  > volume && tp.ecs60 * 0.7 < volume) && (props.piece == "Non" && tp.Units == "Split") && (
                 <div> 
                  {setName(name => [...name, tp.name])}
                  {setUrl(url => [...url, tp.url])}
                  {setBrand(brand => [...brand, tp.Brand])}
                  {setEfficiency(eff => [...eff, tp.Units])}
                  {setCop(cop => [...cop, tp.COP])}
                  
                  
                  </div>
                  
                 
                
               )} 

               {(tog==0) && (props.question1 === 'Eau Chaude') && (tp.type === "CET")  && (tp.ecs60 > volume && tp.ecs60 * 0.7 < volume) && (props.piece == "Oui") && (
                 <div> 
                  {setName(name => [...name, tp.name])}
                  {setUrl(url => [...url, tp.url])}
                  {setBrand(brand => [...brand, tp.Brand])}
                  {setEfficiency(eff => [...eff, tp.Units])}
                  {setCop(cop => [...cop, tp.COP])}
                  
                  
                  </div>
                  
                 
                
               )}
               
               {(tp.name == "WWK 302") && tog < 2 && (
                 <div> 
                  {setTog(tog+1)}
                  {console.log('cet')}
                  {console.log(name)}
                  
                  
                  
                  </div>
                  
                 
                
               )} 

             
              
      
     </div>
     
     
     ))
   : null}
    
    {(!!name && name.length > 1) &&(
   <div>
    <h2 > Chauffe-eau Thermodynamique
      <Tooltip title={ <div>1er : Produit <br/> 2ème: Marque <br/> 3ème: Monobloc/Split <br/> 4ème : COP (kWh de chaleur produits / kWh d'électricité consommée) </div>} placement="top" enterTouchDelay={0}>
             <InfoIcon style={{color: "#1976D2"}}></InfoIcon>
          </Tooltip> </h2>
    <Newcarousel  name = {name} photo = {url} prop1 = {brand} prop2 = {eff} prop3 = {cop}></Newcarousel>
    </div>
    )}
    
   </div>
   );
 }

 const Bivalence = (props) => {
  
  const[pac, setPac] = useState([]);
  const[name, setName] = useState([]);
  const[url, setUrl] = useState([]);
  const[brand,setBrand] = useState([]);
  const[units,setUnits] = useState([]);
  const[etas,setEtas] = useState([]);
  const[tog, setTog] = useState(0);
  var deperd = +localStorage.getItem("deperditions");
  var volume = +localStorage.getItem("volumeecs60");
  
 
  useEffect(() => {
   
     fetch("https://www.simucho.fr/pac")
     .then(response => response.json())
     .then(data => setPac(data))
    },[])
     
     
   
 
   return (
     <div >  
      
        { Array.isArray(pac)
         ? pac.map(tp => (
             <div key={tp.name}>
              
               {(tog==0) && (props.question1 === 'Eau Chaude Chauffage') && (tp.type !== "CET") && (tp.prod == "Heating +  DHW + low temp") && ((tp.PTbiv / deperd) > 0.7 &&  (tp.PTbiv / deperd) < 0.85) && (tp.ecs60 > volume && tp.ecs60 * 0.7 < volume) && (props.chaud == "Moins de 7 ans") && ((props.radiateur =="Radiateur BT ou PC" && tp.WTOL < 60) || (props.radiateur =="Radiateur HT" && tp.WTOL >= 60) ) && (
                 <div> 
                  {setName(name => [...name, tp.name])}
                  {setUrl(url => [...url, tp.url])}
                  {setBrand(brand => [...brand, tp.Brand])}
                  {setUnits(units => [...units, tp.Units])}
                  {setEtas(etas => [...etas, tp.ETAS])}
                  
                  
                  </div>
                  
                 
                
               )} 
               {(tog==0) && (props.question1 === 'Eau Chaude Chauffage') && (tp.type !== "CET") && (tp.prod == "Heating +  DHW + low temp") && ((tp.PTbiv * 1000 / deperd) > 0.5 &&  (tp.PTbiv * 1000 / deperd) < 0.75) && (tp.ecs60 > volume && tp.ecs60 * 0.7 < volume) && (props.chaud == "Entre 7 et 10 ans") && ((props.radiateur =="Radiateur BT ou PC" && tp.WTOL < 60) || (props.radiateur =="Radiateur HT" && tp.WTOL >= 60) ) && (
                 <div> 
                  {setName(name => [...name, tp.name])}
                  {setUrl(url => [...url, tp.url])}
                  {setBrand(brand => [...brand, tp.Brand])}
                  {setUnits(units => [...units, tp.Units])}
                  {setEtas(etas => [...etas, tp.ETAS])}
                  
                  
                  </div>
                  
                 
                
               )} 
               {(tog==0) && (props.question1 === 'Eau Chaude Chauffage') && (tp.type !== "CET") && (tp.prod == "Heating +  DHW + low temp") && ((tp.PTbiv * 1000 / deperd) > 0.5 &&  (tp.PTbiv * 1000 / deperd) < 0.75) && (tp.ecs60 > volume && tp.ecs60 * 0.7 < volume) && (props.chaud == "Plus de 10 ans") && ((props.radiateur =="Radiateur BT ou PC" && tp.WTOL < 60) || (props.radiateur =="Radiateur HT" && tp.WTOL >= 60) ) && (
                 <div> 
                  {setName(name => [...name, tp.name])}
                  {setUrl(url => [...url, tp.url])}
                  {setBrand(brand => [...brand, tp.Brand])}
                  {setUnits(units => [...units, tp.Units])}
                  {setEtas(etas => [...etas, tp.ETAS])}
                  
                  
                  </div>
                  
                 
                
               )} 
                {(tog==0) && (props.question1 === 'Eau Chaude Chauffage') && (tp.type !== "CET") && (tp.prod == "Heating +  DHW + low temp") && ((tp.PTbiv * 1000 / deperd) > 0.6 &&  (tp.PTbiv * 1000 / deperd) < 0.85) && (tp.ecs60 > volume && tp.ecs60 * 0.7 < volume) && (props.chaud == "Entre 7 et 10 ans") && ((props.radiateur =="Radiateur BT ou PC" && tp.WTOL < 60) || (props.radiateur =="Radiateur HT" && tp.WTOL >= 60) ) && (
                 <div> 
                  {setName(name => [...name, tp.name])}
                  {setUrl(url => [...url, tp.url])}
                  {setBrand(brand => [...brand, tp.Brand])}
                  {setUnits(units => [...units, tp.Units])}
                  {setEtas(etas => [...etas, tp.ETAS])}
                  
                  
                  </div>
                  
                 
                
               )} 
               {(tog==0) && (props.question1 === 'Chauffage') && (tp.prod !== "Heating + DHW + low temp") && (tp.type !== "CET") && ((tp.PTbiv * 1000 / deperd) > 0.6 &&  (tp.PTbiv * 1000 / deperd) < 0.85) && (props.chaud == "Entre 7 et 10 ans") && ((props.radiateur =="Radiateur BT ou PC" && tp.WTOL < 60) || (props.radiateur =="Radiateur HT" && tp.WTOL >= 60) ) && (
                 <div> 
                  {setName(name => [...name, tp.name])}
                  {setUrl(url => [...url, tp.url])}
                  {setBrand(brand => [...brand, tp.Brand])}
                  {setUnits(units => [...units, tp.Units])}
                  {setEtas(etas => [...etas, tp.ETAS])}
                  
                  
                  </div>
                  
                 
                
               )} 
               {(tog==0) && (props.question1 === 'Chauffage') && (tp.prod !== "Heating + DHW + low temp") && (tp.type !== "CET") && ((tp.PTbiv * 1000 / deperd) > 0.5 &&  (tp.PTbiv * 1000 / deperd) < 0.75) && (props.chaud == "Plus de 10 ans") && ((props.radiateur =="Radiateur BT ou PC" && tp.WTOL < 60) || (props.radiateur =="Radiateur HT" && tp.WTOL >= 60) ) && (
                 <div> 
                  {setName(name => [...name, tp.name])}
                  {setUrl(url => [...url, tp.url])}
                  {setBrand(brand => [...brand, tp.Brand])}
                  {setUnits(units => [...units, tp.Units])}
                  {setEtas(etas => [...etas, tp.ETAS])}
                  
                  
                  </div>
                  
                 
                
               )} 
               {(tog==0) && (props.question1 === 'Chauffage') && (tp.prod !== "Heating + DHW + low temp") && (tp.type !== "CET") && ((tp.PTbiv / deperd) > 0.7 &&  (tp.PTbiv / deperd) < 0.85) && (props.chaud == "Moins de 10 ans") && ((props.radiateur =="Radiateur BT ou PC" && tp.WTOL < 60) || (props.radiateur =="Radiateur HT" && tp.WTOL >= 60)) && (
                 <div> 
                  {setName(name => [...name, tp.name])}
                  {setUrl(url => [...url, tp.url])}
                  {setBrand(brand => [...brand, tp.Brand])}
                  {setUnits(units => [...units, tp.Units])}
                  {setEtas(etas => [...etas, tp.ETAS])}
                  
                  
                  </div>
                  
                 
                
               )} 
             
               
               {(tp.name == "WWK 302") && tog < 2 && (
                 <div> 
                  {setTog(tog+1)}
                  {console.log('bival')}
                  {console.log(name)}
                  
                  
                  
                  </div>
                  
                 
                
               )} 

             
              
      
     </div>
     
     
     ))
   : null}
    {(!!name && name.length > 1) && (props.question1 === 'Chauffage' || props.question1 === 'Eau Chaude Chauffage') &&(
   <div>
    <h2 > Pompe a chaleur air-eau en mode bivalent
      <Tooltip title={ <div>1er : Produit <br/> 2ème: Marque <br/> 3ème: Unité <br/> 4ème : Efficacité Energétique Saisonnière </div>} placement="top" enterTouchDelay={0}>
             <InfoIcon style={{color: "#1976D2"}}></InfoIcon>
          </Tooltip> </h2>
    <Newcarousel name = {name} photo = {url} prop1 = {brand} prop2 = {units} prop3 = {etas}></Newcarousel>
    </div>
    )}
    
   </div>
   );
 }


 const Chaudierebois = (props) => {
  
  const[chaud, setChaud] = useState([]);
  const[bois, setBois] = useState([]);
  const[url, setUrl] = useState([]);
  const[type,setType] = useState([]);
  const[rendement,setRendement] = useState([]);
  const[emissionCO,setEmissionco] = useState([]);
  const[emissionNox,setEmissionnox] = useState([]);
  const[tog, setTog] = useState(0);
  var deperd = +localStorage.getItem("deperditions");
  
 
  useEffect(() => {
   
     fetch("https://www.simucho.fr/chaudbois")
     .then(response => response.json())
     .then(data => setChaud(data))
    },[])
     
     
   
 
   return (
     <div >
      
        { Array.isArray(chaud)
         ? chaud.map(tp => (
             <div key={tp.name}>
              
               {(tog==0) && (tp.puissance * 1000 > deperd && tp.puissance * 900 < deperd) && (
                 <div> 
                  {setBois(bois => [...bois, tp.name])}
                  {setUrl(url => [...url, tp.url])}
                  {setType(type => [...type, tp.type])}
                  {setRendement(rendement => [...rendement, tp.rendement])}
                  {setEmissionco(emissionCO => [...emissionCO, tp.emissionco])}
                  {setEmissionnox(emissionNox => [...emissionNox, tp.emissionnox])}
                  
                  
                  </div>
                  
                 
                
               )} 
               {(tp.name == "WINDHAGER PW 30") && tog < 2 && (
                 <div> 
                  {setTog(tog+1)}
                  {console.log(tog)}
                  {console.log(bois)}
                  
                  
                  
                  </div>
                  
                 
                
               )} 

             
              
      
     </div>
     
     
     ))
   : null}
   {(!!bois && bois.length > 1) &&(
   <div>
     <h2 > Chaudiere bois/pellets
      <Tooltip title={ <div>1er : Marque & Produit <br/> 2ème: Type <br/> 3ème: Rendement <br/> 4ème : Emission de CO <br/> 5ème : Emission de NOx</div>} placement="top" enterTouchDelay={0}>
             <InfoIcon style={{color: "#1976D2"}}></InfoIcon>
          </Tooltip> </h2>
    <Newcarousel  name = {bois} photo = {url} prop1 = {rendement} prop2 = {emissionCO} prop3 = {emissionNox} type = {type}></Newcarousel>
    </div>
    )}
   </div>
   );
 }

const Temp = (props) => {
  
 const[tem, setTem] = useState([])

   
  useEffect(() => {
    fetch("https://www.simucho.fr/temperature")
    .then(response => response.json())
       
    .then(data => setTem(data))
  },[])

  return (
    <div >
       { Array.isArray(tem.Temperature)
        ? tem.Temperature.map(tp => (
            <div key={tp.Index}>
              {(tp.Code == +localStorage.getItem("addresse")) && (
                <div> 
                  {localStorage.setItem("temperature", tp.Temperature)}
            </div>
              )} 
    </div>
    

    ))
  : null}
  </div>
  );
}

const Mur = (props) => {
  const [mur, setMur] = useState([]);
  const [dep, setDep] = useState([]);
  var nb_grand_mur = +localStorage.getItem("question39").substring(1, 2)
  var nb_petit_mur = +localStorage.getItem("question39").substring(0, 1)
  var nb_grand_mur_forme = +localStorage.getItem("question38").substring(1, 2)
  var nb_petit_mur_forme = +localStorage.getItem("question38").substring(0, 1)
  var temp = +localStorage.getItem("temperature")


  useEffect(() => {
    const loadUsers = async()=> {
      Promise.all([
        fetch('https://www.simucho.fr/Mur'),
        fetch('https://www.simucho.fr/departement')
    ])
    .then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
    .then(([data1, data2]) => Promise.all[setMur(data1), setDep(data2)]
    );
  }
  loadUsers();
    }, []);
    return (
      
      <div>
      
      { Array.isArray(dep.departement)
        ? dep.departement.map(dp => (
            <div key={dp.Département}>
              {(!!props.radiateur && props.radiateur !== "Radiateur Electrique") && (dp.Département == localStorage.getItem("addresse").substring(0, 2)) && (dp.Zone == "h1" ) && (
                <div> {mur.Mur.map(mr => ( <div key={mr.id}> 
                {(mr.id == (props.valeur)) && (
                <div> {localStorage.setItem("Mur", ((mr.Autresh1 - props.ureno)*( props.temperature - temp)*(props.hauteur)*((nb_grand_mur)*(props.grand_cote) + (nb_petit_mur)*(props.petit_cote)) + (mr.Autresh1 - props.urenoext)*( props.temperature - temp)*(props.hauteur)*((nb_grand_mur_forme - nb_grand_mur)*(props.grand_cote) + (nb_petit_mur_forme - nb_petit_mur)*(props.petit_cote))))} </div>
                

              )} 
              
                </div>))} 
            </div>
              )} 
              {(!!props.radiateur && props.radiateur !== "Radiateur Electrique") && (dp.Département == localStorage.getItem("addresse").substring(0, 2)) && (dp.Zone == "h2" ) && (
                <div> {mur.Mur.map(mr => ( <div key={mr.id}> 
                {(mr.id == (props.valeur)) && (
                <div> {localStorage.setItem("Mur",((mr.Autresh2 - props.ureno)*( props.temperature - temp)*(props.hauteur)*((nb_grand_mur)*(props.grand_cote) + (nb_petit_mur)*(props.petit_cote)) + (mr.Autresh2 - props.urenoext)*( props.temperature - temp)*(props.hauteur)*((nb_grand_mur_forme - nb_grand_mur)*(props.grand_cote) + (nb_petit_mur_forme - nb_petit_mur)*(props.petit_cote))))}</div>
              )} 
              
                </div>))} 
            </div>
              )} 
              {(!!props.radiateur && props.radiateur == "Radiateur Electrique") && (dp.Département == localStorage.getItem("addresse").substring(0, 2)) && (dp.Zone == "h1" ) && (
                <div> {mur.Mur.map(mr => ( <div key={mr.id}> 
                {(mr.id == (props.valeur)) && (
                <div> {localStorage.setItem("Mur",((mr.Jouleh1 - props.ureno)*( props.temperature - temp)*(props.hauteur)*((nb_grand_mur)*(props.grand_cote) + (nb_petit_mur)*(props.petit_cote)) + (mr.Jouleh1 - props.urenoext)*( props.temperature - temp)*(props.hauteur)*((nb_grand_mur_forme - nb_grand_mur)*(props.grand_cote) + (nb_petit_mur_forme - nb_petit_mur)*(props.petit_cote))))} </div>
              )} 
              
                </div>))} 
            </div>
              )} 
              {(!!props.radiateur && props.radiateur == "Radiateur Electrique") && (dp.Département == localStorage.getItem("addresse").substring(0, 2)) && (dp.Zone == "h2" ) && (
                <div> {mur.Mur.map(mr => ( <div key={mr.id}> 
                {(mr.id == (props.valeur)) && (
                <div> {localStorage.setItem("Mur",((mr.Jouleh2 - props.ureno)*( props.temperature - temp)*(props.hauteur)*((nb_grand_mur)*(props.grand_cote) + (nb_petit_mur)*(props.petit_cote)) + (mr.Jouleh2 - props.urenoext)*( props.temperature - temp)*(props.hauteur)*((nb_grand_mur_forme - nb_grand_mur)*(props.grand_cote) + (nb_petit_mur_forme - nb_petit_mur)*(props.petit_cote))))} </div>
              )} 
              
                </div>))} 
            </div>
              )} 
              {(!!props.radiateur && props.radiateur !== "Radiateur Electrique") && (dp.Département == localStorage.getItem("addresse").substring(0, 2)) && (dp.Zone == "h3" ) && (
                <div> {mur.Mur.map(mr => ( <div key={mr.id}> 
                {(mr.id == (props.valeur)) && (
                <div> {localStorage.setItem("Mur",((mr.Autresh3- props.ureno)*( props.temperature - temp)*(props.hauteur)*((nb_grand_mur)*(props.grand_cote) + (nb_petit_mur)*(props.petit_cote)) + (mr.Autresh3- props.urenoext)*( props.temperature - temp)*(props.hauteur)*((nb_grand_mur_forme - nb_grand_mur)*(props.grand_cote) + (nb_petit_mur_forme - nb_petit_mur)*(props.petit_cote))))} </div>
              )} 
              
                </div>))} 
            </div>
              )} 
              {(!!props.radiateur && props.radiateur == "Radiateur Electrique") && (dp.Département == localStorage.getItem("addresse").substring(0, 2)) && (dp.Zone == "h3" ) && (
                <div> {mur.Mur.map(mr => ( <div key={mr.id}> 
                {(mr.id == (props.valeur)) && (
                <div> {localStorage.setItem("Mur",((mr.Jouleh3 - props.ureno)*( props.temperature - temp)*(props.hauteur)*((nb_grand_mur)*(props.grand_cote) + (nb_petit_mur)*(props.petit_cote)) + (mr.Jouleh3 - props.urenoext)*( props.temperature - temp)*(props.hauteur)*((nb_grand_mur_forme - nb_grand_mur)*(props.grand_cote) + (nb_petit_mur_forme - nb_petit_mur)*(props.petit_cote))))} </div>
              )} 
              
                </div>))} 
            </div>
              )} 


            </div>

          ))
        : null}
        

              
    </div>
  );
};

const Plancher = (props) => {
  const [pla, setPla] = useState([]);
  const [dep, setDep] = useState([])
  var temp = +localStorage.getItem("temperature")


  useEffect(() => {
    const loadUsers = async()=> {
      Promise.all([
        fetch('https://www.simucho.fr/Plancher'),
        fetch('https://www.simucho.fr/departement')
    ])
    .then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
    .then(([data1, data2]) => Promise.all[setPla(data1), setDep(data2)]
    );
  }
  loadUsers();
    }, []);
    return (
      <div>
        
      { Array.isArray(dep.departement)
        ? dep.departement.map(dp => (
            <div key={dp.Département}>
              {(!!props.radiateur && props.radiateur !== "Radiateur Electrique") && (dp.Département == localStorage.getItem("addresse").substring(0, 2)) && (dp.Zone == "h1" ) && (
                <div> {pla.Plancher.map(pl => ( <div key={pl.id}> 
                {(pl.id == (props.valeur)) && (
                <div> {localStorage.setItem("Plancher",((pl.Autresh1 - props.ureno)*(props.temperature - temp)*(props.aire)))} </div>
              )} 
              
                </div>))} 
            </div>
              )} 
              {(!!props.radiateur && props.radiateur !== "Radiateur Electrique") && (dp.Département == localStorage.getItem("addresse").substring(0, 2)) && (dp.Zone == "h2" ) && (
                <div> {pla.Plancher.map(pl => ( <div key={pl.id}> 
                {(pl.id == (props.valeur)) && (
                <div> {localStorage.setItem("Plancher",((pl.Autresh2 - props.ureno)*(props.temperature - temp)*(props.aire)))} </div>
              )} 
              
                </div>))} 
            </div>
              )} 
              {(!!props.radiateur && props.radiateur == "Radiateur Electrique") && (dp.Département == localStorage.getItem("addresse").substring(0, 2)) && (dp.Zone == "h1" ) && (
                <div> {pla.Plancher.map(pl => ( <div key={pl.id}> 
                {(pl.id == (props.valeur)) && (
                <div> {localStorage.setItem("Plancher",((pl.Jouleh1 - props.ureno)*(props.temperature - temp)*(props.aire)))} </div>
              )} 
              
                </div>))} 
            </div>
              )} 
              {(!!props.radiateur && props.radiateur == "Radiateur Electrique") && (dp.Département == localStorage.getItem("addresse").substring(0, 2)) && (dp.Zone == "h2" ) && (
                <div> {pla.Plancher.map(pl => ( <div key={pl.id}> 
                {(pl.id == (props.valeur)) && (
                <div> {localStorage.setItem("Plancher",((pl.Jouleh2 - props.ureno)*(props.temperature - temp)*(props.aire)))} </div>
              )} 
              
                </div>))} 
            </div>
              )} 
              {(!!props.radiateur && props.radiateur !== "Radiateur Electrique") && (dp.Département == localStorage.getItem("addresse").substring(0, 2)) && (dp.Zone == "h3" ) && (
                <div> {pla.Plancher.map(pl => ( <div key={pl.id}> 
                {(pl.id == (props.valeur)) && (
                <div> {localStorage.setItem("Plancher",((pl.Autresh3 - props.ureno)*(props.temperature - temp)*(props.aire)))} </div>
              )} 
              
                </div>))} 
            </div>
              )} 
              {(!!props.radiateur && props.radiateur == "Radiateur Electrique") && (dp.Département == localStorage.getItem("addresse").substring(0, 2)) && (dp.Zone == "h3" ) && (
                <div> {pla.Plancher.map(pl => ( <div key={pl.id}> 
                {(pl.id == (props.valeur)) && (
                <div> {localStorage.setItem("Plancher",((pl.Jouleh3 - props.ureno)*(props.temperature - temp)*(props.aire)))} </div>
              )} 
              
                </div>))} 
            </div>
              )} 


            </div>

          ))
        : null}
        

              
    </div>
  );
};

const Reno = (props) => {
  const [reno, setReno] = useState([]);
  const [dep, setDep] = useState([]);
  var temp = +localStorage.getItem("temperature")


  useEffect(() => {
    const loadUsers = async()=> {
      Promise.all([
        fetch('https://www.simucho.fr/coeff'),
        fetch('https://www.simucho.fr/departement')
    ])
    .then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
    .then(([data1, data2]) => Promise.all[setReno(data1), setDep(data2)]
    );
  }
  loadUsers();
    }, []);
    return (
      <div>
      { Array.isArray(dep.departement)
        ? dep.departement.map(dp => (
            <div key={dp.Département}>
              {(!!props.reno_type && props.reno_type.includes("Toiture")) && (dp.Département == localStorage.getItem("addresse").substring(0, 2)) && (dp.Zone == "h1" ) && (
                <div> {reno.Toiture.map(re => ( <div key={re.id}> 
                {(re.id == (props.toiture_type)) && (((props.toiture) === "2007- 2011") || ((props.toiture) === "2012- 2017" && (!!props.ci && props.ci === "Non"))) && (
                <div> {localStorage.setItem("Toiture",((0.3 * props.valeur * props.hauteur * props.aire * props.etage*(props.temperature - temp)) - (re.H12007 * (props.aire / re.angle)*(props.temperature - 10))))} </div>
                
              )} 
              
                </div>))} 
                {reno.Toiture.map(re => ( <div key={re.id}> 
                  {(re.id == (props.toiture_type)) && ((props.toiture) === "Après 2018") && (
                <div> {localStorage.setItem("Toiture",((0.3 * props.valeur * props.hauteur * props.aire * props.etage*(props.temperature - temp)) - (re.H12018 * (props.aire / re.angle)*(props.temperature - 10))))} </div>
                
              )} 
              
                </div>))}
                </div>
                
              )} 
              {(!!props.reno_type && props.reno_type.includes("Toiture")) && (dp.Département == localStorage.getItem("addresse").substring(0, 2)) && (dp.Zone == "h2" ) && (
                <div> {reno.Toiture.map(re => ( <div key={re.id}> 
                {(re.id == (props.toiture_type)) && (((props.toiture) === "2007- 2011") || ((props.toiture) === "2012- 2017" && (!!props.ci && props.ci === "Non"))) && (
                <div> {localStorage.setItem("Toiture",((0.3 * props.valeur * props.hauteur * props.aire * props.etage*(props.temperature - temp)) - (re.H22007 * (props.aire / re.angle)*(props.temperature - 10))))} </div>
                
              )} 
              
                </div>))} 
                {reno.Toiture.map(re => ( <div key={re.id}> 
                  {(re.id == (props.toiture_type)) && ((props.toiture) === "Après 2018") && (
                <div> {localStorage.setItem("Toiture",((0.3 * props.valeur * props.hauteur * props.aire * props.etage*(props.temperature - temp)) - (re.H22018 * (props.aire / re.angle)*(props.temperature - 10))))} </div>
                
              )} 
              
                </div>))}
                </div>
                
              )} 
              {(!!props.reno_type && props.reno_type.includes("Toiture")) && (dp.Département == localStorage.getItem("addresse").substring(0, 2)) && (dp.Zone == "h3" ) && (
                <div> {reno.Toiture.map(re => ( <div key={re.id}> 
                {(re.id == (props.toiture_type)) && (((props.toiture) === "2007- 2011") || ((props.toiture) === "2012- 2017" && (!!props.ci && props.ci === "Non"))) && (
                <div> {localStorage.setItem("Toiture",((0.3 * props.valeur * props.hauteur * props.aire * props.etage*(props.temperature - temp)) - (re.H32007 * (props.aire / re.angle)*(props.temperature - 10))))} </div>
                
              )} 
              
                </div>))} 
                {reno.Toiture.map(re => ( <div key={re.id}> 
                  {(re.id == (props.toiture_type)) && ((props.toiture) === "Après 2018") && (
                <div> {localStorage.setItem("Toiture",((0.3 * props.valeur * props.hauteur * props.aire * props.etage*(props.temperature - temp)) - (re.H32018 * (props.aire / re.angle)*(props.temperature - 10))))} </div>
                
              )} 
              
                </div>))}
                </div>
                
              )} 

              {(!!props.reno_type && props.reno_type.includes("Plancher Bas")) && (dp.Département == localStorage.getItem("addresse").substring(0, 2)) && (dp.Zone == "h1" ) && (
                <div> {reno.Plancher.map(pl => ( <div key={pl.id}> 
                {(pl.id == (props.plancher_type)) && (((props.pb) === "2007- 2011") || (((props.pb) === "2012- 2017")  && (!!props.ci && props.ci === "Non"))) && (
                <div> <Plancher valeur={props.valeur} aire = {props.aire} ureno = {pl.H12007} radiateur ={props.radiateur} temperature = {props.temperature}> </Plancher> BBBBA </div>
                
              )} 
              
                </div>))} 
                {reno.Plancher.map(pl => ( <div key={pl.id}> 
                  {(pl.id == (props.plancher_type)) && ((props.pb) === "Après 2018") && (
                <div> <Plancher valeur={props.valeur} aire = {props.aire} ureno = {pl.H12018} radiateur ={props.radiateur} temperature = {props.temperature}> </Plancher>  </div>
                
              )} 
              
                </div>))}
                </div>
                
              )}  

              {(!!props.reno_type && props.reno_type.includes("Plancher Bas")) && (dp.Département == localStorage.getItem("addresse").substring(0, 2)) && (dp.Zone == "h2" ) && (
                <div> {reno.Plancher.map(pl => ( <div key={pl.id}> 
                {(pl.id == (props.plancher_type)) && (((props.pb) === "2007- 2011") || (((props.pb) === "2012- 2017")  && (!!props.ci && props.ci === "Non"))) && (
                <div> <Plancher valeur={props.valeur} aire = {props.aire} ureno = {pl.H22007} radiateur ={props.radiateur} temperature = {props.temperature}> </Plancher> </div>
                
              )} 
              
                </div>))} 
                {reno.Plancher.map(pl => ( <div key={pl.id}> 
                  {(pl.id == (props.plancher_type)) && ((props.pb) === "Après 2018") && (
                <div> <Plancher valeur={props.valeur} aire = {props.aire} ureno = {pl.H22018} radiateur ={props.radiateur} temperature = {props.temperature}> </Plancher> </div>
                
              )} 
              
                </div>))}
                </div>
                
              )}  
              {(!!props.reno_type && props.reno_type.includes("Plancher Bas")) && (dp.Département == localStorage.getItem("addresse").substring(0, 2)) && (dp.Zone == "h3" ) && (
                <div> {reno.Plancher.map(pl => ( <div key={pl.id}> 
                {(pl.id == (props.plancher_type)) && (((props.pb) === "2007- 2011") || (((props.pb) === "2012- 2017")  && (!!props.ci && props.ci === "Non"))) && (
                <div> <Plancher valeur={props.valeur} aire = {props.aire} ureno = {pl.H32007} radiateur ={props.radiateur} temperature = {props.temperature}> </Plancher>  </div>
                
              )} 
              
                </div>))} 
                {reno.Plancher.map(pl => ( <div key={pl.id}> 
                  {(pl.id == (props.plancher_type)) && ((props.pb) === "Après 2018") && (
                <div> <Plancher valeur={props.valeur} aire = {props.aire} ureno = {pl.H32018}  radiateur ={props.radiateur} temperature = {props.temperature}> </Plancher> </div>
                
              )} 
              
                </div>))}
                </div>
                
              )}  

              {(!!props.reno_type && props.reno_type.includes("Murs")) && (dp.Département == localStorage.getItem("addresse").substring(0, 2)) && (dp.Zone == "h1" ) && (!!props.ci && props.ci === "Non") && (
                <div> {reno.Mur.map(re => ( <div key={re.id}> 
                {(re.id == (props.mur_type)) && (((props.mur) === "2007- 2011") || (((props.mur) === "2012- 2017") && (!!props.ci && props.ci === "Non"))) && (
                <div> <Mur hauteur = {props.hauteur} valeur={props.valeur} radiateur ={props.radiateur} ureno = {re.H12007} temperature = {props.temperature} petit_cote = {props.petit_cote} grand_cote = {props.grand_cote} urenoext = {0.435} > </Mur> </div>
                
              )} 
              
                </div>))} 
                {reno.Mur.map(re => ( <div key={re.id}> 
                  {(re.id == (props.mur_type)) && ((props.mur) === "Après 2018") && (
                <div> <Mur hauteur = {props.hauteur} valeur={props.valeur} radiateur ={props.radiateur} ureno = {re.H12018} temperature = {props.temperature} petit_cote = {props.petit_cote} grand_cote = {props.grand_cote} urenoext = {0.345} > </Mur></div>
                
              )} 
              
                </div>))}
                </div>
                
              )} 
              {(!!props.reno_type && props.reno_type.includes("Murs")) && (dp.Département == localStorage.getItem("addresse").substring(0, 2)) && (dp.Zone == "h2" ) && (
                <div> {reno.Mur.map(re => ( <div key={re.id}> 
                {(re.id == (props.mur_type)) && (((props.mur) === "2007- 2011") || (((props.mur) === "2012- 2017") && (!!props.ci && props.ci === "Non"))) && (
                <div> <Mur hauteur = {props.hauteur} valeur={props.valeur} radiateur ={props.radiateur} ureno = {re.H22007} temperature = {props.temperature} petit_cote = {props.petit_cote} grand_cote = {props.grand_cote} urenoext = {0.435}> </Mur> </div>
                
              )} 
              
                </div>))} 
                {reno.Mur.map(re => ( <div key={re.id}> 
                  {(re.id == (props.mur_type)) && ((props.mur) === "Après 2018") && (
                <div> <Mur hauteur = {props.hauteur} valeur={props.valeur} radiateur ={props.radiateur} ureno = {re.H22018} temperature = {props.temperature} petit_cote = {props.petit_cote} grand_cote = {props.grand_cote} urenoext = {0.345} > </Mur> </div>
                
              )} 
              
                </div>))}
                </div>
                
              )} 
              {(!!props.reno_type && props.reno_type.includes("Murs")) && (dp.Département == localStorage.getItem("addresse").substring(0, 2)) && (dp.Zone == "h3" ) && (
                <div> {reno.Mur.map(re => ( <div key={re.id}> 
                {(re.id == (props.mur_type)) && (((props.mur) === "2007- 2011") || (((props.mur) === "2012- 2017") && (!!props.ci && props.ci === "Non"))) && (
                <div> <Mur hauteur = {props.hauteur} valeur={props.valeur} radiateur ={props.radiateur} ureno = {re.H32007} temperature = {props.temperature} petit_cote = {props.petit_cote} grand_cote = {props.grand_cote} urenoext = {0.5} > </Mur>   </div>
                
              )} 
              
                </div>))} 
                {reno.Mur.map(re => ( <div key={re.id}> 
                  {(re.id == (props.mur_type)) && ((props.mur) === "Après 2018") && (
                <div> <Mur hauteur = {props.hauteur} valeur={props.valeur} radiateur ={props.radiateur} ureno = {re.H32018} temperature = {props.temperature} petit_cote = {props.petit_cote} grand_cote = {props.grand_cote} urenoext = {0.455}> </Mur>  </div>
                
              )} 
              
                </div>))}
                </div>
                
              )} 
              
            </div>

          ))
        : null}

            {(!!props.reno_type && props.reno_type.includes("Murs"))  && ((props.mur) === "2012- 2017") && (!!props.ci && props.ci === "Oui")  && (
              <div>
                <Mur hauteur = {props.hauteur} valeur={props.valeur} radiateur ={props.radiateur} ureno = {0.270} temperature = {props.temperature} petit_cote = {props.petit_cote} grand_cote = {props.grand_cote} urenoext = {0.270} > </Mur>
                </div>
              )}

              {(!!props.reno_type && props.reno_type.includes("Plancher Bas"))  && ( (props.pb) === "2012- 2017") && (!!props.ci && props.ci === "Oui")  && (
              <div>
                <Plancher valeur={props.valeur} aire = {props.aire} ureno = {0.333}  radiateur ={props.radiateur} temperature = {props.temperature}> </Plancher>
                </div>
              )}
              {(!!props.reno_type && props.reno_type.includes("Toiture"))  && ((props.toiture) === "2012- 2017") && (!!props.ci && props.ci === "Oui")  && (
              <div>
                {(!!props.toiture_type && (props.toiture_type === "60+" || props.toiture_type === "60-" )) && (
                  <div> 
                    {localStorage.setItem("Toiture",((0.3 * props.valeur * props.hauteur * props.aire * props.etage*(props.temperature - 10)) - (0.1667 * (props.aire / 0.75)*(props.temperature - 10))))}
                  </div>
                )}
                 {(!!props.toiture_type && props.toiture_type === "terasse") && (
                  <div> 
                    {localStorage.setItem("Toiture",((0.3 * props.valeur * props.hauteur * props.aire * props.etage*(props.temperature - 10)) - (0.222 * (props.aire)*(props.temperature - 10))))}
                  </div>
                )}
                {(!!props.toiture_type && props.toiture_type === "perdue") && (
                  <div> 
                    {localStorage.setItem("Toiture",((0.3 * props.valeur * props.hauteur * props.aire * props.etage*(props.temperature - 10)) - (0.143 * (props.aire / 0.5)*(props.temperature - 10))))} 
                  </div>
                )}
                </div>
              )}

              
    </div>
  );
};

export default class Wizard extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired
  }
  static Page = ({ children }) => children

  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      values: props.initialValues || {},
      cet : [],
      pacs : [],
      chaudb : [],
      insertss : [],
      installateurscet : [],
      installateurspacs : [],
      installateurschaudb : [],
      installateursinsertss : [],
      installateurschaudgaz : [],
      color : "#2196F3",
      button_text : "Recevoir des devis",
      expanded: 'panel1',
    }
  }
  next = values =>
    this.setState(state => ({
      page: Math.min(state.page + 1, this.props.children.length -1 ),
      values
    }))
    

  previous = () =>
    this.setState(state => ({
      page: Math.max(state.page - 1, 0)
    }))

    handleAddresse(e) {
      e.preventDefault();
      window.alert("\t Renseignez votre code postal.");
    }

    handleChange = (panel) => (e, newExpanded) => {
      e.preventDefault();
      this.setState({expanded: newExpanded ? panel : false});
      console.log("appuye")
    };
    

  /**
   * NOTE: Both validate and handleSubmit switching are implemented
   * here because 🏁 Redux Final Form does not accept changes to those
   * functions once the form has been defined.
   */

  validate = values => {
    const activePage = React.Children.toArray(this.props.children)[
      this.state.page
    ]
    return activePage.props.validate ? activePage.props.validate(values) : {}
  }

   makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * 
 charactersLength));
   }
   return result;
}

  loadUsers = (e,values)=> {
    e.preventDefault();
    this.setState({color: "#D30303"})
    this.setState({button_text: "Merci !"})
    console.log('clicked 1');
    const load = (values) => {
    Promise.all([
      fetch('https://www.simucho.fr/installateurscet'),
      fetch('https://www.simucho.fr/installateurschaudbois'),
      fetch('https://www.simucho.fr/installateursinserts'),
      fetch('https://www.simucho.fr/installateurspac'),
      fetch('https://www.simucho.fr/installateurschaudgaz'),
  ])
  .then(([res1, res2,res3,res4,res5]) => Promise.all([res1.json(), res2.json(),res3.json(),res4.json(),res5.json()]))
  .then(([data1, data2,data3,data4,data5]) => {this.setState({ 
    cet : data1,
    chaudb : data2, 
    insertss : data3, 
    pacs : data4,
    gaz : data5
  })
  console.log(values.questionfinal)
  if (values.questionfinal.includes("cet/cumulus")) {
    {this.state.cet.map(tp => (
      <div key={tp.index}>
        {(tp.departement == +localStorage.getItem("addresse") || (localStorage.hasOwnProperty("Address") && (tp.departement == +localStorage.getItem("Address"))))  && (
          <div> 
           {this.setState({installateurscet : [...this.state.installateurscet, tp.email]})}
           
           </div>
         
        )} 
       
  </div>
  
  
  ))}
    }
    if (values.questionfinal.includes("Inserts/Foyers/Poeles")) {
      {this.state.insertss.map(tp => (
        <div key={tp.index}>
          {(tp.departement == +localStorage.getItem("addresse") || (localStorage.hasOwnProperty("Address") && (tp.departement == +localStorage.getItem("Address"))))  && (
            <div> 
             {this.setState({installateursinsertss : [...this.state.installateursinsertss, tp.email]})}
             </div>
           
          )} 
         
    </div>
    
    
    ))}
  }
  if (values.questionfinal.includes("Chaudiere Bois/granule")) {
    {this.state.chaudb.map(tp => (
      <div key={tp.index}>
        {(tp.departement == +localStorage.getItem("addresse") || (localStorage.hasOwnProperty("Address") && (tp.departement == +localStorage.getItem("Address"))))  && (
          <div> 
           {this.setState({installateurschaudb : [...this.state.installateurschaudb, tp.email]})}
           </div>
         
        )} 
       
  </div>
  
  
  ))}
}
if (values.questionfinal.includes("Pompe a chaleur")) {
  {this.state.pacs.map(tp => (
    <div key={tp.index}>
      {(tp.departement == +localStorage.getItem("addresse") || (localStorage.hasOwnProperty("Address") && (tp.departement == +localStorage.getItem("Address"))))  && (
        <div> 
         {this.setState({installateurspacs : [...this.state.installateurspacs, tp.email]})}
         </div>
       
      )} 
     
</div>


))}
}
if (values.questionfinal.includes("Chaudiere gaz")) {
  {this.state.gaz.map(tp => (
    <div key={tp.index}>
      {(tp.departement == +localStorage.getItem("addresse") || (localStorage.hasOwnProperty("Address") && (tp.departement == +localStorage.getItem("Address")))) && (
        <div> 
         {this.setState({installateurschaudgaz : [...this.state.installateurschaudgaz, tp.email]})}
         
         </div>
       
      )} 
     
</div>


))}
  }
{this.prod(values)}
})

};
load(values);

}

  prod = (values) => {
    
    
    console.log('clicked');
    fetch('https://www.simucho.fr/addproduct', {
      method: 'POST',
     
      body: JSON.stringify({
         cle : this.makeid(7),
         email : values.email? values.email : ("pas d'email indiqué"),
         nom : values.lastName? values.lastName : ("Non renseigné"),
         prenom : values.firstName? values.firstName : ("Non renseigné"),
         produit : values.questionfinal? values.questionfinal : ("N/A"),
         adresse : localStorage.hasOwnProperty("Address")? localStorage.getItem("Address") : localStorage.getItem("addresse"),
         besoin : values.question1? values.question1 : ("N/A"),
         raison : values.question2? values.question2 : ("N/A"),
         type : values.question3? values.question3 : ("N/A"),
         solution_existante : values.question48? values.question48 : ("N/A"),
         habitat : values.question5? values.question5 : ("N/A"),
         deperdition : localStorage.hasOwnProperty("deperditions")? Math.round(+localStorage.getItem("deperditions")) : ("N/A"),
         volumeECS : localStorage.hasOwnProperty("volumeecs60")? +localStorage.getItem("volumeecs60") : ("N/A"),
         debit : localStorage.hasOwnProperty("debit")? +localStorage.getItem("debit") : ("N/A"),
         fumisterie : localStorage.hasOwnProperty("question14")? +localStorage.getItem("question14") : ("N/A"),
         conduit : localStorage.hasOwnProperty("question15")? +localStorage.getItem("question15") : ("N/A"),
         cheminee : localStorage.hasOwnProperty("question55")? +localStorage.getItem("question55") : ("N/A"),
         installateurscet : this.state.installateurscet.length > 0 ? this.state.installateurscet : (""),
         installateurspacs : this.state.installateurspacs.length > 0 ? this.state.installateurspacs.sort(function(){return .5 - Math.random()}).slice(0,3) : (""),
         installateurschaudb : this.state.installateurschaudb.length > 0 ? this.state.installateurschaudb.sort(function(){return .5 - Math.random()}).slice(0,3) : (""),
         installateursinsertss : this.state.installateursinsertss.length > 0 ? this.state.installateursinsertss : (""),
         installateurschaudgaz : this.state.installateurschaudgaz.length > 0 ? this.state.installateurschaudgaz : (""),
    }),

      headers: {
          'Content-type': 'application/json'
      }

  })
  window.alert("Merci, votre demande de devis a bien été envoyée aux installateurs locaux !" )
  
  }
  

  handleSubmit = values => {
    const { children, onSubmit } = this.props
    const { page } = this.state
   
    const lastPage = page === React.Children.count(children) - 1
    if (lastPage) {
      return onSubmit(values)
    } else {
      this.next(values)

    }
  }


  render() {
    const { children } = this.props
    const { page, values} = this.state
    const {expanded} = this.state
    const handleChange = this.handleChange
    const activePage = React.Children.toArray(children)[page]
    const isLastPage = page === React.Children.count(children) - 2
    const lastPage = page === React.Children.count(children) - 1
  


    return (
      
      
    <div class="background">
      <header>
        <ResponsiveAppBar></ResponsiveAppBar>
        </header>
      <body class="background">
      <Form
        initialValues={values}
        validate={this.validate}
        onSubmit={this.handleSubmit}
        
        
        
      >
        
        {({ handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit} class = "formwizard">
            {(page === 4) && (
            <Box> 
            <Typography
              variant="h4"
              align="center"
              color="#1976D2"
              size="large" sx={{ m: 1}}>
            RESULTATS
            </Typography>
            <Typography
            variant="h6"
              align="center"
              color="#1976D2"
              size="small" sx={{ m: 1}} className="grandtexte">
                Passez votre souris sur les photos pour voir les details techniques
            </Typography>
            <Typography
            variant="h6"
              align="center"
              color="#1976D2"
              size="small" sx={{ m: 1}} className="petittexte">
                Appuyez sur les photos pour voir les details techniques
            </Typography>
            </Box>
            )}
            


            {(page === 4) && (localStorage.hasOwnProperty("addresse"))   && (
                <div>
                  <Temp></Temp>

                </div>
              )}

            {(page === 4) && (
                <div className='alerteinfo'>
                  <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography
              component="h6"
              variant="h6"
              align="center"
              color="#1976D2"
              size="large"
              fontFamily="monospace"
            >Info Consommation</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Stack
  direction="column"
  justifyContent="center"
  alignItems="center"
  spacing={2}
  
>

{+localStorage.getItem("deperditions") != 0 ? 
<Typography variant="h7" align="center">
  <Stack>Déperditions (W) : {Math.round(+localStorage.getItem("deperditions"))}</Stack>
  <Divider sx={{m: 1 }} color="#1976D2" variant="inset" />
  </Typography>
  : null}

  {+localStorage.getItem("volumeecs60") != 0 ? 
  <Typography variant="h7" align="center">
  <Stack>Volume ballon eau chaude (L) : {+localStorage.getItem("volumeecs60")}</Stack>
  <Divider sx={{m: 1 }} color="#1976D2" variant="inset" />
  </Typography>
            : null}

  {+localStorage.getItem("debit") != 0 ? 
  
  <Stack direction="row" alignItems="center"><Typography variant="h7"> Débit (L/min, </Typography> <ChangeHistoryIcon size="small" style={{ color: "#1976D2", alignItems: 'center'}}></ChangeHistoryIcon> <Typography variant="h7">  30) : {+localStorage.getItem("debit")} </Typography></Stack>
  
  :null}
  
            
</Stack>

        </AccordionDetails>
      </Accordion>
      {(page === 4) && (values.question56 == "Panneaux thermiques")   && (
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
        <Typography
              component="h6"
              variant="h6"
              align="center"
              color="#1976D2"
              size="large"
              fontFamily="monospace"
            >Chauffe eau solaire</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Typography variant="h7" align="center">
    <p>Un ballon eau chaude <b class="malheureusement">classique </b> ne pourra pas être utilisé pour votre installation solaire. <br/> 
                  Un ballon solaire muni d'un échangeur thermique relié aux panneaux solaires thermiques est nécessaire. <br />
                   <b class="malheureusement"> Malheureusement, nous ne nous occupons pas encore de chauffe eau solaires, les solutions proposées ci-dessous ne seront donc pas adaptées à votre installation.</b></p>
                
    </Typography>
        </AccordionDetails>
      </Accordion>
      )}
       {(page === 4) && (values.question56 == "Panneaux photovoltaïques" && values.question83 == "Non" ) && (
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography
              component="h6"
              variant="h6"
              align="center"
              color="#1976D2"
              size="large"
              fontFamily="monospace"
            >Installation solaire</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Typography variant="h6" align="center">
    <p>Une pompe à chaleur classique ne pourra pas être utilisée pour votre installation solaire si vous ne souhaitez pas l'alimenter totalement par l’électricité du réseau. <br/> 
                  Une pompe à chaleur couplant électricité et solaire munie d'un system "Smart Grid" pour limiter l’injection sur le réseau électrique est nécessaire. <br />
                   <b class="malheureusement"> Malheureusement, nous ne nous occupons pas encore de ce genre de produits et donc les solutions proposées ci-dessous ne seront pas adaptées à votre installation. </b></p>
                
    </Typography>
        </AccordionDetails>
      </Accordion>
      )}
       {(page === 4) && (localStorage.hasOwnProperty("question54") && localStorage.getItem('question54') == "VMC") && (
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          <Typography
              component="h6"
              variant="h6"
              align="center"
              color="#1976D2"
              size="large"
              fontFamily="monospace"
            >Ballon thermodynamique</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Typography variant="h6" align="center">
    <p> Un ballon thermodynamique peut-être installé pour capter l'énergie contenue dans l'air extrait de la VMC et ainsi améliorer considerablement les performances de l'installation.<br/> 
                  Néanmoins, le débit d'air doit correspondre aux besoins du chauffe-eau thermodynamique. <br />
                   <b class="malheureusement"> Le couplage d'une VMC double flux avec un chauffe-eau thermodynamique est davantage complexe et de fait deconseillé.</b></p>
                
    </Typography>
        </AccordionDetails>
      </Accordion>
      )}
      {(page == 4 ) && (localStorage.hasOwnProperty("deperditions")) && (values.question1 === "Chauffage" || values.question1 === 'Eau Chaude Chauffage') && (values.question3 == "Solution Principale") && (values.question5 == "Maison Secondaire" || values.question5 == "Maison Principale") && (values.question49 == "Radiateur BT ou PC") && (values.question61 == "nsp" || values.question61 == "-60") && (values.question77 == "Oui mais" || values.question77 == "Oui") && (
      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
          <Typography
              component="h6"
              variant="h6"
              align="center"
              color="#1976D2"
              size="large"
              fontFamily="monospace"
            >Chaudière à bois </Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Typography variant="h6" align="center">
    <p> Une chaudière bois doit-être installée obligatoirement avec un ballon tampon pour stocker l'énergie produite et la redistribuer dans le circuit chauffage.<br/> 
                  C'est le cas également pour les chaudières à granulé, <b>SAUF SI </b>: <br />
                  - dispose d’un corps de chauffe à volume d’eau important (entre 50 et 150L) <br />
                  - dispose d’un brûleur type « volcan » qui permet de moduler sa puissance <br />
                  - puissance faible : inférieure à 25kW <br />
                  
                  
    <b class="malheureusement"> Un ballon mixte assurant également la production d’eau chaude sanitaire peut voir la capacité ECS s’inclure pour les chaudières à granulés uniquement ou les chaudières à bois utilisant parallèlement une autre énergie (idealement renouvelable)</b></p>
    </Typography>
        </AccordionDetails>
      </Accordion>
      )}
       {(page == 4 ) && (localStorage.hasOwnProperty("deperditions")) && (values.question1 === "Chauffage") && (values.question3 == "Appoint" || (values.question3 == "Solution Principale" && values.question12 <= 100 && values.question13 == 0  && values.question70 == "Oui")) && (
      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
        <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
          <Typography
              component="h6"
              variant="h6"
              align="center"
              color="#1976D2"
              size="large"
              fontFamily="monospace"
            >Emplacement du poêle</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Typography variant="h6" align="center">
      <p>
        {" "}
        L'emplacement et l'hypothétique ventilation du poêle sont deux éléments
        importants à prendre en compte au moment de votre choix :
        <br />
        Ce tableau résume les differentes possibilités pour vous aider à mieux
        vous y retrouver :<br />
      </p>
    </Typography>
    <div>
  <img className="ventilation" src={poele}></img>    
  </div>  
        </AccordionDetails>
      </Accordion>
      )}
      {(page == 4 ) && (localStorage.hasOwnProperty("deperditions")) && (values.question1 === "Chauffage") && ((values.question3 == "Solution Principale" && values.question12 <= 100 && values.question13 == 0 && values.question70 == "Oui")) && (
        <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
        <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
          <Typography
              component="h6"
              variant="h6"
              align="center"
              color="#1976D2"
              size="large"
              fontFamily="monospace"
            >Poêle en solution principale</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Typography variant="h7" align="center">
                  <p> <b> Ne pas sous estimer les contraintes associées à l'utilisation d'un poêle comme solution principale: </b><br/> 
                    - nettoyage<br />
                    - remplissage <br />
                    - garder les portes ouvertes ou convecteurs électriques dans les chambres/ salle de bain<br />
                   </p>
                </Typography>
        </AccordionDetails>
      </Accordion>
      )}
      {(page == 4 ) && (!!values.question69) && ((!!values.question28 && +values.question28.value >= 1.1 && values.question48 !== "Chaudière standard Fioul") || ( !!values.question29 && values.question29 == "Non" && values.question48 == "Chaudière standard Fioul") ) && (!!values.question16 || !!values.question81) && (values.question1 === "Chauffage" || values.question1 === 'Eau Chaude Chauffage') && (!!values.question68 && values.question68 == "Oui") && (localStorage.hasOwnProperty("deperditions")) && (localStorage.hasOwnProperty("addresse")) && (
        <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
        <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
          <Typography
              component="h6"
              variant="h6"
              align="center"
              color="#1976D2"
              size="large"
              fontFamily="monospace"
            >MaPrime Renov </Typography>
        </AccordionSummary>
        <AccordionDetails>
        <div>
                    {(values.question69 == "--") && (
                      <div>
                        <Primeb></Primeb>
                      </div>
                    )}
                    {(values.question69 == "-") && (
                      
                      <Primej></Primej>
                      
                    )}
                    {(values.question69 == "+") && (
                      <div>
                        <Primev></Primev>
                      </div>
                    )}
                    {(values.question69 == "++") && (
                      <div>
                        <Primer></Primer>
                      </div>
                    )}
                    </div>
        </AccordionDetails>
      </Accordion>
      )}

                </div>
              )}


              {(page === 4) && (!!values.question28 && !!values.question28.value) && (!values.question30.includes('Non')) && (
                <div>
              {<Reno etage = {+values.question13 + 1} hauteur = {values.question27} temperature = {values.question80} aire = {values.question12} petit_cote = {values.question40} grand_cote = {values.question41} valeur = {values.question28.value} radiateur = {values.question49} reno_type = {values.question30} toiture ={values.question43} mur={values.question37} pb={values.question45} toiture_type= {values.question42} plancher_type = {values.question44} mur_type = {values.question59} ci = {values.question46}> </Reno>} 
              
              </div>
              )}
             

              {(page === 4) && (!!values.question28 && !!values.question28.value) && (localStorage.hasOwnProperty("addresse"))   && (
                <div>
                
                 { (!!values.question31 && values.question31.includes('cadre') && !values.question31.includes('vitrage')) && (!values.question30.includes('Non')) && (
                  <div> 
                    {localStorage.setItem("deperditions", (values.question12 * (+values.question13 + 1) * values.question27) * (!!values.question28 && values.question28.value)*(values.question80 - (-10)) - ((localStorage.hasOwnProperty("Toiture") === true && localStorage.getItem('Toiture')) - (localStorage.hasOwnProperty("Plancher") === true && localStorage.getItem('Plancher')) - (localStorage.hasOwnProperty("Mur") === true && localStorage.getItem('Mur')) - ((0.05 * values.question28.value * values.question27 * values.question12 * (+values.question13 + 1 ) *(values.question80 - (-10))) - (values.question32 * (values.question33 - values.question33*0.8) * (values.question80 - (-10))))))}
                  </div>
                 )}
                 { (!!values.question31 && values.question31.includes('vitrage') && !values.question31.includes('cadre')) && (!values.question30.includes('Non')) && (
                  <div> 
                    {localStorage.setItem("deperditions",(values.question12 * (+values.question13 +1 ) * values.question27) * (!!values.question28 && values.question28.value)*(values.question80 - (-10)) - ((localStorage.hasOwnProperty("Toiture") === true && localStorage.getItem('Toiture')) - (localStorage.hasOwnProperty("Plancher") === true && localStorage.getItem('Plancher')) - (localStorage.hasOwnProperty("Mur") === true && localStorage.getItem('Mur'))  - (((0.10 * values.question28.value * values.question27 * values.question12 * (+values.question13 +1) *(values.question80 - (-10)))) - (values.question35 * values.question33*0.8 * (values.question80 - (-10))))))}
                  </div>
                 )}
                  { (!!values.question31 && values.question31.includes('cadre') && values.question31.includes('vitrage')) && (!values.question30.includes('Non')) && (
                  <div> 
                    {localStorage.setItem("deperditions",(values.question12 * (+values.question13 +1) * values.question27) * (!!values.question28 && values.question28.value)*(values.question80 - (-10)) - ((localStorage.hasOwnProperty("Toiture") === true && localStorage.getItem('Toiture')) - (localStorage.hasOwnProperty("Plancher") === true && localStorage.getItem('Plancher')) - (localStorage.hasOwnProperty("Mur") === true && localStorage.getItem('Mur')) - ((0.05 * values.question28.value * values.question27 * values.question12 * (+values.question13 + 1) *(values.question80 - (-10))) - (values.question32 * (values.question33 - values.question33*0.8) * (values.question80 - (-10)))) - (((0.10 * values.question28.value * values.question27 * values.question12 * (+values.question13 + 1) *(values.question80 - (-10)))) - (values.question35 * values.question33*0.8 * (values.question80 - (-10))))))}
                  </div>
                 )}
                 { (!values.question30.includes('Fenêtre')) && (!values.question30.includes('Non')) && (
                  <div>  
                    {localStorage.setItem("deperditions",(values.question12 * (+values.question13 + 1) * values.question27) * (!!values.question28 && values.question28.value)*(values.question80 - (-10)) - ((localStorage.hasOwnProperty("Toiture") === true && localStorage.getItem('Toiture')) - (localStorage.hasOwnProperty("Plancher") === true && localStorage.getItem('Plancher')) - (localStorage.hasOwnProperty("Mur") === true && localStorage.getItem('Mur'))))}
                  </div>
                 )}
                {(values.question30.includes('Non')) && (
                  <div> 
                   {localStorage.setItem("deperditions",(values.question12 * (+values.question13 +1) * values.question27) * (values.question28.value)*(values.question80 - (-10)))}
                   
                   
                   
                  </div>
                 )}
                </div>
                )}

                {(page === 4) && (!!values.question16) && ((!!values.question25 && values.question25 == "Oui") || typeof values.question25 == "undefined") && (
                  <div>
                    {(values.question16 - (!!values.question18 && values.question18) == 0)  && (
                      <div>  {localStorage.setItem("debit",(values.question18 *14))}</div>
                     
                      )}

                      {(values.question16 - (!!values.question17 && values.question17) == 0) && ((!!values.question18 && values.question18) == 0) && (
                      <div> {localStorage.setItem("debit",(0.95*(values.question17 *9 )))}</div>
                     
                      )}

                    {(values.question16 - (!!values.question18 && values.question18) - (!!values.question17 && values.question17) == 0) && (
                      <div>  {localStorage.setItem("debit",(0.9*(values.question18 *14 + values.question17 *9 )))}</div>
                     
                      )}
                      {(values.question16 - (!!values.question18 && values.question18) - (!!values.question17 && values.question17) - (!!values.question20 && values.question20) == 0) && (
                      <div>  {localStorage.setItem("debit",(0.9*(values.question18 *14 + values.question17 *9 + values.question20 * 7.8 )))}</div>
                     
                      )}
                      
                      {((values.question16 - (!!values.question18 && values.question18) - (!!values.question17 && values.question17) - (!!values.question20 && values.question20) - (!!values.question19 && values.question19)) < 0) && (
                      <div> {localStorage.setItem("debit",(0.9*(values.question18 *14 + values.question17 *9 + values.question20 * 7.8 + (values.question16 - (!!values.question18 && values.question18) - (!!values.question17 && values.question17) - (!!values.question20 && values.question20))* 1 )))}</div>
                     
                      )}
                      {((values.question16 - (!!values.question18 && values.question18) - (!!values.question17 && values.question17) - (!!values.question20 && values.question20) - (!!values.question19 && values.question19)) >= 0) && (
                      <div> {localStorage.setItem("debit",(0.9*(values.question18 * 14 + values.question17 * 9 + values.question20 * 7.8 + values.question19 * 1 )))}</div>
                     
                      )}

                  </div>

                )}

                {(page === 4) && (!!values.question16) && (!!values.question25 && values.question25 == "Non") &&  (
                  <div>
                    {(values.question16 - (!!values.question18 && values.question18) == 0)  && (
                      <div> {localStorage.setItem("debit",(values.question18 *14))}</div>
                     
                      )}

                      {(values.question16 - (!!values.question17 && values.question17) == 0) && ((!!values.question18 && values.question18) == 0) && (
                      <div> {localStorage.setItem("debit",(0.85*(values.question17 *9 )))}</div>
                     
                      )}

                    {(values.question16 - (!!values.question18 && values.question18) - (!!values.question17 && values.question17) == 0) && (
                      <div> {localStorage.setItem("debit",(0.8*(values.question18 *14 + values.question17 *9 )))}</div>
                     
                      )}
                      {(values.question16 - (!!values.question18 && values.question18) - (!!values.question17 && values.question17) - (!!values.question20 && values.question20) == 0) && (
                      <div>  {localStorage.setItem("debit",(0.8*(values.question18 *14 + values.question17 *9 + values.question20 * 7.8 )))}</div>
                     
                      )}
                      
                      {((values.question16 - (!!values.question18 && values.question18) - (!!values.question17 && values.question17) - (!!values.question20 && values.question20) - (!!values.question19 && values.question19)) < 0) && (
                      <div>  {localStorage.setItem("debit",(0.8*(values.question18 *14 + values.question17 *9 + values.question20 * 7.8 + (values.question16 - (!!values.question18 && values.question18) - (!!values.question17 && values.question17) - (!!values.question20 && values.question20))* 1 )))}</div>
                     
                      )}
                      {((values.question16 - (!!values.question18 && values.question18) - (!!values.question17 && values.question17) - (!!values.question20 && values.question20) - (!!values.question19 && values.question19)) >= 0) && (
                      <div> {localStorage.setItem("debit",(0.8*(values.question18 * 14 + values.question17 * 9 + values.question20 * 7.8 + values.question19 * 1 )))}</div>
                     
                      )}

                  </div>

                )}


                {(!!values.question21 || !!values.question22) && (page == 4) && (values.question1 != "Chauffage") && (
                  <div> 
                     {localStorage.setItem("volumeecs60",(2* values.question19 * 1 +(!!values.question20 && values.question20) * 7.8 * 5 + ((!!values.question21 && values.question21)*7*9)+((!!values.question22 && values.question22)*8*14))*0.5)}
                     
                  </div>
                )}

                {(!!values.question21 || !!values.question22) && ((values.question3 == "Solution Principale") || (values.question3 == "Appoint" && values.question71 == "plusieurs")) && (page == 4) && (values.question1 == "Eau Chaude") && (
                  <div> 
                     <Cumulus></Cumulus>
                  </div>
                )}

                {(values.question3 == "Appoint") && (page == 4) && (values.question1 == "Eau Chaude") && (values.question71 == "lavabo" || values.question71 == "douche" ) && (
                  <div> 
                     <Cei utilisation = {values.question71} proximite = {values.question11? values.question11 : "undefined"} cuisine = {values.question10? values.question10 : "undefined"}></Cei>
                  </div>
                )}

                 {(page == 4 ) && (localStorage.hasOwnProperty("deperditions")) && (values.question1 === "Chauffage") && (values.question3 == "Appoint") && (!!values.question67) && (
                  <div>
                    <Insert Longueur = {values.question67.label} ></Insert>      
                  </div>
                )}

                {(page == 4 ) && (localStorage.hasOwnProperty("deperditions")) && (values.question1 === "Chauffage") && (values.question3 == "Appoint" || (values.question3 == "Solution Principale" && values.question12 <= 100 && values.question13 == 0 && values.question70 == "Oui")) && (
                  <div>      
                    <Foyer> </Foyer>
                  </div>
                )}
               
                {(page == 4 ) && (localStorage.hasOwnProperty("deperditions")) && (values.question1 === "Chauffage" || values.question1 === 'Eau Chaude Chauffage') && (values.question3 == "Solution Principale") && (values.question5 == "Maison Secondaire" || values.question5 == "Maison Principale") && (values.question49 == "Radiateur BT ou PC") && (values.question61 == "nsp" || values.question61 == "-60") && (values.question77 == "Oui mais" || values.question77 == "Oui") && (
                  <div>
                    <Chaudierebois></Chaudierebois>
                  </div>
                )}

                {(page == 4 ) && (values.question1 === "Chauffage" || values.question1 === 'Eau Chaude Chauffage') && (values.question3 == "Solution Principale") && (((values.question5 == "Appartement Secondaire" || values.question5 == "Appartement Principal") && (localStorage.hasOwnProperty("question15") && localStorage.getItem("question15") == "Conduit Ventouse")) || (values.question5 == "Maison Secondaire" || values.question5 == "Maison Principale")) && (values.question8 == "Oui") && (localStorage.hasOwnProperty("deperditions")) && (values.question49 == "Radiateur BT ou PC") && (
                  <div>
                    <Gazcondens question1 = {values.question1}></Gazcondens>
                  </div>
                )}
                {(page == 4 ) && (values.question1 === "Chauffage" || values.question1 === 'Eau Chaude Chauffage') && (values.question3 == "Solution Principale") && ((values.question5 == "Appartement Secondaire" || values.question5 == "Appartement Principal") && (localStorage.hasOwnProperty("question15") && localStorage.getItem("question15") != "Conduit Ventouse")) && (values.question8 == "Oui") && (localStorage.hasOwnProperty("deperditions")) && (values.question49 == "Radiateur BT ou PC")  &&(
                  <div>
                    <Basnox question1 = {values.question1} fumi = {localStorage.getItem("question15")}></Basnox>
                  </div>
                )}
                {(page == 4 ) && (values.question3 == "Solution Principale") && (values.question5 == "Maison Secondaire" || values.question5 == "Maison Principale") && (typeof values.question52 == 'undefined') && (values.question1 == "Eau Chaude" ) && (!!values.question76) && (
                  <div>
                    <Cet question1 = {values.question1} question16={values.question16} piece={values.question76} ></Cet>
                  </div>
                )}

                {(page == 4 ) && (values.question5 == "Maison Secondaire" || values.question5 == "Maison Principale") && (localStorage.hasOwnProperty("deperditions")) && (typeof values.question52 == 'undefined') && ((values.question1 == "Chauffage" || values.question1 == "Eau Chaude Chauffage") && !!values.question49) && (
                  <div>
                    <Pac question1 = {values.question1} question16={values.question16} radiateur={values.question49}></Pac>
                  </div>
                )}

                {(page == 4 ) && (values.question5 == "Maison Secondaire" || values.question5 == "Maison Principale") && (localStorage.hasOwnProperty("deperditions")) && (!!values.question52) && (!!values.question49) && (
                  <div>
                    <Bivalence question1 = {values.question1} chaud = {values.question52} radiateur={values.question49} ></Bivalence>
                  </div>
                )}
                
              


                {activePage}
                
            <div className="buttons">
              {page > 0 && (
                <Box m={2} >
                <Button sx={{ margin: 2 }} variant="contained" style={{backgroundColor: "#2196F3", color: "white", borderRadius:"5%" }} onClick={this.previous}>« Precedant </Button>
                </Box>
              )}
              {(page < 3) && (
                <Box m={2} >
                  {(localStorage.hasOwnProperty("addresse")) && (
                    <Box m={2} >
                <Button sx={{ margin: 2 }} variant="contained" style={{backgroundColor: "#2196F3", color: "white", borderRadius:"5%" }} type="submit">Suivant {page}/3 »</Button>
                </Box>
                )}
                {(localStorage.getItem("addresse") === null) && (
                  <Box m={2} >
                <Button sx={{ margin: 2 }} variant="contained" style={{backgroundColor: "#2196F3", color: "white", borderRadius:"5%" }} onClick={(e) => this.handleAddresse(e)}>Suivant {page}/3 »</Button>
                </Box>
                )}
                </Box>
              )}

              {isLastPage && (
                <Box m={2} >
                <Button sx={{ margin: 2 }} variant="contained" style={{backgroundColor: "#2196F3", color: "white", borderRadius:"5%" }}  type="submit" disabled={submitting}>Resultats</Button>
                </Box>
              )}
              {lastPage && (
              
              <Box >
                {(!!values.RGPD && values.RGPD.includes("true")) && (
                  <Box m={2} >
              <Button variant="contained"  style={{backgroundColor: this.state.color, color: "white", borderRadius:"5%" }}  onClick={(e) => this.loadUsers(e,values)} >{this.state.button_text}</Button>
              </Box>
              )}
              {(typeof(values.RGPD) == "undefined" || typeof(values.email) == "undefined" || typeof(values.lastName) == "undefined" || typeof(values.firstName) == "undefined" || typeof(values.questionfinal) == "undefined") && (
                <Box m={2} >
              <Button variant="contained"  style={{backgroundColor: "#2196F3", color: "white", borderRadius:"5%" }} type="submit" disabled={submitting}>Recevoir des devis</Button>
              </Box>
              )}
              </Box>
              
              )}
            </div>
            {lastPage && (
            <div>
              
              Pour en savoir plus sur la gestion de vos données personnelles et exercer vos droits, consultez : <Link to={"/donnees"} style={{ textDecoration: 'none' }}> Charte de traitement des données </Link>
              </div>
            )}
            
          </form>
        )}
      </Form>
      </body>  
      <footer>
                <Foot></Foot>
      </footer>
      </div>
    )
  }
}
