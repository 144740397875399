import * as React from 'react';
import { useRef, useEffect} from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import "./menuitem.css"
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ResponsiveAppBar from '../header/header.js';
import Foot from "../header/footer.js"
import { useNavigate, useParams } from "react-router-dom";
import MainFeaturedPost from './MainFeaturedPost';
import FeaturedPost from './FeaturedPost';
import Item from "./item";
import Divider from '@material-ui/core/Button';
import {Carousel } from "./carousel";
import InputLabel from '@mui/material/InputLabel';
import MuiMenuItem from "@material-ui/core/MenuItem";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { withStyles } from "@material-ui/core/styles";

const scrollToSection = (elementRef) => {
  window.scrollTo({
    top: elementRef.current.offsetTop,
    behavior: "smooth",
  });
};

const MenuItem = withStyles({
  root: {
    flexDirection: "column",
    margin: 1
  }
})(MuiMenuItem);

const theme = createTheme({
});

const TestComponent = () => {
    const navigate = useNavigate();

    return (
        <Button variant="contained"style={{
          
          backgroundColor: "#2196F3", borderRadius:"5%"
          
      }} onClick={() => navigate('/test')}>Passer le test</Button>
    );
};

const mainFeaturedPost = {
    title: 'Guide : Choisir entre radiateur à eau et radiateur électrique',
    description:
      "Êtes-vous en train de rénover votre maison ou votre appartement et vous demandez-vous quel système de chauffage choisir entre les radiateurs électriques et les radiateurs à eau ? Choisissez le radiateur qui convient le mieux à votre maison et à votre budget ",
    image: 'https://www.qualitel.org/particuliers/uploads/shutterstock_644783233.jpg',
    imageText: 'main image description',
    url: "https://www.simucho.fr/radiateur/radiateur_à_eau/15",
    linkText: 'Continuer à lire…',
  };
  
  const featuredPosts = [
    {
      title: 'Guide : Comment choisir son radiateur à eau',
      description:
        " Si vous êtes à la recherche d'une solution de chauffage efficace, économique et esthétique, alors vous êtes au bon endroit.",
      image: 'https://www.espace-aubade.fr/uploads/blog/parts/630x410/20211210-radiateur-inertie-seche-et-fluide-quelles-differences-2.jpg',
      imageText: 'main image description',
      url: "https://www.simucho.fr/radiateur/radiateur_à_eau/14",
      linkText: 'Continuer à lire…',
    },
    {
      title: 'Guide : Comment choisir son radiateur électrique',
      description:
        "Découvrez comment choisir un radiateur électrique performant et économique selon sa puissance, le type de radiateur, le thermostat et la connectivité.",
      image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQqdGvAO_TCuKf7czLyi2ftNgImNPv2-40lpw&usqp=CAU',
      url: "https://www.simucho.fr/radiateur/convecteur_electrique/22",
      imageLabel: 'Continuer à lire…',
    },
  ];


export default function Radiateur() {
  let {id} = useParams();
  const souscategorie = useRef(null);
  const [souscate, setSouscate] = React.useState(id);
  const [newcate, setNewcate]= React.useState(id)


   const handleChange = (event) => {
    
    setSouscate(event.target.value);
    setNewcate(souscate)
   
  };

  function Carou() {
    
    useEffect(() => {
     
    }, [newcate,souscate,id]); 
    // <- add empty brackets here
  
    return <Carousel souscategorie={ newcate } categorie={"radiateur"} ></Carousel>;
  }
  
  useEffect(() => {
      
      setNewcate(souscate)
      
     
  }, [souscate]); 

  useEffect(() => {
      
    setNewcate(id)
    
   
}, [id]); 

  return (
    <ThemeProvider theme={theme}>
   
      <AppBar position="relative">
        <ResponsiveAppBar></ResponsiveAppBar>
      </AppBar>
      <main>
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: '#F4F9F9',
            pt: 8,
            pb: 6,
          }}
        >
          <Box
          sx={{
            marginLeft: "10%",
            width:"80%",
            pt: 5,
            pb: 6,
          }}
        >
        
          <MainFeaturedPost post={mainFeaturedPost} />
          <Grid container  spacing={4} >
          
            {featuredPosts.map((post) => (
             
              <FeaturedPost key={post.title} post={post} />
             
            ))}
         
          </Grid>
          </Box>
          <Stack
              sx={{ pt: 7 }}
              direction="row"
              spacing={2}
              display= "flex" alignItems="center" justifyContent="center"
            >
               <Divider
          flexItem
          
          style={{backgroundColor: "#1976D2", width:'50%',height:"50%" }} > </Divider>
        <Button variant="outlined" size="large"  style={{ borderRadius:"5%" }} onClick={() => scrollToSection(souscategorie)}>  Nos Derniers Articles Par Sous-Categorie </Button>
        <Divider
          flexItem
          style={{backgroundColor: "#1976D2", width:'50%', height:"50%"  }} > </Divider>
        </Stack>
          <Box
          sx={{
            marginLeft: "5%",
            width:"90%",
            pt: 5,
            pb: 6,
          }}
          ref={souscategorie} 
         
        >
        <Box className="box1"   sx={{ minWidth: 50, maxWidth:180,  }} >
       
      <FormControl className="choice">
        <InputLabel>Sous-Catégorie</InputLabel>
        <Select
          value={id != undefined ? newcate : "radiateur_à_eau"}
          onChange={handleChange}
          autoWidth
          
        
        >
        

         
          <MenuItem  value={"convecteur_electrique"}>Convecteur Electrique</MenuItem>
          <MenuItem   value={'radiateur_à_eau'}>Radiateur à Eau</MenuItem>
       
        </Select>
      </FormControl >
      </Box>
          <Carou ></Carou>
          
       </Box>
        </Box>
      </main>
      {/* Footer */}
      <Box   component="footer">
        <Foot></Foot>
      </Box>
      {/* End footer */}
    </ThemeProvider>
  );
}