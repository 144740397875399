import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import CameraIcon from '@mui/icons-material/PhotoCamera';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ResponsiveAppBar from './header/header.js';
import Foot from "./header/footer.js"
import { useNavigate } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import {Helmet} from "react-helmet";

const useStyles = makeStyles(theme => ({
  btn: {
     
     borderColor: "#2196F3",
     color: "#2196F3",
     backgroundColor: "white", 
     "&:hover": {
         color: "#2196F3",
         borderColor:"#2196F3"
     },
  },

}));

const TestComponent = () => {
    const navigate = useNavigate();

    return (
        <Button variant="contained"  style={{
          
          backgroundColor: "#2196F3", borderRadius:"5%"
          
      }} onClick={() => navigate('/test')}>Passer le test</Button>
    );
};

const Contactbutton = () => {
  const navigate = useNavigate();
  const classes = useStyles();

  return (
      <Button variant="outlined" className={classes.btn} onClick={() => navigate('/Contact')}>Contact</Button>
  );
};

const theme = createTheme();

export default function Apropos() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="relative">
        <ResponsiveAppBar></ResponsiveAppBar>
      </AppBar>
      <main>
      <Helmet>
        <title>À Propos - Simucho </title>
        <meta name="description" content="Simucho est une entreprise francaise et indépendante spécialisée en solutions de chauffage et eau chaude crée en 2020 par la fratrie Audidier." />
        <link rel="canonical" href="https://www.simucho.fr/APropos" />
      </Helmet>
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: '#F4F9F9',
            pt: 8,
            pb: 6,
          }}
        >
          <Box
          sx={{
            bgcolor:'#fff',
            width: '80%',
            padding: "3%",
            marginLeft: "10%",
            borderRadius: 6,
            boxShadow: 1
          }}
        >
          <Container maxWidth="md">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
            >
              A Propos
            </Typography>
            <Typography variant="h5" align="center" color="text.secondary" paragraph>
              Fondé en 2022 par la fratrie Audidier. Simucho est le fruit de 
              2 ans de recherche et développement pour vous présenter les solutions chauffage et eau chaude pour votre logement respectueuses de votre budget et de l'environnement.<br/> 
              Simucho est une entreprise francaise et indépendante. <b>Nous ne sommes pas affiliés à un reseau d'installateurs ou à un fabricant.</b>
            </Typography>
            <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="center"
            >
              
              <TestComponent></TestComponent>
              <Contactbutton></Contactbutton>
            </Stack>
          </Container>
          </Box>
        </Box>
      </main>
      {/* Footer */}
      <Box  component="footer">
        <Foot></Foot>
      </Box>
      {/* End footer */}
    </ThemeProvider>
  );
}