const source = {
    0.08: 'Gaz',
    0.0958: 'Fioul',
    0.058: 'Mazout',
    0.1392: 'Propane',
    0.16: 'Électricité ',
    0:'Solaire ',
    0.045: 'Bûche',
    0.070: 'Granulé',

  }
  export default Object.keys(source).map(value => ({
    value: value,
    label: source[value]
  }))