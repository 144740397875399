const combustible = {
    4.4: 'Sapin',
    4.2: 'Chêne',
    4.21: 'Hêtre',
    5.0: 'Pellets',
    3.9: 'Bois déchiqueté, copeaux, plaquette',
    4.3:'Ne sait pas/ autre',

  }
  export default Object.keys(combustible).map(value => ({
    value: value,
    label: combustible[value]
  }))