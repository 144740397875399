import React, { Component } from "react";
import M  from "materialize-css";
import "./carousel.js";
import "./Stylestyle.css"


function shuffle(array) {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}


export default class Newcarousel extends Component {
    constructor(props) {
        super(props);
        
    
      }
  componentDidMount() {
    const options = {
      duration: 300,
      onCycleTo: () => {
        console.log("New Slide");
      }
    };
    M.Carousel.init(this.Carousel, options);

    //Instance Plugin
    // let instance = M.Carousel.getInstance(this.Carousel);
    // instance.next(2);
  }
  items = this.props.photo.map((subArray, index) =>

  <a className="carousel-item">
    <div  className="containerbis">
       <img src={(this.props.photo[index])} alt={index}   />
       <div className="overlay">
        <div className="text">  {this.props.type ?  this.props.name[index] : (this.props.name[index].substring(0,this.props.name[index].length - (this.props.name[index].length/2)))}  </div>
      <div className="text4">{this.props.type ?  this.props.type[index] : (this.props.name[index].substring((this.props.name[index].length/2),))}</div>
          <div className="text1">{(this.props.prop1[index])} </div>
        <div className="text2">{(this.props.prop2[index])} </div>
        <div className="text3">{(this.props.prop3[index])} </div>
    </div>
        </div>
       </a>
       
      
       )

  render() {
    return (
      <div
        ref={(Carousel) => {
          this.Carousel = Carousel;
        }}
        className="carousel"
      >
         
        {shuffle(this.items)}
      </div>
    );
  }
}

