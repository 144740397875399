import React, { useEffect, useState, useRef} from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ResponsiveAppBar from '../header/header.js';
import Foot from "../header/footer.js";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Fab from '@mui/material/Fab';
import useWindowDimensions from './useWindowDimensions.js';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate, useParams } from "react-router-dom";
import Divider from '@material-ui/core/Button';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import axios from 'axios';
import IconButton from '@mui/material/IconButton';
import {Carousel} from "./carousel.js"
import "./article.css";
import {Helmet} from "react-helmet";
import CustomizedSnackbars from "./snackBar.js"


const theme = createTheme({});
export default function Articletemplate(props) {
    const { height, width } = useWindowDimensions();
    let {id} = useParams();
    let{firstid} = useParams();
    const navigate = useNavigate();
    var [dat,setDat] = useState(92)
    var [photo,setPhoto] = useState("")
    var [titre,setTitre] = useState("")
    var [cate,setCate] = useState([])
    var [soustitre, setSoustitre] = useState([])
    var [text, setText] = useState([])
    var [meta, setMeta] = useState("")
    const ahref = window.location.href
    const encodedAhref = encodeURIComponent(ahref)
    const ref = useRef();
    const isVisible = useIsVisible(ref);
    const deuxieme = useRef();
    const isDeuxieme = useIsVisible(deuxieme);
    const troisieme = useRef();
    const isTroisieme = useIsVisible(troisieme);
   
  function useIsVisible(ref) {
    const [isIntersecting, setIntersecting] = useState(false);
  
    useEffect(() => {
      const observer = new IntersectionObserver(([entry]) =>
        setIntersecting(entry.isIntersecting)
      );
  
      observer.observe(ref.current);
      return () => {
        observer.disconnect();
      };
    }, [ref]);
  
    return isIntersecting;
  }

  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  };
  
    useEffect(() =>{

      if (id !== undefined) {
       
      fetch("https://www.simucho.fr/dataarticles")
        .then(res => res.json())
        .then(
          (result) => {
            
            result["Articles"].forEach(element => {
             
              if(Number(element["id"]) == Number(id) ){
                {setDat(id)}
                {setTitre(element["titre"])}
                {setCate([element["categorie"][0],element["categorie"][1]])}
                {setSoustitre([element["sous-titre"][0],element["sous-titre"][1],element["sous-titre"][2]])}
                {setText(element["text"])}
                {setPhoto(element["url"])}
                {setMeta(element["meta"])}
             
                
              }
            
            });
          },
        )
      }},[id])


    const handleClick = () => {
      console.info('You clicked the Chip.');
      
    };
    
    return (
    <ThemeProvider theme={theme}>
   
      <AppBar position="relative">
        <ResponsiveAppBar></ResponsiveAppBar>
      </AppBar>
      <main >
        {/* Hero unit */}
        <Helmet>
        <title>{titre}</title>
        <meta name="description" content={meta} />
        <link rel="canonical" href={`https://www.simucho.fr/${props.categorie}/${firstid}/${id}`} />
      </Helmet>
        <Box
          sx={{

            bgcolor: '#F4F9F9',
            pt: 4,
            pb: 6,
          }}
        >
            <Box
          sx={{ marginLeft: "10%", width:"80%" }}
        >
            
            <Grid container spacing={2} display="flex" alignItems="stretch" justifyContent="center">
          
          <Grid item xs sm md lg>
          {width > 500 ? <Box component="img" maxHeight={500} maxWidth={500}  style={{ borderRadius:28, minWidth:"200px", minHeight:"300px"}} src={photo}></Box>:<Box component="img" maxWidth={"350px"} style={{ borderRadius:28}} src={photo}></Box>}
        </Grid>
        <Grid item xs sm md lg>
            <Box >
       <h1  style={{flexWrap: 'wrap', lineHeight: "45px", color:"#000000"}}> {titre} </h1>
       <Box id="boite" size="small" component={Divider} boxShadow={1} borderRadius={28} backgroundColor="#fff" sx={{bgcolor:"#fff", width:"50%"}} />
       <Stack margin={1} marginTop={3} direction="row" spacing={1} alignItems="center" justifyContent={"center"}>
      <Button size="small" style={{color:"#1976D2", borderRadius: 28}}  variant="outlined" onClick={() => navigate(`/${props.categorie.replaceAll(" ","")}`)}> {props.categorie.replaceAll("_"," ")} </Button>
      <Button size="small" style={{color:"#1976D2", borderRadius: 28}}  variant="outlined"  onClick={() => navigate(`/${props.categorie.replaceAll(" ","")}/${firstid}`)}> {firstid.replaceAll("_"," ")} </Button>
    </Stack>
    <Stack marginLeft={1} marginTop={3} direction="row" spacing={3} alignItems="center" justifyContent={"center"}>
    <a href={'https://twitter.com/intent/tweet?url='+encodedAhref} > <IconButton style={{borderRadius: "50%"}}><TwitterIcon style={{borderRadius: "50%", fontSize:"150%" , backgroundColor:"#fff", color:"#1976D2"}}></TwitterIcon></IconButton></a>
    <a href={'https://www.linkedin.com/sharing/share-offsite/?url='+ahref} > <IconButton style={{borderRadius: "50%"}}> <LinkedInIcon style={{borderRadius: "50%", fontSize:"150%" , backgroundColor:"#fff", color:"#1976D2"}}></LinkedInIcon></IconButton></a>
    <a href={'https://www.facebook.com/sharer/sharer.php?u='+ahref} > <IconButton style={{borderRadius: "50%"}}><FacebookIcon style={{borderRadius: "50%", fontSize:"150%" , backgroundColor:"#fff", color:"#1976D2"}} ></FacebookIcon></IconButton></a>
    <CustomizedSnackbars url={ahref}> </CustomizedSnackbars>
          
    </Stack>
       </Box>
       </Grid>
       </Grid>
       </Box>
       <Box
          sx={{
            marginLeft: "5%",
            width:"90%",
            pt: 5,
            pb: 6,
          }}
        >
            {width < 500 ? 
            <div  > 

                <Accordion sx={{mb:1}}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ml:1, color:"#1976D2"}} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography variant="h5" align='center' sx={{ml:1, color:"#1976D2"}}>Sommaire</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Button >
                {isVisible && !(isDeuxieme) ? <Typography variant="h7" sx={{color:"#1976D2"}} onClick={() => scrollToSection(ref)}> {soustitre[0]} </Typography> : <Typography  variant="h7" sx={{color:"#000000"}} onClick={() => scrollToSection(ref)}> {soustitre[0]} </Typography>}
                </Button>
          <br/>
          <Button>
          {isDeuxieme && !(isTroisieme) ? <Typography variant="h7" sx={{color:"#1976D2"}} onClick={() => scrollToSection(deuxieme)} > {soustitre[1]} </Typography> : <Typography variant="h7" onClick={() => scrollToSection(deuxieme)} sx={{color:"#000000"}} > {soustitre[1]} </Typography>}
          </Button>
          <br/>
          <Button>
          {isTroisieme ? <Typography variant="h7" sx={{color:"#1976D2"}} onClick={() => scrollToSection(troisieme)}> {soustitre[2]} </Typography> : <Typography variant="h7" onClick={() => scrollToSection(troisieme)} sx={{color:"#000000"}} > {soustitre[2]} </Typography>}
          </Button>
          </AccordionDetails>
                </Accordion>

                <Box align='center' sx={{boxShadow:4, borderRadius:5, backgroundColor:"#fff", marginBottom:2 }}>
            <Typography variant="h6" align='center' sx={{padding:1, color:"#1976D2"}}>Devis & solutions <br/>sans création de compte</Typography>
            <Box  align='center'>
            <Fab  style={{backgroundColor: "#1976D2", borderRadius: 28, marginBottom:10}}  variant="extended" onClick={() => navigate('/test')} >
                <DoubleArrowIcon   sx={{backgroundColor: "#fff", color:"#1976D2", borderRadius:"50%", m: 1 }} />
            <Typography align='center' sx={{m:1, color:"#fff"}}>
                
        Decouvrir vos solutions
        </Typography>
        
      </Fab>
      </Box>
      </Box>
                </div> : null}


            <Grid container  spacing={2} >
            {width > 500 ? 
          <Grid item xs={3} >
          
         
            <div className='sticky' > 

            <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ml:1, color:"#1976D2"}} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h5" align='center' sx={{ml:1, color:"#1976D2"}}>Sommaire</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Button >
                {isVisible && !(isDeuxieme) ? <Typography variant="h7" sx={{color:"#1976D2"}} onClick={() => scrollToSection(ref)}> {soustitre[0]} </Typography> : <Typography  variant="h7" sx={{color:"#000000"}} onClick={() => scrollToSection(ref)}> {soustitre[0]} </Typography>}
                </Button>
          <br/>
          <Button>
          {isDeuxieme && !(isTroisieme) ? <Typography variant="h7" sx={{color:"#1976D2"}} onClick={() => scrollToSection(deuxieme)} > {soustitre[1]} </Typography> : <Typography variant="h7" onClick={() => scrollToSection(deuxieme)} sx={{color:"#000000"}} > {soustitre[1]} </Typography>}
          </Button>
          <br/>
          <Button>
          {isTroisieme ? <Typography variant="h7" sx={{color:"#1976D2"}} onClick={() => scrollToSection(troisieme)}> {soustitre[2]} </Typography> : <Typography variant="h7" onClick={() => scrollToSection(troisieme)} sx={{color:"#000000"}} > {soustitre[2]} </Typography>}
          </Button>
          </AccordionDetails>
      </Accordion>

            <Box sx={{boxShadow:4, borderRadius:5, backgroundColor:"#fff",'& > :not(style)': { marginBottom: 2}}} alignItems="center" justifyContent={"center"}>
            <Typography variant="h6" align='center' sx={{m:1,padding:1, color:"#1976D2"}}>Devis & solutions <br/>sans création de compte</Typography>
            <Box  align='center'>
            <Fab  style={{backgroundColor: "#1976D2", borderRadius: 28, justifyContent:"center", marginBottom:10}}  variant="extended" onClick={() => navigate('/test')} >
                <DoubleArrowIcon   sx={{backgroundColor: "#fff", color:"#1976D2", borderRadius:"50%", m: 1 }} />
            <Typography align='center' sx={{m:1, color:"#fff"}}>  Decouvrir vos solutions </Typography>
            </Fab>
      </Box>
      </Box>
      </div>
          
            </Grid>
            : null }


            <Grid item xs>
            <Box sx={{
            bgcolor:'#fff',
            padding: "2%",
            borderRadius: 6,
            
           
          }}>
          <Typography sx={{ padding:0.5}}>
          <Typography ref={ref} variant="h5" sx={{ mb:0.5, color:"#1976D2"}}> {soustitre[0]}  </Typography>
          <br/>
        
          <div dangerouslySetInnerHTML={{__html: text[0] }} />
        
         <br/>
          <Typography ref={deuxieme} variant="h5" sx={{ mb:0.5, color:"#1976D2"}}>{soustitre[1]} </Typography>
          <br/>
          <div dangerouslySetInnerHTML={{__html: text[1] }} />
          <br/>
          <Typography ref={troisieme} variant="h5"  sx={{ mb:0.5, color:"#1976D2"}}>{soustitre[2]}</Typography>
          <br/>
          <div dangerouslySetInnerHTML={{__html: text[2] }} />
         
         <br/>
          <br/>
          {}

        </Typography>
          </Box>
            </Grid>
            </Grid>

            <Stack sx={{ pt: 5 }} direction="row" spacing={2} display= "flex" alignItems="center" justifyContent="center">
               <Divider flexItem style={{backgroundColor: "#1976D2", width:'50%',height:"50%" }} > </Divider>
        <Button variant="outlined" size="large" style={{ borderRadius:"50%", color:"#1976D2", backgroundColor:"#fff"}}> <Typography margin={0.25}>< AddCircleOutlineIcon size="small" style={{color: "#1976D2", paddingTop:"5px", alignItems: 'center'}} /> d’articles sur ce thème </Typography>  </Button>
        <Divider
          flexItem
          style={{backgroundColor: "#1976D2", width:'50%', height:"50%"  }} > </Divider>
        </Stack>  
        </Box >
        <Box marginLeft={"5%"} width="90%" marginRight={"5%"}>
       
          <Carousel souscategorie={firstid} categorie={props.categorie.replaceAll(" ","")} ></Carousel>
          </Box>
        </Box>
      </main>
      {/* Footer */}
      <Box  component="footer">
        <Foot></Foot>
      </Box>
      {/* End footer */}
    </ThemeProvider>
  );
}