import React from 'react';
import cx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { useCoverCardMediaStyles } from '@mui-treasury/styles/cardMedia/cover';
import { useLightTopShadowStyles } from '@mui-treasury/styles/shadow/lightTop';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import'../Style.css'
import LocalFireDepartmentSharpIcon from '@mui/icons-material/LocalFireDepartmentSharp';

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 304,
    margin: 'auto',
    borderRadius: 6,
    position: 'relative',
  },
  content: {
    padding: 24,
  },
  cta: {
    display: 'block',
    textAlign: 'center',
    color: '#fff',
    letterSpacing: '3px',
    fontWeight: 200,
    fontSize: 12,
  },
  title: {
    color: '#92B4F4',
    letterSpacing: '2px',
  },
}));

export const NewsCard2Demo = React.memo(function NewsCard2() {
  const styles = useStyles();
  const mediaStyles = useCoverCardMediaStyles();
  const shadowStyles = useLightTopShadowStyles();
  return (

    
    
      <Grid container alignItems="center" spacing={3} justifyContent="center">

      <Grid item xs={8} sm={3} md={3}>

    <Card className={cx(styles.root, shadowStyles.root)}>
      <CardMedia
        classes={mediaStyles}
        image={
          'https://lecomptoirdesenergies.com/26704-large_default/pompe-a-chaleur-air-eau-atlantic-11-kw-alfea-excellia-duo-ai-triphase.jpg.pagespeed.ce.3skWU26bTh.jpg'
        }
      />
      <CardActionArea>
        <CardContent className={styles.content}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}
            minHeight={360}
            color={'#00000'}
            textAlign={'center'}
          >
            <h1 class="gg-bolt" ></h1>
            <h2>POMPE A CHALEUR <br/> & <br/> CHAUFFE EAU</h2>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
    </Grid>

    <Grid item xs={8} sm={3} md={3}>
    <Card className={cx(styles.root, shadowStyles.root)}>
      <CardMedia
        classes={mediaStyles}
        image={
          "https://www.greenpellets.be/wp-content/uploads/2020/05/poele-pellet-ou-chaudiere-pellet.jpg"
        }
      />
      <CardActionArea>
        <CardContent className={styles.content}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}
            minHeight={360}
            color={'#fff'}
            textAlign={'center'}
          >
            <h1 class="gg-trees"> </h1>
            <h2>CHAUDIERE <br/> POELE <br/>  INSERT A BOIS <br/>  PELLETS</h2>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
    </Grid>

    <Grid item xs={8} sm={3} md={3}>
    <Card className={cx(styles.root, shadowStyles.root)}>
      <CardMedia
        classes={mediaStyles}
        image={
          'https://www.systemed.fr/images/conseils/huit-chaudieres-a-condensation-l760-h550.jpg'
        }
      />
      <CardActionArea>
        <CardContent className={styles.content}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}
            minHeight={360}
            color={'#000000'}
            textAlign={'center'}
          >
            <LocalFireDepartmentSharpIcon style={{color: "#e25822", fontSize: "400%"}}></LocalFireDepartmentSharpIcon>
            <h2>CHAUDIERE <br/> & <br/> CHAUFFE-EAU GAZ</h2>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
    </Grid>

    </Grid>
  

  );
});

export default NewsCard2Demo