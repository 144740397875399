import axios from 'axios';
import { useEffect, useState } from 'react';
import './custom.css';

function Autocomplete() {
  const[users,setUsers] = useState([]);
  const[text,setText] = useState('');
  const[suggestions,setSuggestions] = useState([]);
  useEffect (() => {
    const loadUsers = async()=> {
      const response = await axios.get('https://api-adresse.data.gouv.fr/search/?q=' + encodeURI(`${text}`) + '&limit=10', {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json; charset=utf-8',
        }
    })
      setUsers(response.data.features)
      
      
    }
    loadUsers();
  }, [text])

const onSuggestHandler = (text) =>{
    setText(text.label);
    setSuggestions([]);
    localStorage.setItem('addresse', `${text.postcode}`)
    console.log(localStorage.getItem("addresse"))
  
}

const onChangeHandler = (text) => {
  console.log(text.length)
  let matches = []
  console.log(`${text}`)
  if (text.length > 0) {
    matches = users.filter(user => {
      const regex = new RegExp(`${text}`);
      return user.properties.label.match(regex)
    })

  }
  console.log('matches',matches)
  setSuggestions(matches)
  setText(text)
}
return (
  <div className="container1">
    <div class="questionaddresse">Code Postal ou Ville:</div>
      
  <input type="textauto" className='col-md-12 input' style={{marginTop:10}}
    onChange={e => onChangeHandler(e.target.value)}
    value={text}
    onBlur={()=> {
        setTimeout(()=> {
            setSuggestions([])
        },1000);
    }}></input>

  {suggestions && suggestions.map((suggestions,i)=>
  <div key={i} className=' suggestions col-md-12 justify-content-md-center'
  onClick={() => onSuggestHandler(suggestions.properties)}> {suggestions.properties.label}</div>
  )}
</div>
)
}
export default Autocomplete;