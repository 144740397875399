const dimensions = {
    1: '60',
    2: '65',
    3: '70',
    4: '75',
    5: '80',
    6:'85',
    7: '90',
    8: '95',
    9: '100',
    10: '105',
    11: '110',
    12:'115',
    13: '120',
    14: '125',
    15: '130',
    16: '135',
    17:'140',
    18: '145',
    19:'150',

  }
  export default Object.keys(dimensions).map(value => ({
    value: value,
    label: dimensions[value]
  }))