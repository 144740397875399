import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from '@mui/material/Typography';
import  {Link} from "react-router-dom";
import Box from '@mui/material/Box';


function SimpleMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <Box ml={1.5} >
      <Button
        aria-owns={anchorEl ? "simple-menu" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        onMouseOver={handleClick}
        size="small"
      >
        <Typography
             variant="h7"
    
             sx={{
              
               color: '#1976D2',
              
             }}
           >
        {props.title}
        </Typography>
      </Button>
          
      <Menu
        id="simple-menu"
       
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{ onMouseLeave: handleClose }}
        
      >
        <Typography
             variant="h7"
       
             sx={{
              
               color: '#1976D2',
              
             }}
           >
     
     
          {props.tags.map((subArray, index) => {
          return (
            <Link to={subArray.url} style={{ color: '#1976D2', textDecoration: 'none' }}>
               <MenuItem onClick={handleClose}>{subArray.id}</MenuItem>
               </Link>
             
              )})}
       
        </Typography>
      </Menu>
      
    </Box>
  );
}

export default SimpleMenu;
