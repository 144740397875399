import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import CameraIcon from '@mui/icons-material/PhotoCamera';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ResponsiveAppBar from './header/header.js';
import Foot from "./header/footer.js"
import { useNavigate } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import CustomizedTables from './table_charte.js';
import CustomizedTablesbis from './table_chartebis.js';

const useStyles = makeStyles(theme => ({
  btn: {
     
     borderColor: "#2196F3",
     color: "#2196F3",
     backgroundColor: "white", 
     "&:hover": {
         color: "#2196F3",
         borderColor:"#2196F3"
     },
  },

}));

const theme = createTheme({});

const TestComponent = () => {
    const navigate = useNavigate();

    return (
        <Button variant="contained"style={{
          
          backgroundColor: "#2196F3", borderRadius:"5%"
          
      }} onClick={() => navigate('/test')}>Passer le test</Button>
    );
};

const Aproposbutton = () => {
  const navigate = useNavigate();
  const classes = useStyles();


  return (
    <ThemeProvider theme={theme}>
      <Button variant="outlined" className={classes.btn} onClick={() => navigate('/Apropos')}>A Propos</Button>
      </ThemeProvider>
  );
};




export default function Contact() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="relative">
        <ResponsiveAppBar></ResponsiveAppBar>
      </AppBar>
      <main>
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: '#F4F9F9',
            pt: 8,
            pb: 6,
          }}
        >
          <Box
          sx={{
            bgcolor:'#fff',
            width: '80%',
            padding: "3%",
            marginLeft: "10%",
            borderRadius: 6,
            boxShadow: 1
          }}
        >
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
            >
              Charte de traitement des données
            </Typography>
            <Typography variant="h6" color="text.secondary" paragraph>
            <h4> <b>Charte applicable au 1er Novembre 2022. <br/> </b></h4>
                Cette Charte de Protection des Données détaille la manière dont nous collectons, gérons et traitons vos données personnelles conformément à la réglementation en vigueur relative à la protection des personnes physiques à l’égard des données à caractère personnel et à la libre circulation de ces données, dit RGPD, règlement n°2016 679 en complément de la loi n° 78 17 du 6 janvier 1978 modifiée relative à l’informatique, aux fichiers et aux libertés.
                
                <h3 className='titel_color'> <b>PREAMBULE <br/> </b></h3>
                La présente Charte de Protection des Données concerne les données personnelles collectées sur le site www..fr . <br/>
                
                <h3 className='titel_color'> <b>UTILISATION DES DONNEES COLLECTEES <br/> </b></h3>
                Conformément à la réglementation sur la protection des données, nous utilisons vos données personnelles dans un cadre bien défini. Ces données ne sont pas revendues à des tiers. Vos données personnelles sont principalement utilisées pour les finalités suivantes : <br/> <br/>
                
                - <b>La réponse aux demandes de contact et de devis: </b> les données que nous collectons permettent aux installateurs RGE proche de chez vous de vous joindre pour répondre à vos demandes de contact ou de devis ; <br/>
                - <b>L’élaboration des pré-devis et devis: </b> les données que nous collectons nous permettent de vous proposer le meilleur devis pour la prestation que vous nous demandez ; <br/>
                - <b>La gestion de vos demandes de financement, d’aides et de primes: </b> certaines données que nous collectons sont nécessaires à la constitution et au traitement de vos dossiers de financement ou de demande d’aides financières, crédits d’impôts, primes d’économie d’énergie, etc. ; <br/>
                - <b>Les services personnalisés: </b> certaines données que nous collectons sont nécessaires au fonctionnement des services proposés par le site tels que les simulateurs qui vous permettent d’obtenir des préconisations concernant votre besoin ; <br/>
                - <b>La réalisation de statistiques: </b> les données que nous collectons au travers des différentes finalités listées ci-dessus nous permettent de réaliser des statistiques. <br/>
                
                <h3 className='titel_color'> <b>TYPOLOGIE DES DONNEES COLLECTEES <br/> </b></h3>
                Les données que nous sommes amenés à collecter et à traiter sont les suivantes : <br/>
               
                <CustomizedTables></CustomizedTables>
                <h3 className='titel_color'> <b>QUAND ET COMMENT LES DONNEES SONT-ELLES COLLECTEES ? <br/> </b></h3>
                Nous collectons les informations que vous nous fournissez notamment quand : <br/> <br/>
                - Vous remplissez un formulaire de demande de contact ; <br/>
                - Vous remplissez un formulaire de demande de devis ; <br/>
                - Vous remplissez un formulaire de simulation en ligne en le finalisant par une demande de devis ; <br/>
                
                <h3 className='titel_color'> <b>BASES LEGALES DES TRAITEMENTS DES DONNEES COLLECTEES <br/> </b></h3>
                Le traitement de vos données personnelles repose sur différentes bases légales en fonction de l’usage auquel elles sont destinées. Les bases légales applicables par IZI Solutions Renov à la collecte des données susvisées sont : <br/>
                <CustomizedTablesbis></CustomizedTablesbis>

                
                <h3 className='titel_color'> <b>TRANSMISSION DES DONNEES COLLECTEES <br/> </b></h3>
                Les données que nous collectons peuvent être transmises à : <br/>
                
                Nos sous-traitants : <br/>
                
                - Les artisans sous-traitants qui exécutent l’installation de vos équipements et les dossiers de demande d’aide financière et de primes d’économie d’énergie ; <br/>
                
                <h3 className='titel_color'> <b>VOS DROITS ET INFORMATIONS <br/> </b></h3>
                
                Vous pouvez demander l’accès à vos données personnelles en exerçant votre droit d’accès. Si des données sont erronées ou obsolètes, vous pouvez exercer votre droit de rectification pour les mettre à jour. Vous pouvez exercer votre droit à l’oubli pour effacer vos données conformément à la législation en vigueur ou exercer votre droit à la limitation et à la portabilité. <br/>
                
                Vous pouvez, par ailleurs, exercer votre droit d’opposition au traitement de vos données à caractère personnel notamment afin de vous opposer à ce que vos données à caractère personnel soient utilisées à des fins de prospection commerciale. <br/>
                
                Pour exercer vos droits, vous pouvez : <br/>
                
                <b> Écrire un courrier électronique à l’adresse « info@simucho.fr » </b> <br/>
                
                
                Si toutefois vous rencontrez des difficultés, vous pouvez aussi vous adresser à notre délégué à la protection des données personnelles par mail à l’adresse : «  info@simucho.fr » <br/>
                
                Vous avez également le droit d’exercer un recours auprès la Commission Nationale Informatique et Libertés (CNIL), notamment via son site internet. <br/>
                
                Concernant la prospection commerciale, la personne qui ne souhaite pas faire l’objet de prospection commerciale par téléphone peut également s’inscrire gratuitement sur la liste d’opposition Bloctel sur le site www.bloctel.gouv.fr <br/>
                
                <h3 className='titel_color'> <b>SÉCURITÉ DE VOS DONNEES <br/> </b></h3>
                Nous mettons en œuvre des mesures techniques pour protéger la sécurité de vos données personnelles contre le vol ainsi que l'accès, l'utilisation et la modification non autorisés. Des mesures techniques sont prises pour que les systèmes informatiques supportant le traitement de données à caractère personnel respectent des exigences définies par l’entreprise. <br/>
                
                <h3 className='titel_color'> <b>TRANSFERT DES DONNEES COLLECTEES EN DEHORS DE L’UNION EUROPEENNE <br/> </b></h3>
                <b>Vos données sont stockées au sein de l’Union européenne par AWS en Irlande. </b> <br/>
                
                <h3 className='titel_color'> <b>MODIFICATIONS APPORTÉES À LA PRÉSENTE CHARTE DE PROTECTION DES DONNEES. <br/> </b></h3>
                
                La présente Charte de protection des données sera mise à jour pour tenir compte des évolutions légales, réglementaires et opérationnelles. Nous vous invitons à la consulter régulièrement. <br/>



            </Typography>
            <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="center"
            >
              
              <TestComponent></TestComponent>
              
              <Aproposbutton></Aproposbutton>
            </Stack>
          </Container>
          </Box>
        </Box>
      </main>
      {/* Footer */}
      <Box  component="footer">
        <Foot></Foot>
      </Box>
      {/* End footer */}
    </ThemeProvider>
  );
}