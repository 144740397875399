import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { useNavigate } from "react-router-dom";

export default function MediaCard(props) {
    const navigate = useNavigate();
  return (
    <Card  style={{justifyContent: 'center'}} sx={{ maxWidth: 350, height: 340}}>
      <CardMedia
        sx={{ height: 140 }}
        image={props.url}
        title="green iguana"
      />
      <CardContent>
        <Typography sx={{ height: 100}} gutterBottom variant="h5"  flexWrap={"wrap"} align='center' component="div">
         {props.titre}
        </Typography>
      </CardContent>
      <CardActions style={{justifyContent: 'center'}}>
      <Fab style={{backgroundColor: "#1976D2", borderRadius: 28}}  variant="extended" size="small" onClick={() => navigate(`/${props.categorie}/${props.souscategorie}/${props.id}`)} >
                <DoubleArrowIcon   sx={{backgroundColor: "#fff", color:"#1976D2", borderRadius:"50%", m: 1 }} />
            <Typography align='center' sx={{m:1, color:"#fff"}}>
                
        Lire l'article
        </Typography>
        
      </Fab>
      </CardActions>
    </Card>
  );
}