import * as React from 'react';
import { useRef, useEffect} from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import "./menuitem.css"
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ResponsiveAppBar from '../header/header.js';
import Foot from "../header/footer.js"
import { useNavigate, useParams } from "react-router-dom";
import MainFeaturedPost from './MainFeaturedPost';
import FeaturedPost from './FeaturedPost';
import Item from "./item";
import Divider from '@material-ui/core/Button';
import {Carousel } from "./carousel";
import InputLabel from '@mui/material/InputLabel';
import MuiMenuItem from "@material-ui/core/MenuItem";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { withStyles } from "@material-ui/core/styles";

const scrollToSection = (elementRef) => {
  window.scrollTo({
    top: elementRef.current.offsetTop,
    behavior: "smooth",
  });
};

const MenuItem = withStyles({
  root: {
    flexDirection: "column",
    margin: 1
  }
})(MuiMenuItem);

const theme = createTheme({
});

const TestComponent = () => {
    const navigate = useNavigate();

    return (
        <Button variant="contained"style={{
          
          backgroundColor: "#2196F3", borderRadius:"5%"
          
      }} onClick={() => navigate('/test')}>Passer le test</Button>
    );
};

const mainFeaturedPost = {
    title: 'Guide : Choisir entre poêle à bois ou poêle à granulés',
    description:
      "Découvrez le guide ultime pour choisir entre un poêle à bois et un poêle à granulés, et prenez une décision éclairée pour chauffer votre maison de manière efficace et écologique.",
    image: 'https://st.hzcdn.com/simgs/pictures/salons/bordeaux-centre-ambiance-deco-img~8ea11d17044fd9c0_4-2637-1-fbf9224.jpg',
    imageText: 'main image description',
    url: "https://www.simucho.fr/poele/po%C3%AAle_et_inserts_à_bois/53",
    linkText: 'Continuer à lire…',
  };
  
  const featuredPosts = [
    {
      title: 'Guide : Les avantages et les inconvénients des poêles hybrides',
      description:
        "Découvrez les avantages et les inconvénients des poêles hybrides dans ce guide pratique pour choisir le système de chauffage idéal pour votre maison.",
      image: 'https://www.flamme-and-co.com/web/image/42776/poele-mixte-bois-granules-clou-duo-austroflamm.jpg',
      imageText: 'main image description',
      url: "https://www.simucho.fr/poele/po%C3%AAle_et_inserts_à_bois/62",
      linkText: 'Continuer à lire…',
    },
    {
      title: 'Guide : Comment choisir le meilleur bois de chauffage pour votre chauffage au bois',
      description:
        "Optimisez les performances de votre poêle à bois grâce à ce guide pratique pour choisir le meilleur bois de chauffage en fonction de vos besoins.",
      image: 'https://www.bois-de-chauffage-34.com/oktThemes/ra136-s/images/accueil-diapo/image3.jpg',
      url: "https://www.simucho.fr/poele/po%C3%AAle_et_inserts_à_bois/55",
      imageLabel: 'Continuer à lire…',
    },
  ];


export default function Radiateur() {
  let {id} = useParams();
  const souscategorie = useRef(null);
  const [souscate, setSouscate] = React.useState(id);
  const [newcate, setNewcate]= React.useState(id)


   const handleChange = (event) => {
    
    setSouscate(event.target.value);
    setNewcate(souscate)
   
  };

  function Carou() {
    
    useEffect(() => {
     
    }, [newcate,souscate,id]); 
    // <- add empty brackets here
  
    return <Carousel souscategorie={ newcate } categorie={"poele"} ></Carousel>;
  }
  
  useEffect(() => {
      
      setNewcate(souscate)
      
     
  }, [souscate]); 

  useEffect(() => {
      
    setNewcate(id)
    
   
}, [id]); 

  return (
    <ThemeProvider theme={theme}>
  
   
      <AppBar position="relative">
        <ResponsiveAppBar></ResponsiveAppBar>
      </AppBar>
      <main>
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: '#F4F9F9',
            pt: 8,
            pb: 6,
          }}
        >
          <Box
          sx={{
            marginLeft: "10%",
            width:"80%",
            pt: 5,
            pb: 6,
          }}
        >
        
          <MainFeaturedPost post={mainFeaturedPost} />
          <Grid container  spacing={4} >
          
            {featuredPosts.map((post) => (
             
              <FeaturedPost key={post.title} post={post} />
             
            ))}
         
          </Grid>
          </Box>
          <Stack
              sx={{ pt: 7 }}
              direction="row"
              spacing={2}
              display= "flex" alignItems="center" justifyContent="center"
            >
               <Divider
          flexItem
          
          style={{backgroundColor: "#1976D2", width:'50%',height:"50%" }} > </Divider>
        <Button variant="outlined" size="large"  style={{ borderRadius:"5%" }} onClick={() => scrollToSection(souscategorie)}>  Nos Derniers Articles Par Sous-Categorie </Button>
        <Divider
          flexItem
          style={{backgroundColor: "#1976D2", width:'50%', height:"50%"  }} > </Divider>
        </Stack>
          <Box
          sx={{
            marginLeft: "5%",
            width:"90%",
            pt: 5,
            pb: 6,
          }}
          ref={souscategorie} 
         
        >
        <Box className='box1'  sx={{minWidth: 100, maxWidth:180  }} >
      
     
      <FormControl className='choice' >
        <InputLabel>Sous-Catégorie</InputLabel>
        <Select
          value={newcate}
          onChange={handleChange}
          autowidth
          
        >

        <MenuItem  value={"poêle_et_inserts_à_bois"}>Poêle & Inserts à Bois ou Hybrides</MenuItem>
          <MenuItem  value={'poêle_et_inserts_à_pellets'}>Poêle & Inserts à Pellets </MenuItem>
          <MenuItem value={'poêle_hydro'}>Poêle de Masse, Hydro, Canalisable</MenuItem>

        </Select>
      </FormControl >
      </Box>
          <Carou ></Carou>
          
       </Box>
        </Box>
      </main>
      {/* Footer */}
      <Box   component="footer">
        <Foot></Foot>
      </Box>
      {/* End footer */}
    </ThemeProvider>
  );
}