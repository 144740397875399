import React, { useEffect, useState} from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import Button from '@mui/material/Button';
import MediaCard from './card';

const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  1000: { items: 3 }
};

export const Carousel = (props) => {
    const handleDragStart = (e) => e.preventDefault();
    var [titre,setTitre] = useState([])
    var [url,setUrl] = useState([])
    var [id,setId] = useState([])
    useEffect(() =>{
      
      fetch("https://www.simucho.fr/dataarticles")
        .then(res => res.json())
        .then(
          (result) => {
            
            result["Articles"].forEach(element => {
             
              if(element["categorie"][1] == props.souscategorie ){
                
                
                {setTitre(titre => [...titre,element["titre"]])}
                {setUrl(url => [...url,element["url"]])}
                {setId(id => [...id,element["id"]])}
            
              }
            
            });
          },
        )
      },[props.souscategorie])
      

   const items = id.map((subArray, index) =>

    
      <div className='parent'>
        <MediaCard categorie={props.categorie} souscategorie={props.souscategorie} id={id[index]} url={url[index]} titre={titre[index]}></MediaCard>
       
        </div>,
         
        )
  
    return (
      <div className='resultat'>
        
      <AliceCarousel  mouseTracking items={items} keyboardNavigation  
          responsive={responsive}
          autoPlay
          infinite
          autoPlayInterval={1000}
          animationDuration={1000} />
      
      </div>
    );
  }