import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(Type, MaPrimeRenov, EstimationsCEE) {
  return { Type, MaPrimeRenov, EstimationsCEE};
}

const rows = [
  createData('Nom, Prénom, Code Postal, Email', 'Les demandes de contact'),
  createData('Avis d imposition', "La gestion de vos demandes de financement, d'aide financière et de primes d'économies d'énergie."),
  createData('Données liées à votre logement, Données liées à votre foyer,  Données liées à votre consommation énergétique', "Les simulateurs en ligne vous permettant d'obtenir des préconisations concernant votre besoin."),
];

export default function CustomizedTables() {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 550 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell> Données : </StyledTableCell>
            <StyledTableCell align="right">Principalement utilisées pour :</StyledTableCell>
           
            
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.Type}>
              <StyledTableCell component="th" scope="row">
                {row.Type}
              </StyledTableCell>
              <StyledTableCell align="right">{row.MaPrimeRenov}</StyledTableCell>
              <StyledTableCell align="right">{row.EstimationsCEE}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}