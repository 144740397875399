/* eslint-disable jsx-a11y/accessible-emoji */

import { render } from 'react-dom'
import Wizard from './Wizard'
import { Form, Field, useFormState, useForm} from 'react-final-form'
import React, {Component} from 'react'
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useNavigate
} from "react-router-dom";
import Formsa from './Formsa';
import'./Style.css'
import MultiSelect from "./MultiSelect.jsx";
import { Button, MenuItem, Chip, Slider } from "@material-ui/core";
import Select from 'react-select';
import reno from './reno.js';
import source from './source.js';
import combustible from './combustible.js';
import dimensions from './Dimensions.js';
import Autocomplete from "./Autocomplete.js";
import head from "./header/header.js"
import ResponsiveAppBar from './header/header.js';
import Z1 from "./Z1.PNG";
import Z2 from "./Z2.PNG";
import Z3 from "./Z3.PNG";
import Foyer1 from "./Foyer1.jpg";
import Foyer2 from "./Foyer2.jpg";
import Aucun from "./Aucun.png";
import shunt from "./shunt.png";
import VMCGaz from "./VMCGAZ.png";
import ventouse from "./Ventouse.png";
import jsp from "./JSP.png";
import cheminee from "./Alveole.png";
import VMC from "./VMC.png";
import Autre from "./Autre.png";
import Carre from "./Carre.jpg";
import L from "./L.png";
import U from "./U.png";
import IND from "./Independent.png";
import GC from "./GC.png";
import PC from "./PC.png";
import DGC from "./DGC.png";
import DPC from "./DPC.png";
import PCGC from "./PCGC.png";
import ComboBox from './departement_fr.js';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import {Helmet} from "react-helmet";


const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

const onSubmit = async values => {
  await sleep(300)
  window.alert(JSON.stringify(values, 0, 2))
}

const styles = {
  container: base => ({
    ...base,
    flex: 1,
    minWidth : "30%",
    maxWidth: "50%",
    marginLeft: "25%",
    marginRight: "10%",
    
  })
};
const required = value => (value ? undefined : <div className='alerte'><WarningRoundedIcon></WarningRoundedIcon> Répondez à la question </div>  )

const MultiCondition = ({ condition, children }) => {
  const { values = {} } = useFormState({ subscription: { values: true } });
  return condition(values) ? children : null;
};


const ReactSelectAdapter = ({ input, ...rest }) => (
  <Select {...input} {...rest} searchable />
)


const Condition = ({ when, is, children }) => (
<Field name={when} subscription={{ value: true }}>
  {({ input: { value } }) => (value === is ? children : null)}
</Field>
)

const options = {
  "Pompe à chaleur" : "Pompe à chaleur",
"Chaudière basse Température Gaz" : "Chaudière basse Température Gaz",
"Chaudière basse Température Électrique" : "Chaudière basse Température Électrique",
"Chaudière basse Température Mazout" : "Chaudière basse Température Mazout",
"Chaudière Bas Nox Gaz" : "Chaudière Bas Nox Gaz",
"Chaudière standard Gaz" : "Chaudière standard Gaz",
"Chaudière standard Charbon" : "Chaudière standard Charbon",
"Chaudière standard Fioul" : "Chaudière standard Fioul",
"Chaudière standard Propane" : "Chaudière standard Propane",
"Chaudière à condensation Gaz" : "Chaudière à condensation Gaz",
"Chaudière à condensation Mazout" : "Chaudière à condensation Mazout",
"Chaudière à condensation Fioul" : "Chaudière à condensation Fioul",
"Chaudière à condensation Propane" : "Chaudière à condensation Propane",
"Chaudière à bois (Bûches)" : "Chaudière à bois (Bûches)",
"Chaudière granulé de bois (pellets)" : "Chaudière granulé de bois (pellets)",
"Poêle à bois (bûches)" : "Poêle à bois (bûches)",
"Poêle à granulé de bois (pellets)" : "Poêle à granulé de bois (pellets)",

}
class Home extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
       
  };
  
      this.handleChange = this.handleChange.bind(this);
      
    }

    handleChange = (e, values) => {
     
      let val= []
      let value = e.target.value 
      let question = e.target.name
      val = val.concat(question,value) 
      localStorage.setItem(question.toString(), value.toString());
      this.setState({values: e.target.value});
    
   }

 

  render() {
    
    return (

      
       
    <Wizard 
      
      onSubmit={onSubmit}
      onChange={this.handleChange}
      
    >

      <Wizard.Page class = "formwizard">
      <Helmet>
        <title>Simulation Chauffage et Eau Chaude - Simucho </title>
        <meta name="description" content="Quelle solution chauffage et/ou eau chaude pour votre habitation ? Comparez toutes les solutions existantes pour trouver celle qui conviendra le mieux à vos besoins en utilisant notre simulateur sans création de compte !" />
        <link rel="canonical" href="https://www.simucho.fr/test" />
      </Helmet>
      
      <div class = "questionaddresse">
        Code Postal
        </div>
        <div class = "adresse">
        <ComboBox></ComboBox>
        
        </div>
        <div class = "question">
                Quel type de solution cherchez-vous ? 
                <div>
                <label>
             
                <Field 
                       name="question1" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Chauffage">
            {({ input, meta }) => (
            
                <label>
                  Chauffage
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  
                </label>
               
              )
            }
          </Field>
                </label>
                <label>
                <Field 
                       name="question1" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Eau Chaude">
            {({input, meta }) => (
                <label>
                    Eau Chaude
                    <input {...input} type="radio" />
                  <span class="checkmark"></span>
                </label>
              )
            }
          </Field>
                </label>
                <label>
                <Field 
                       name="question1" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Eau Chaude Chauffage">
            {({input, meta }) => (
                <label>
                    Eau Chaude & Chauffage
                    <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  {meta.error && (meta.modified || meta.touched) && (
                    <div className='alerte'>
                    {meta.error}
                    </div>
                  )}
                </label>
              )
            }
          </Field>
                    </label>
                </div>
                </div>

                <div class = "question">
                 Quelle raison ? 
                <div>
                <label>
                <Field 
                       name="question2" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Panne">
            {({ input, meta }) => (
            
                <label>
                  Panne
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  
                </label>
               
              )
            }
          </Field>
                </label>
                <label>
                <Field 
                       name="question2" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="economie">
            {({ input, meta }) => (
            
                <label>
                  <AddCircleOutlineRoundedIcon style={{color: "#1976D2", fontSize:"130%", alignItems: 'center'}}></AddCircleOutlineRoundedIcon> Economique
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  
                </label>
               
              )
            }
          </Field>
                        
                    </label>
                    <label>
                    <Field 
                       name="question2" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="ecologique">
            {({ input, meta }) => (
            
                <label>
                  <AddCircleOutlineRoundedIcon  style={{color: "#1976D2", paddingTop:"5px", fontSize:"130%", alignItems: 'center'}}></AddCircleOutlineRoundedIcon> Ecologique
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  {meta.error && (meta.modified || meta.touched) && (
                    <div className='alerte'>
                    {meta.error}
                    </div>
                  )}
                </label>
               
              )
            }
          </Field>
                      
                    </label>
                </div>
                </div>


                <MultiCondition condition={({question1}) => (question1 === "Chauffage" || question1 === "Eau Chaude Chauffage")}>
                <div class = "question">
                    Combien de m2 souhaitez-vous chauffer ? 
                    <div class = "question">
                    <label>
                  
                        <Field
                        name="question12"
                        component="input"
                        type="range"
                        min="0" max="300" 
                        />{' '}
                        
                    </label >  
                    <Field name="question12" value="0" validate={required} component="input" type="text" class="textrange">
                    {({ input, meta }) => (
            <label>
              <input {...input} type="text" class="textrange" />
              {meta.error && (meta.modified || meta.touched) && (
                <div className='alerte'>
                {meta.error}
                </div>
              )}
            </label>
          )}
      </Field>
                </div>
                </div>  

                  </MultiCondition>

                  <MultiCondition condition={({question1}) => (question1 === "Chauffage" || question1 === "Eau Chaude Chauffage")}>
                  <div class = "question">
                    Combien d'etages souhaitez-vous chauffer ? (0 si plein pied)
                    <div>
                    <label>
                    <Field
                        name="question13"
                        component="input"
                        type="range"
                        min="0" max="6" 
                        />{' '}
                        
                    </label >  
                    <Field name="question13" value="0" validate={required} component="input" type="text" class="textrange">
                    {({ input, meta }) => (
            <label>
              <input {...input} type="text" class="textrange" />
              {meta.error && (meta.modified || meta.touched) && (
                <div className='alerte'>
                {meta.error}
                </div>
              )}
            </label>
          )}
      </Field>
                  
                </div>
                </div>  

                  </MultiCondition>
                  <MultiCondition condition={({question1}) => (question1 === "Chauffage" || question1 === "Eau Chaude Chauffage")}>
                  <div class = "question">
                    Hauteur sous plafond en metre? 
                    <div >
                    <label>
                    <Field
                        name="question27"
                        component="input"
                        type="range"
                        min="0" max="6" step="0.25" 
                        />{' '}
                        
                    </label >  
                    <Field name="question27" value="0" validate={required} component="input" type="text" class="textrange">
                    {({ input, meta }) => (
            <label>
              <input {...input} type="text" class="textrange" />
              {meta.error && (meta.modified || meta.touched) && (
                <div className='alerte'>
                {meta.error}
                </div>
              )}
            </label>
          )}
      </Field>
                </div>
                </div>  
                  </MultiCondition>

                  <div class = "question">
                Appoint ou solution principale ?
                <div>
                <label>
                <Field 
                       name="question3" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Appoint">
            {({ input, meta }) => (
            
                <label>
                  Appoint
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  
                </label>
               
              )
            }
          </Field>
                </label>
                <label>
                <Field 
                       name="question3" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Solution Principale">
            {({ input, meta }) => (
            
                <label>
                  Solution Principale
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  {meta.error && (meta.modified || meta.touched) && (
                <div className='alerte'>
                {meta.error}
                </div>
              )}
                </label>
               
              )
            }
          </Field>
                    </label>
                </div>
                </div>

      </Wizard.Page>
      <Wizard.Page>
      <MultiCondition condition={({question1}) => (question1 === "Chauffage")}>
                  <div class = "question">
                  Combien de personne habitent le logement ? 
                  <div>
                  <label>
                  <Field
                        name="question81"
                        component="input"
                        type="range"
                        min="0" max="10" 
                        />{' '}
                        
                    </label >  
                    <Field name="question81" value="0" validate={required} component="input" type="text" class="textrange">
                    {({ input, meta }) => (
            <label>
              <input {...input} type="text" class="textrange" />
              {meta.error && (meta.modified || meta.touched) && (
                <div className='alerte'>
                {meta.error}
                </div>
              )}
            </label>
          )}
      </Field>
                  </div>
                  </div>
                  </MultiCondition>

      <MultiCondition condition={({question1}) => (question1 === "Eau Chaude" || question1 === "Eau Chaude Chauffage")}>
                  <div class = "question">
                  Combien de personne habitent le logement ? 
                  <div>
                  <label>
                  <Field
                        name="question16"
                        component="input"
                        type="range"
                        min="0" max="10" 
                        />{' '}
                        
                    </label >  
                    <Field name="question16" value="0" validate={required} component="input" type="text" class="textrange">
                    {({ input, meta }) => (
            <label>
              <input {...input} type="text" class="textrange" />
              {meta.error && (meta.modified || meta.touched) && (
                <div className='alerte'>
                {meta.error}
                </div>
              )}
            </label>
          )}
      </Field>
                    
                  </div>
                  </div>
                  </MultiCondition>

                  <MultiCondition condition={({question1, question3}) => ((question1 === "Eau Chaude" || question1 === "Eau Chaude Chauffage") && question3 === 'Appoint')}>
                    <div class = "question">
                    Équipement(s) alimenté(s) par l’appoint ?
                   <div>
                    
                   <label>
                   <Field 
                       name="question71" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="douche">
            {({ input, meta }) => (
            
                <label>
                  Douche ou Baignoire
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  {meta.error && (meta.modified || meta.touched) && (
                <div className='alerte'>
                {meta.error}
                </div>
              )}
                </label>
               
              )
            }
          </Field>
        
            </label>
            <label>
            <Field 
                       name="question71" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="lavabo">
            {({ input, meta }) => (
            
                <label>
                  Lavabo ou lave-main
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  {meta.error && (meta.modified || meta.touched) && (
                <div className='alerte'>
                {meta.error}
                </div>
              )}
                </label>
               
              )
            }
          </Field>
            </label>
            <label>
            <Field 
                       name="question71" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="plusieurs">
            {({ input, meta }) => (
            
                <label>
                  Plusieurs équipements
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  {meta.error && (meta.modified || meta.touched) && (
                <div className='alerte'>
                {meta.error}
                </div>
              )}
                </label>
               
              )
            }
          </Field>
             
            </label>
            
               </div>
               </div>
                      </MultiCondition>

                  <MultiCondition condition={({question16, question1, question3, question71}) => (question16 >= "4" && (question1 === "Eau Chaude" || question1 === "Eau Chaude Chauffage") && (question3 == "Solution Principale"  || (question3 == "Appoint" && question71 == "plusieurs")))}>
                  <div class = "question">
                   Combien de douche(s) et bain(s) sont prises par jour dans votre foyer? 
                   <div>
                   <div>
                     Douche
                     <Field
                        name="question21"
                        component="input"
                        type="range"
                        min="0" max="10" 
                        />
                        
                    <Field name="question21" value="0" validate={required} component="input" type="text" class="textrange">
                    {({ input, meta }) => (
                      <label>
              <input {...input} type="text" class="textrange" />
              {meta.error && (meta.modified || meta.touched) && (
                <div className='alerteslider'>
                {meta.error}
                </div>
              )}
      </label>
          )}
      </Field>
                   </div>  
                   <div>
                     Bain
                     <Field
                        name="question22"
                        component="input"
                        type="range"
                        min="0" max="10" 
                        />
                        
                    <Field name="question22" value="0" validate={required} component="input" type="text" class="textrange">
                    {({ input, meta }) => (
            <label>
              <input {...input} type="text" class="textrange" />
              {meta.error && (meta.modified || meta.touched) && (
                <div className='alerteslider'>
                {meta.error}
                </div>
              )}
            </label>
          )}
      </Field>
                   </div>  
               </div>
               </div>
                  </MultiCondition>

                  <MultiCondition condition={({question16, question21}) => ((question16 >= "3" && question21 === "2"))}>
                  <div class = "question">
                Utilisez-vous les douches en meme temps  ?
                 <div>
                 
                <label>
                <Field 
                       name="question25" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Oui">
            {({ input, meta }) => (
            
                <label>
                  Oui
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                </label>
               
              )
            }
          </Field>
             
                    
                </label>
                <label>
                <Field 
                       name="question25" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Non">
            {({ input, meta }) => (
            
                <label>
                  Non
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  {meta.error && (meta.modified || meta.touched) && (
                <div className='alerte'>
                {meta.error}
                </div>
              )}
                </label>
               
              )
            }
          </Field>
             
                    </label>
                    </div>
                    </div>
                  </MultiCondition>

                  <MultiCondition condition={({question16, question21}) => ((question16 >= "3" && question21 > "2"))}>
                  <div class = "question">
                Utilisez-vous les douches en meme temps  ?
                 <div>
                 
                <label>
                <Field 
                       name="question25" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Oui">
            {({ input, meta }) => (
            
                <label>
                  Oui
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                </label>
               
              )
            }
          </Field>
                </label>
                <label>
                <Field 
                       name="question25" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Non">
            {({ input, meta }) => (
            
                <label>
                  Non
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  {meta.error && (meta.modified || meta.touched) && (
                <div className='alerte'>
                {meta.error}
                </div>
              )}
                </label>
               
              )
            }
          </Field>
             
                    </label>
                    </div>
                    </div>
                  </MultiCondition>

                  


        <MultiCondition condition={({question1, question3}) => ((question1 === "Eau Chaude" && question3 === 'Appoint'))}>
                  <div class = "question">
          Votre cuisine a-elle une fenêtre ?
    
          <div>
          <label>
          <Field 
                       name="question10" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Oui">
            {({ input, meta }) => (
            
                <label>
                  Oui
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                </label>
               
              )
            }
          </Field>
          </label>
          <label>
          <Field 
                       name="question10" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Non">
            {({ input, meta }) => (
            
                <label>
                  Non
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  {meta.error && (meta.modified || meta.touched) && (
                <div className='alerte'>
                {meta.error}
                </div>
              )}
                </label>
               
              )
            }
          </Field>
        </label>
        </div>
        </div>
                  </MultiCondition>

                  <MultiCondition condition={({question1, question3}) => ((question1 === "Eau Chaude" && question3 === 'Appoint'))}>
                  <div class = "question">
       Votre cuisine est-elle située à proximité de votre salle de bain ?
    
          <div>
          <label>
          <Field 
                       name="question11" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Oui">
            {({ input, meta }) => (
            
                <label>
                  Oui
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                </label>
               
              )
            }
          </Field>
          </label>
          <label>
          <Field 
                       name="question11" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Non">
            {({ input, meta }) => (
            
                <label>
                  Non
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  {meta.error && (meta.modified || meta.touched) && (
                <div className='alerte'>
                {meta.error}
                </div>
              )}
                </label>
               
              )
            }
          </Field>
        </label>
        </div>
        </div>
                    </MultiCondition>

                    <MultiCondition condition={({question1, question3}) => ((question1 === "Eau Chaude" || question1 === "Eau Chaude Chauffage") && question3 === 'Solution Principale')}>
                    <div class = "question">
                   Nombre d’équipements relié à l’eau sanitaire dans la maison ? 
                   <div>
                    
                   <div>
                     Douche
                     <Field
                        name="question17"
                        component="input"
                        type="range"
                        min="0" max="5" 
                        />
                        
                    <Field name="question17" value="0" validate={required} component="input" type="text" class="textrange">
                    {({ input, meta }) => (
            <label>
              <input {...input} type="text" class="textrange" />
              {meta.error && (meta.modified || meta.touched) && (
                <div className='alerteslider'>
                {meta.error}
                </div>
              )}
            </label>
          )}
      </Field>
                   </div>  
                   <div>
                    Baignoire
                    <Field
                        name="question18"
                        component="input"
                        type="range"
                        min="0" max="5" 
                        />
                        
                    <Field name="question18" value="0" validate={required} component="input" type="text" class="textrange">
                    {({ input, meta }) => (
            <label>
              <input {...input} type="text" class="textrange" />
              {meta.error && (meta.modified || meta.touched) && (
                <div className='alerteslider'>
                {meta.error}
                </div>
              )}
            </label>
          )}
      </Field>
                   </div> 
                   <div>
                     Lavabo
                     <Field
                        name="question19"
                        component="input"
                        type="range"
                        min="0" max="5" 
                        />
                        
                    <Field name="question19" value="0" validate={required} component="input" type="text" class="textrange">
                    {({ input, meta }) => (
            <label>
              <input {...input} type="text" class="textrange" />
              {meta.error && (meta.modified || meta.touched) && (
                <div className='alerteslider'>
                {meta.error}
                </div>
              )}
            </label>
          )}
      </Field>
                   </div> 
                   <div>
                     Bidet
                     <Field
                        name="question20"
                        component="input"
                        type="range"
                        min="0" max="5" 
                        />
                        
                    <Field name="question20" value="0" validate={required} component="input" type="text" class="textrange">
                    {({ input, meta }) => (
            <label>
              <input {...input} type="text" class="textrange" />
              {meta.error && (meta.modified || meta.touched) && (
                <div className='alerteslider'>
                {meta.error}
                </div>
              )}
            </label>
          )}
      </Field>
                   </div> 
               </div>
               </div>
                      </MultiCondition>

                      
        
      <MultiCondition condition={({question1}) => (question1 === "Chauffage" || question1 === "Eau Chaude Chauffage")}>
      <div class = "question">
                Système(s) de chauffage en place ?
                    <div >
                  <label class="buttons">
                    <Field
              name="question48"
              styling="field"
              labelname="Chauffage"
              component={MultiSelect}
              renderValue={selected => (
                <div className="multi-select-chips">
                  {selected.map(value => (
                    <Chip
                      key={value}
                      label={options[value]}
                      className="multi-select-chip"
                    />
                    
                    ))}
                    
                    </div>
                  )}
                >
                  {Object.entries(options).map(([key, value], index) => (
                <MenuItem key={index} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Field>
            </label>
                </div>
                    </div>
                  </MultiCondition>
                
                  <MultiCondition condition={({question1}) => (question1 === "Chauffage" || question1 === "Eau Chaude Chauffage")}>
            <div class = "question">
            Radiateur en place ? <Tooltip  title={ <div> <h3> <b> Basse temperature </b> : Eau envoyée dans les radiateurs entre <b> 40 et 50°C </b> <br/> <br/> <b>Haute Température </b> : Eau envoyée dans les radiateurs entre <b>65 et 90°C </b> </h3> </div>} placement="top" enterTouchDelay={0}>
             <InfoOutlinedIcon variant="outlined" style={{color: "#1976D2"}}></InfoOutlinedIcon>
             </Tooltip>
              <div>
              <label>
              <Field 
                       name="question49" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Radiateur HT">
            {({ input, meta }) => (
            
                <label>
                  Radiateur Haute Temperature
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                </label>
               
              )
            }
          </Field>
             
            </label>
            <label>
            <Field 
                       name="question49" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Radiateur BT ou PC">
            {({ input, meta }) => (
            
                <label>
                  Radiateur Basse Temperature / Plancher Chauffant
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                </label>
               
              )
            }
          </Field>
            </label>
            <label>
            <Field 
                       name="question49" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Radiateur Electrique">
            {({ input, meta }) => (
            
                <label>
                  Radiateur Electrique
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                </label>
               
              )
            }
          </Field>
             
              </label>
              <label>
              <Field 
                       name="question49" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Splits">
            {({ input, meta }) => (
            
                <label>
                  Splits
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  {meta.error && (meta.modified || meta.touched) && (
                <div className='alerte'>
                {meta.error}
                </div>
              )}
                </label>
               
              )
            }
          </Field>
              </label>
              </div>
              </div>    
              </MultiCondition>

                  
                  <MultiCondition condition={({question48, question2}) => ((question48 == "Chaudière standard Gaz" ||  question48 == "Chaudière standard Charbon" || question48 == "Chaudière standard Fioul" || question48 == "Chaudière standard Propane" || question48 == "Chaudière à condensation Gaz" || question48 == "Chaudière à condensation Mazout" || question48 == "Chaudière à condensation Fioul" || question48 == "Chaudière à condensation Propane") && (question2 === "economie" || question2 === "ecologique"))}>
                  <div class = "question">
                  Age de la chaudiere en place  ?
              <div>
              <label>
              <Field 
                       name="question52" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Moins de 7 ans">
            {({ input, meta }) => (
            
                <label>
                 Moins de 7 ans
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                </label>
               
              )
            }
          </Field>
            
            </label>
            <label>
            <Field 
                       name="question52" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Entre 7 et 10 ans">
            {({ input, meta }) => (
            
                <label>
                 Entre 7 et 10 ans
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                </label>
               
              )
            }
          </Field>
             
            </label>
            <label>
            <Field 
                       name="question52" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Plus de 10 ans">
            {({ input, meta }) => (
            
                <label>
                 Plus de 10 ans
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  {meta.error && (meta.modified || meta.touched) && (
                <div className='alerte'>
                {meta.error}
                </div>
              )}
                </label>
               
              )
            }
          </Field>
            
              </label>
              
              </div>
              </div>  

                  </MultiCondition>

                  <MultiCondition condition={({question1}) => (question1 === "Chauffage" || question1 === "Eau Chaude Chauffage")}>
                  <div class = "question">
                    Période de construction du bâtiment ?
                <div>
                <label class = "questionconstruction">
                <Field
                name="question28"
                className = 'Select'
                placeholder = "Période"
                styles={styles}
                component={ReactSelectAdapter}
                options={reno}
                
              /> 
              </label>   
                </div>
                </div>  
                </MultiCondition>

               
            
                

                <MultiCondition condition={({question1}) => (question1 === "Chauffage" || question1 === "Eau Chaude Chauffage")}>
                  <div class = "question">
                Avez-vous fait des rénovations dans votre maison ?
                    <div>
                    <label class="check">
                    <Field 
                       name="question30" 
                       component="input"
                        validate={required} 
                        type="checkbox"
                        value="Murs">
            {({ input, meta }) => (
            
                <label>
                 Murs
                  <input {...input} type="checkbox" />
                  <span class="checkmar"></span>
                </label>
               
              )
            }
          </Field>
                  
                    </label>  
                    <label class="check">
              
                    <Field 
                       name="question30" 
                       component="input"
                        validate={required} 
                        type="checkbox"
                        value="Fenêtre">
            {({ input, meta }) => (
            
                <label>
                Fenêtre
                  <input {...input} type="checkbox" />
                  <span class="checkmar"></span>
                </label>
               
              )
            }
          </Field>
                    
                    </label>  
                    <label class="check">
                    <Field 
                       name="question30" 
                       component="input"
                        validate={required} 
                        type="checkbox"
                        value="Toiture">
            {({ input, meta }) => (
            
                <label>
               Isolation des combles
                  <input {...input} type="checkbox" />
                  <span class="checkmar"></span>
                </label>
               
              )
            }
          </Field>
                    </label> 
                    <label class="check">
                      
                    <Field 
                       name="question30" 
                       component="input"
                        validate={required} 
                        type="checkbox"
                        value="Plancher Bas">
            {({ input, meta }) => (
            
                <label>
               Plancher Bas
                  <input {...input} type="checkbox" />
                  <span class="checkmar"></span>
                </label>
               
              )
            }
          </Field>
                    </label>  
                    <label class="check">
                    <Field 
                       name="question30" 
                       component="input"
                        validate={required} 
                        type="checkbox"
                        value="Non">
            {({ input, meta }) => (
            
                <label>
               Non
                  <input {...input} type="checkbox" />
                  <span class="checkmar"></span>
                  {meta.error && (meta.modified || meta.touched) && (
                <div className='alerte'>
                {meta.error}
                </div>
              )}
                </label>
               
              )
            }
          </Field>
                    </label>
                </div>
                </div>  
                  </MultiCondition>

                  <MultiCondition condition={({question30 }) => (question30?.includes('Fenêtre'))}>
                  <div class = "question">
                  Quel(s) changement(s) ont été effectué ?
                  <div>
                  <label class="check">
                  <Field 
                       name="question31" 
                       component="input"
                        validate={required} 
                        type="checkbox"
                        value="cadre">
            {({ input, meta }) => (
            
                <label>
               Cadre
                  <input {...input} type="checkbox" />
                  <span class="checkmar"></span>
                </label>
               
              )
            }
          </Field>
                    
                    </label>  
                    <label class="check">
                    <Field 
                       name="question31" 
                       component="input"
                        validate={required} 
                        type="checkbox"
                        value="vitrage">
            {({ input, meta }) => (
            
                <label>
               Vitrage
                  <input {...input} type="checkbox" />
                  <span class="checkmar"></span>
                </label>
               
              )
            }
          </Field>
                
                    </label>  
                    <label class="check">
                    <Field 
                       name="question31" 
                       component="input"
                        validate={required} 
                        type="checkbox"
                        value="Autre">
            {({ input, meta }) => (
            
                <label>
               Autre
                  <input {...input} type="checkbox" />
                  <span class="checkmar"></span>
                  {meta.error && (meta.modified || meta.touched) && (
                <div className='alerte'>
                {meta.error}
                </div>
              )}
                </label>
               
              )
            }
          </Field>
                    </label>  
                  </div>
                  </div>

                  </MultiCondition>

                  <MultiCondition condition={({question30}) => ((( question30?.includes('Fenêtre'))))}>
                  
                  <div class = "question">
                    Combien de fenêtres votre habitation présente-t-elle ? 
                    <div>
                    <label>
                    <Field
                        name="question34"
                        component="input"
                        type="range"
                        min="0" max="20" 
                        />{' '}
                        
                    </label >  
                    <Field name="question34" value="0" validate={required} component="input" type="text" class="textrange">
                    {({ input, meta }) => (
            <label>
              <input {...input} type="text" class="textrange" />
              {meta.error && (meta.modified || meta.touched) && (
                <div className='alerte'>
                {meta.error}
                </div>
              )}
            </label>
          )}
      </Field>
                        
                </div>
                </div>
                  
                  </MultiCondition>

                 

                  <MultiCondition condition={({question31}) => (question31?.includes('cadre'))}>
                  <div class = "question">
                    Nature de la renovation ?
                    <div>
                    <label>
                    <Field 
                       name="question32" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="2">
            {({ input, meta }) => (
            
                <label>
                 Bois 
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                </label>
               
              )
            }
          </Field>
                      
                      </label>  
                      <label>
                      <Field 
                       name="question32" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="1.4">
            {({ input, meta }) => (
            
                <label>
                 PVC
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                </label>
               
              )
            }
          </Field>
                      </label> 
                      <label>
                      <Field 
                       name="question32" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="1.7">
            {({ input, meta }) => (
            
                <label>
                 Aluminium
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                </label>
               
              )
            }
          </Field>
                          
                      </label>  
                      <label>
                      <Field 
                       name="question32" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="1.7">
            {({ input, meta }) => (
            
                <label>
                 Autre / Je ne sais pas
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  {meta.error && (meta.modified || meta.touched) && (
                <div className='alerte'>
                {meta.error}
                </div>
              )}
                </label>
               
              )
            }
          </Field>
                      </label>  
                    </div>
                    </div>
                  </MultiCondition>

                  <MultiCondition condition={({question30}) => (question30?.includes('Fenêtre'))}>
                  <div class = "question">
                    Aire de la fenêtre (en m²), cadre inclus ? 
                    <div>
                    <label>
                    <Field
                        name="question33"
                        component="input"
                        type="range"
                        min="0" max="5" step="0.25"
                        />{' '}
                        
                    </label >  
                    <Field name="question33" value="0" validate={required} component="input" type="text" class="textrange">
                    {({ input, meta }) => (
            <label>
              <input {...input} type="text" class="textrange" />
              {meta.error && (meta.modified || meta.touched) && (
                <div className='alerte'>
                {meta.error}
                </div>
              )}
            </label>
          )}
      </Field>
                        
                </div>
                </div>
                  </MultiCondition>

                  <MultiCondition condition={({question31}) => (question31?.includes('vitrage'))}>
                  <div class = "question">
                    Nature de la renovation ?
                    <div>
                    <label>
                    <Field 
                       name="question35" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="0.5">
            {({ input, meta }) => (
            
                <label>
                 Triple vitrage avec krypton 
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                </label>
               
              )
            }
          </Field>
                     
                      </label>  
                      <label>
                      <Field 
                       name="question35" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="1.3">
            {({ input, meta }) => (
            
                <label>
                 Double vitrage avec argon
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                </label>
               
              )
            }
          </Field>
                      
                      </label> 
                      <label>
                      <Field 
                       name="question35" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="2.9">
            {({ input, meta }) => (
            
                <label>
                 Double Vitrage
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                </label>
               
              )
            }
          </Field>
                      
                      </label>  
                      <label>
                      <Field 
                       name="question35" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="5.8">
            {({ input, meta }) => (
            
                <label>
                 Simple Vitrage
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                </label>
               
              )
            }
          </Field>
                      
                      </label> 
                      <label>
                      <Field 
                       name="question35" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="3">
            {({ input, meta }) => (
            
                <label>
                 Autre / Je ne sais pas
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  {meta.error && (meta.modified || meta.touched) && (
                <div className='alerte'>
                {meta.error}
                </div>
              )}
                </label>
               
              )
            }
          </Field>
                      </label>  
                    </div>
                    </div>
                  </MultiCondition>

                  <MultiCondition condition={({question30}) => (question30?.includes('Murs'))}>
                  <div class = "question">
                    Période de rénovation des murs ?
                    <div>
                    <label>
                    <Field 
                       name="question37" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="2007- 2011">
            {({ input, meta }) => (
            
                <label>
                 2007- 2011
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  
                </label>
               
              )
            }
          </Field>
                    
                      </label>  
                      <label>
                      <Field 
                       name="question37" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="2012- 2017">
            {({ input, meta }) => (
            
                <label>
                 2012- 2017
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  
                </label>
               
              )
            }
          </Field>
                      </label> 
                      <label>
                      <Field 
                       name="question37" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Après 2018">
            {({ input, meta }) => (
            
                <label>
                 Après 2018
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  
                </label>
               
              )
            }
          </Field>
                      </label>   
                      <label>
                      <Field 
                       name="question37" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="2007- 2011">
            {({ input, meta }) => (
            
                <label>
                 Autre / Je ne sais pas
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  {meta.error && (meta.modified || meta.touched) && (
                <div className='alerte'>
                {meta.error}
                </div>
              )}
                  
                </label>
               
              )
            }
          </Field>
                      
                      </label> 
                </div>
                </div>
                  </MultiCondition>

                  <MultiCondition condition={({question30}) => (question30?.includes('Murs'))}> 
                  <div class = "question">
                    Type de mur?
                    <div>
                    <label>
                    <Field 
                       name="question59" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="ext">
            {({ input, meta }) => (
            
                <label>
                 Mur donnant sur l'exterieur
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  
                </label>
               
              )
            }
          </Field>
                    
                      </label>  
                      <label>
                      <Field 
                       name="question59" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="vide">
            {({ input, meta }) => (
            
                <label>
                 Mur donnant sur un vide ou un espace chauffé
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  {meta.error && (meta.modified || meta.touched) && (
                <div className='alerte'>
                {meta.error}
                </div>
              )}
                </label>
               
              )
            }
          </Field>
                      </label> 
                      
                </div>
                </div>
                  </MultiCondition>


                      <MultiCondition condition={({question30}) => (question30?.includes('Toiture'))}>
                      <div class = "question">
                    Type de toiture ?
                    <div>
                    <label>
                    <Field 
                       name="question42" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="60-">
            {({ input, meta }) => (
            
                <label>
                 Comble aménagée (toiture de pente entre 30° et 60°)
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                </label>
               
              )
            }
          </Field>
                      
                      </label>  
                      <label>
                      <Field 
                       name="question42" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="60+">
            {({ input, meta }) => (
            
                <label>
                 Comble aménagée (toiture de pente superieur a 60°)
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                </label>
               
              )
            }
          </Field>
                    
                      </label> 
                      <label>
                      <Field 
                       name="question42" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="perdue">
            {({ input, meta }) => (
            
                <label>
                 Combles Perdues
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                </label>
               
              )
            }
          </Field>
                    
                      </label>   
                      <label>
                      <Field 
                       name="question42" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="terasse">
            {({ input, meta }) => (
            
                <label>
                 Toit/ Terrasse
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  {meta.error && (meta.modified || meta.touched) && (
                <div className='alerte'>
                {meta.error}
                </div>
              )}
                </label>
               
              )
            }
          </Field>
                      
                      </label> 
                </div>
                </div>
                  </MultiCondition>

                  <MultiCondition condition={({question30}) => (question30?.includes('Toiture'))}> 
                  <div class = "question">
                    Période de rénovation de la toiture ?
                    <div>
                    <label>
                    <Field 
                       name="question43" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="2007- 2011">
            {({ input, meta }) => (
            
                <label>
                 2007- 2011
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                </label>
               
              )
            }
          </Field>
                      
                      </label>  
                      <label>
                      <Field 
                       name="question43" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="2012- 2017">
            {({ input, meta }) => (
            
                <label>
                 2012- 2017
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                </label>
               
              )
            }
          </Field>
                      
                      </label> 
                      <label>
                      <Field 
                       name="question43" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Après 2018">
            {({ input, meta }) => (
            
                <label>
                 Après 2018
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                </label>
               
              )
            }
          </Field>
                    
                      </label>   
                      <label>
                      <Field 
                       name="question43" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="2007- 2011">
            {({ input, meta }) => (
            
                <label>
                 Autre / Je ne sais pas
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  {meta.error && (meta.modified || meta.touched) && (
                <div className='alerte'>
                {meta.error}
                </div>
              )}
                </label>
               
              )
            }
          </Field>
                      
                      </label> 
                </div>
                </div>
                  </MultiCondition>
                  
                  <MultiCondition condition={({question30}) => (question30?.includes('Plancher Bas'))}> 
                  <div class = "question">
                    Type de plancher bas?
                    <div>
                    <label>
                    <Field 
                       name="question44" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="vide">
            {({ input, meta }) => (
            
                <label>
                 Planchers donnant sur vide sanitaire ou sur un espace non chauffé
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                </label>
               
              )
            }
          </Field>
                      
                      </label>  
                      <label>
                      <Field 
                       name="question44" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="ext">
            {({ input, meta }) => (
            
                <label>
                  Plancher bas donnant sur l’extérieur ou sur un parking
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  {meta.error && (meta.modified || meta.touched) && (
                <div className='alerte'>
                {meta.error}
                </div>
              )}
                </label>
               
              )
            }
          </Field>
                      
                      </label> 
                      
                </div>
                </div>
                  </MultiCondition>

                  <MultiCondition condition={({question30}) => (question30?.includes('Plancher Bas'))}> 
                  <div class = "question">
                    Période de rénovation du plancher bas ?
                    <div>
                    <label>
                    <Field 
                       name="question45" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="2007- 2011">
            {({ input, meta }) => (
            
                <label>
                 2007- 2011
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                </label>
               
              )
            }
          </Field>
                      </label>  
                      <label>
                      <Field 
                       name="question45" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="2012- 2017">
            {({ input, meta }) => (
            
                <label>
                 2012- 2017
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                </label>
               
              )
            }
          </Field>
                
                      </label> 
                      <label>
                      <Field 
                       name="question45" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Après 2018">
            {({ input, meta }) => (
            
                <label>
                 Après 2018
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                </label>
               
              )
            }
          </Field>
                      
                      </label>   
                      <label>
                      <Field 
                       name="question45" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="2007- 2011">
            {({ input, meta }) => (
            
                <label>
                 Autre / Je ne sais pas
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  {meta.error && (meta.modified || meta.touched) && (
                <div className='alerte'>
                {meta.error}
                </div>
              )}
                </label>
               
              )
            }
          </Field>
                      
                      </label> 
                </div>
                </div>
                  </MultiCondition>

                  <MultiCondition condition={({question37, question43, question45}) => (question37 === "2012- 2017" || question43 === "2012- 2017" || question45 === "2012- 2017")}>
                  <div class = "question">
                    Avez-vous bénéficié du crédit d’impôt pour vos travaux ?
                    <div>
                    <label>
                    <Field 
                       name="question46" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Oui">
            {({ input, meta }) => (
            
                <label>
                 Oui
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                </label>
               
              )
            }
          </Field>
                    
                    </label>  
                    <label>
                    <Field 
                       name="question46" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Non">
            {({ input, meta }) => (
            
                <label>
                 Non
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  {meta.error && (meta.modified || meta.touched) && (
                <div className='alerte'>
                {meta.error}
                </div>
              )}
                </label>
               
              )
            }
          </Field>
                    </label> 
                      </div>
                      </div>
                  </MultiCondition>
                    
                  <MultiCondition condition={({question28, question48}) => (JSON.stringify(question28, 0, 2)?.includes(0.28) && question48 && question48?.includes("Chaudière standard Fioul"))}>
                  <div class = "question">
                  
                Votre maison a-t-elle été construite il y a moins de 3 ans ? 
                    <div>
                    <label>
                    <Field 
                       name="question29" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Oui">
            {({ input, meta }) => (
            
                <label>
                 Oui
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                </label>
               
              )
            }
          </Field>
                    
                    </label>  
                    <label>
                    <Field 
                       name="question29" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Non">
            {({ input, meta }) => (
            
                <label>
                 Non
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  {meta.error && (meta.modified || meta.touched) && (
                <div className='alerte'>
                {meta.error}
                </div>
              )}
                </label>
               
              )
            }
          </Field>
                
                    </label>  
                
                </div> 
                    </div>
                  </MultiCondition>
                  
                  

                  <MultiCondition condition={({question28, question1}) => (JSON.stringify(question28, 0, 2)?.includes(0.28) && (question1 === "Eau Chaude" || question1 === 'Eau Chaude Chauffage' ))}>
                  <div class = "question">
                   Le logement est-il achevé depuis au moins 2 ans ?
                  <div>
                  <label>
                    
                  <Field 
                       name="question67" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Oui">
            {({ input, meta }) => (
            
                <label>
                 Oui
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                </label>
               
              )
            }
          </Field>
                </label>
                
                <label>
                  
                <Field 
                       name="question67" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Non">
            {({ input, meta }) => (
            
                <label>
                 Non
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  {meta.error && (meta.modified || meta.touched) && (
                <div className='alerte'>
                {meta.error}
                </div>
              )}
                </label>
               
              )
            }
          </Field>
                    </label>
                    
                  </div>
                  </div>
                  </MultiCondition>


      </Wizard.Page>
      <Wizard.Page >

      <div class = "question">
                Type de logement ?
                <div >
                <label>
                <Field 
                       name="question5" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Appartement Principal">
            {({ input, meta }) => (
            
                <label>
                 Appartement Principal
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                </label>
               
              )
            }
          </Field>

                    </label>
                <label>
                <Field 
                       name="question5" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Appartement Secondaire">
            {({ input, meta }) => (
            
                <label>
                 Appartement Secondaire
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                </label>
               
              )
            }
          </Field>
                      
                    </label>
                    <label>
                    <Field 
                       name="question5" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Maison Principale">
            {({ input, meta }) => (
            
                <label>
                 Maison Principale
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                </label>
               
              )
            }
          </Field>
                  
                    </label>
                <label>
                <Field 
                       name="question5" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Maison Secondaire">
            {({ input, meta }) => (
            
                <label>
                 Maison Secondaire
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  {meta.error && (meta.modified || meta.touched) && (
                <div className='alerte'>
                {meta.error}
                </div>
              )}
                </label>
               
              )
            }
          </Field>
                    </label>
               
                </div>
                </div>

                <MultiCondition condition={({question1, question5 }) => ((question1 === "Eau Chaude" && (question5 === "Maison Principale" || question5 === "Maison Secondaire")))}>
              <div class = "question">
          Disposez-vous d’une pièce non-chauffé de <AddCircleOutlineRoundedIcon  style={{fontSize:"90%", color: "#1976D2", paddingTop:"5px", alignItems: 'center'}}></AddCircleOutlineRoundedIcon> de 10m², qui puisse restée porte fermée et relativement loin des chambres (buanderie, cave, garage, etc) ? 
    
          <div>
          <label>
          <Field 
                       name="question76" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Oui">
            {({ input, meta }) => (
            
                <label>
                 Oui
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                </label>
               
              )
            }
          </Field>
          
          </label>
          <label>
          <Field 
                       name="question76" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Non">
            {({ input, meta }) => (
            
                <label>
                 Non
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  {meta.error && (meta.modified || meta.touched) && (
                <div className='alerte'>
                {meta.error}
                </div>
              )}
                </label>
               
              )
            }
          </Field>
        </label>
        </div>
        </div>

        
              </MultiCondition>

               

                <MultiCondition condition={({question5, question1, question30}) => ((question5 === "Maison Principale" || question5 === "Maison Secondaire") && question30?.includes('Murs') && (question1 === "Chauffage" || question1 === "Eau Chaude Chauffage"))}> 
                <div class = "question">
                    Forme de votre maison ?
                    <div>
                     <label> 
                        <input type="radio" className="Carre" name="question38" value="22" id="Carre" onClick={this.handleChange}/>
                        <img src={Carre}></img>
                       <label htmlFor="Carre"></label>
                     </label>

                     <label>
                        <input type="radio" className="L" name="question38" value="04" id="L" onClick={this.handleChange}/>
                        <img src={L}></img>
                        <label htmlFor="L"></label>
                   </label>

                   <label>
                        <input type="radio" className="U" name="question38" value="06" id="U" onClick={this.handleChange}/>
                        <img src={U}></img>
                        <label htmlFor="U"></label>
                   </label>

                </div>
                </div>
                </MultiCondition>

                <MultiCondition condition={({question5, question1, question30}) => ((question5 === "Maison Principale" || question5 === "Maison Secondaire") && !!question30 && question30?.includes('Murs') && (question1 === "Chauffage" || question1 === "Eau Chaude Chauffage"))}> 
                <div class = "question">
                    Nombre de murs accolés ?
                    <div>
                     <label> 
                        <input type="radio" className="IND" name="question39" value="00" id="IND" onClick={this.handleChange}/>
                        <img src={IND}></img>
                       <label htmlFor="IND"></label>
                     </label>

                     <label>
                        <input type="radio" className="GC" name="question39" value="01" id="GC" onClick={this.handleChange}/>
                        <img src={GC}></img>
                        <label htmlFor="GC"></label>
                   </label>

                   <label>
                        <input type="radio" className="DGC" name="question39" value="02" id="DGC" onClick={this.handleChange}/>
                        <img src={DGC}></img>
                        <label htmlFor="DGC"></label>
                   </label>
                   <label>
                        <input type="radio" className="PC" name="question39" value="10" id="PC" onClick={this.handleChange}/>
                        <img src={PC}></img>
                        <label htmlFor="PC"></label>
                   </label>
                   <label>
                        <input type="radio" className="DPC" name="question39" value="20" id="DPC" onClick={this.handleChange}/>
                        <img src={DPC}></img>
                        <label htmlFor="DPC"></label>
                   </label>
                   <label>
                        <input type="radio" className="PCGC" name="question39" value= "11"  id="PCGC" onClick={this.handleChange}/>
                        <img src={PCGC}></img>
                        <label htmlFor="PCGC"></label>
                   </label>
                </div>
                </div>
                </MultiCondition>

                <MultiCondition condition={({question5, question1, question30}) => ((question5 === "Maison Principale" || question5 === "Maison Secondaire") && (question1 === "Chauffage" || question1 === "Eau Chaude Chauffage") && (!!question30 && question30.includes("Murs")))}>
                <div class = "question">
                      Dimensions de l'habitation ?
                    <div>
                      <label>
                    Longueur petit cote (en metre)
                    <Field
                        name="question40"
                        component="input"
                        type="range"
                        min="0" max="30" 
                        />
                        
                    <Field name="question40" value="0" validate={required} component="input" type="text" class="textrange">
                    {({ input, meta }) => (
            <label>
              <input {...input} type="text" class="textrange" />
              {meta.error && (meta.modified || meta.touched) && (
                <div className='alerteslider'>
                {meta.error}
                </div>
              )}
            </label>
          )}
      </Field>
                     </label>
                  </div>  
                  <div>
                  <label>
                   Longueur grand cote (en metre)
                   <Field
                        name="question41"
                        component="input"
                        type="range"
                        min="0" max="30" 
                        />
                        
                    <Field name="question41" value="0" validate={required} component="input" type="text" class="textrange">
                    {({ input, meta }) => (
            <label>
              <input {...input} type="text" class="textrange" />
              {meta.error && (meta.modified || meta.touched) && (
                <div className='alerteslider'>
                {meta.error}
                </div>
              )}
            </label>
          )}
      </Field>
                     </label>
                  </div> 
                  </div>
                </MultiCondition>

                <MultiCondition condition={({question5}) => ((question5 === "Maison Principale" || question5 === "Maison Secondaire"))}>
                <div class = "question">
                 Présence de conduits de fumée déjà existant ?
                <div>
                 
                     <label> 
                        <input display= "inline" type="radio" className="check_box" name="question14" value="Z1" id="check_box" onClick={this.handleChange}/>
                        <img src={Z1}></img>
                       <label htmlFor="check_box"></label>
                     </label>
                     

                     <label>
                        <input display= "inline" type="radio" className="check_box1" name="question14" value="Z2" id="check_box1"  onClick={this.handleChange}/>
                        <img src={Z2}></img>
                        <label htmlFor="check_box1"></label>
                   </label>

                   <label>
                        <input display= "inline" type="radio" className="check_box2" name="question14" value="Z3" id="check_box2" onClick={this.handleChange}/>
                        <img src={Z3}></img>
                        <label htmlFor="check_box2"></label>
                   </label>

                </div>
                </div>
                </MultiCondition>

                <MultiCondition condition={({question5}) => ((question5 === "Maison Principale" || question5 === "Maison Secondaire"))}>
                <div class = "question">
               Foyer ouvert ou foyer fermé présent dans l’habitat ? 
              <div>
              <label> 
                        <input type="radio" className="foyer1" name="question55" value="foyer1" id="foyer1" onClick={this.handleChange}/>
                        <img src={Foyer1}></img>
                       <label htmlFor="foyer1"></label>
                     </label>

                     <label>
                        <input type="radio" className="foyer2" name="question55" value="foyer2" id="foyer2" onClick={this.handleChange}/>
                        <img src={Foyer2}></img>
                        <label htmlFor="foyer2"></label>
                   </label>

                   <label>
                        <input type="radio" className="aucun" name="question55" value="aucun" id="aucun" onClick={this.handleChange}/>
                        <img src={Aucun}></img>
                        <label htmlFor="aucun"></label>
                   </label>
                   
                  
              </div >
              </div>
                </MultiCondition>

                <MultiCondition condition={({question3,question1, question5}) => ((question3 === "Appoint" && (question1 === "Chauffage" || question1 === 'Eau Chaude Chauffage' ) && (question5 === "Maison Principale" || question5 === "Maison Secondaire" )))}> 
                {(localStorage.hasOwnProperty("question55") === true && localStorage.getItem('question55') === 'foyer1') && (
                <div class = "question">
                     Quelle est la longueur de la cheminée ? Si vous ne trouvez pas la dimension exacte prendre celle légèrement au dessus
                  <div>
                  <Field
                    name="question67"
                    className = 'Select'
                    component={ReactSelectAdapter}
                    options={dimensions}
                
                  />    
                  </div>
                  </div>
                  )}
                </MultiCondition>

                <MultiCondition condition={({question5}) => ((question5 === "Appartement Secondaire" || question5 === 'Appartement Principal'))}>
                <div class = "question">
                     Présence de conduits de fumée déjà existant ? <Tooltip  title={ <div> <h3> <b> Shunt </b> :  conduit d’évacuation des fumées commun <br/> <br/> <b>conduit Alsace </b> : Present dans les batiments construits avant 1958, souvent dans l'Est de la France </h3> </div>} placement="top" enterTouchDelay={0}>
             <InfoOutlinedIcon variant="outlined" style={{color: "#1976D2"}}></InfoOutlinedIcon>
             </Tooltip>
                     <div>
                          <label> 
                             <input type="radio" className="shunt" name="question15" value="Shunt/Alsace" id="shunt" onClick={this.handleChange}/>
                             <img src={shunt}></img>
                            
                            <label htmlFor="shunt"></label>
                          </label>
     
                        <label>
                             <input type="radio" className="VMCGAZ" name="question15" value="VMC Gaz" id="VMCGAZ" onClick={this.handleChange}/>
                             <img src={VMCGaz}></img>
                             <label htmlFor="VMCGAZ"></label>
                        </label>
                        <label>
                             <input type="radio" className="Ventouse" name="question15" value="Conduit Ventouse" id="Ventouse" onClick={this.handleChange}/>
                             <img src={ventouse}></img>
                             <label htmlFor="Ventouse"></label>
                        </label>
                        <label>
                             <input type="radio" className="Cheminee" name="question15" value="Cheminee" id="Cheminee" onClick={this.handleChange}/>
                             <img src={cheminee}></img>
                             <label htmlFor="Cheminee"></label>
                        </label>
                        <label>
                             <input type="radio" className="JSP" name="question15" value="Conduit Ventouse" id="JSP" onClick={this.handleChange}/>
                             <img src={jsp}></img>
                             <label htmlFor="JSP"></label>
                        </label>
                     </div>
                     </div>
                </MultiCondition>

                <MultiCondition condition={({question5, question1, question3}) => ((question5 === "Maison Secondaire" || question5 === 'Maison Principale') && question1 == "Eau Chaude" && question3 == "Solution Principale")}> 
                
                   <div class = "question">
                   Système de ventilation en place ? 
                   <div>
                   <label> 
                   <input type="radio" className="VMC" name="question54" value="VMC" id="VMC" onClick={this.handleChange}/>
                   <img src={VMC}></img>
                  <label htmlFor="VMC"></label>
                </label>
       
                <label>
                   <input type="radio" className="Autre" name="question54" value="Autre" id="Autre" onClick={this.handleChange}/>
                   <img src={Autre}></img>
                   <label htmlFor="Autre"></label>
              </label>
       
              </div>
              </div>
                
                </MultiCondition>

                <MultiCondition condition={({question5}) => ((question5 === "Appartement Secondaire"))}> 
                    <div class = "question">
                    A quelles périodes de l année êtes-vous présent dans votre habitation secondaire ? 
                    <div>
                    <label>
                    <Field 
                       name="question4" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Hiver">
            {({ input, meta }) => (
            
                <label>
                 Hiver
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  
                </label>
               
              )
            }
          </Field>
                    </label>  
                    
                    <label>
                    <Field 
                       name="question4" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Automne">
            {({ input, meta }) => (
            
                <label>
                 Automne
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  
                </label>
               
              )
            }
          </Field>
                    </label>  

                    <label>
                    <Field 
                       name="question4" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Ete">
            {({ input, meta }) => (
            
                <label>
                 Ete
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  
                </label>
               
              )
            }
          </Field>
                    </label>  

                    <label>
                    <Field 
                       name="question4" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Printemps">
            {({ input, meta }) => (
            
                <label>
                 Printemps
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  {meta.error && (meta.modified || meta.touched) && (
                <div className='alerte'>
                {meta.error}
                </div>
              )}
                </label>
               
              )
            }
          </Field>
                    </label>
                    </div>
                </div>
                </MultiCondition>

                <MultiCondition condition={({question5}) => ((question5 === "Maison Secondaire"))}> 
                <div class = "question">
                    A quelles périodes de l année êtes-vous présent dans votre habitation secondaire ? 
                    <div>
                    <label>
                    <Field 
                       name="question6" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Hiver">
            {({ input, meta }) => (
            
                <label>
                 Hiver
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  
                </label>
               
              )
            }
          </Field>
                    </label>  
                    
                    <label>
                    <Field 
                       name="question6" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Automne">
            {({ input, meta }) => (
            
                <label>
                 Automne
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  
                </label>
               
              )
            }
          </Field>
                    </label>  

                    <label>
                    <Field 
                       name="question6" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Ete">
            {({ input, meta }) => (
            
                <label>
                 Ete
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  
                </label>
               
              )
            }
          </Field>
                    </label>  

                    <label>
                    <Field 
                       name="question6" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Printemps">
            {({ input, meta }) => (
            
                <label>
                 Printemps
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  {meta.error && (meta.modified || meta.touched) && (
                <div className='alerte'>
                {meta.error}
                </div>
              )}
                </label>
               
              )
            }
          </Field>
                    </label>
                    </div>
                </div>
                </MultiCondition>

                <MultiCondition condition={({question3,question1, question5, question13}) => (question3 === "Solution Principale" && (question1 === "Chauffage" || question1 === 'Eau Chaude Chauffage' ) && (question5 === "Maison Principale" || question5 === "Maison Secondaire" ) && question13 == 0 )}> 
                  <div class = "question">
                  Les pièces à chauffer sont-elles à proximité ( - de 5 mètres), non cloisonnées (un mur entraverait directement la chaleur de circuler) ?
                <div>
                <label>
                  
                <Field 
                       name="question70" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Oui">
            {({ input, meta }) => (
            
                <label>
                 Oui
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  
                </label>
               
              )
            }
          </Field>
                </label>
                <label>
                <Field 
                       name="question70" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Non">
            {({ input, meta }) => (
            
                <label>
                 Non
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  {meta.error && (meta.modified || meta.touched) && (
                <div className='alerte'>
                {meta.error}
                </div>
              )}
                </label>
               
              )
            }
          </Field>
                    </label>
                </div>
                </div>
                  </MultiCondition>

                 

                  <MultiCondition condition={({question3,question1, question5, question49}) => (question3 === "Solution Principale" && (question1 === "Chauffage" || question1 === 'Eau Chaude Chauffage' ) && (question5 === "Maison Principale" || question5 === "Maison Secondaire" ) && question49 == "Radiateur BT ou PC")}>
                  <div class = "question">
                   Quel âge avez-vous ?
                  <div>
                  <label>
                  <Field 
                       name="question61" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="+60">
            {({ input, meta }) => (
            
                <label>
                 + de 60 ans
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  
                </label>
               
              )
            }
          </Field>
                    
                </label>
                
                <label>
                <Field 
                       name="question61" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="-60">
            {({ input, meta }) => (
            
                <label>
                 - de 60 ans
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  
                </label>
               
              )
            }
          </Field>
                  
                    </label>

                    <label>
                  
                    <Field 
                       name="question61" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="nsp">
            {({ input, meta }) => (
            
                <label>
                 Ne souhaite pas repondre
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  {meta.error && (meta.modified || meta.touched) && (
                <div className='alerte'>
                {meta.error}
                </div>
              )}
                </label>
               
              )
            }
          </Field>
                    </label>
                  
                  </div>
                  </div>
                  </MultiCondition>
               
                <MultiCondition condition={({question3, question1}) => (question3 === "Solution Principale" && (question1 === "Eau Chaude" || question1 === 'Eau Chaude Chauffage'))}>
                <div class = "question">
                   Panneaux photovoltaïques ou thermiques présents dans l’habitat ?
                  <div>
                  <label>
                  <Field 
                       name="question56" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Panneaux thermiques">
            {({ input, meta }) => (
            
                <label>
                 Panneaux thermiques
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  
                </label>
               
              )
            }
          </Field>
                       
                </label>
                <label>
                <Field 
                       name="question56" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Panneaux photovoltaïques">
            {({ input, meta }) => (
            
                <label>
                 Panneaux photovoltaïques
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  
                </label>
               
              )
            }
          </Field>
                       
                    </label>
                    <label>
                    <Field 
                       name="question56" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Aucun des deux">
            {({ input, meta }) => (
            
                <label>
                 Aucun des deux
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  {meta.error && (meta.modified || meta.touched) && (
                <div className='alerte'>
                {meta.error}
                </div>
              )}
                </label>
               
              )
            }
          </Field>
                    </label>
                  </div>
                  </div>
                
                </MultiCondition>

                <MultiCondition condition={({question56}) => (question56==="Panneaux photovoltaïques")}>
                <div class = "question">
                   Souhaitez vous que votre solution soit alimentée totalement par l’électricité du réseau ?
                  <div>
                  <label>
                  <Field 
                       name="question83" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Oui">
            {({ input, meta }) => (
            
                <label>
                 Oui
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  
                </label>
               
              )
            }
          </Field>
                </label>
                <label>
                <Field 
                       name="question83" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Non">
            {({ input, meta }) => (
            
                <label>
                 Non
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  {meta.error && (meta.modified || meta.touched) && (
                <div className='alerte'>
                {meta.error}
                </div>
              )}
                </label>
               
              )
            }
          </Field>
                    </label>
                    
                  </div>
                  </div>
                
                </MultiCondition>


      </Wizard.Page>

      <Wizard.Page>

      <div class = "question">
                    Êtes-vous propriétaire de votre logement ?
                  <div>
                  <label>
                  <Field 
                       name="question68" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Oui">
            {({ input, meta }) => (
            
                <label>
                 Oui
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  
                </label>
               
              )
            }
          </Field>
                    
                </label>
                
                <label>
                  
                <Field 
                       name="question68" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Non">
            {({ input, meta }) => (
            
                <label>
                 Non
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  {meta.error && (meta.modified || meta.touched) && (
                <div className='alerte'>
                {meta.error}
                </div>
              )}
                </label>
               
              )
            }
          </Field>
                    </label>
                    
                  </div>
                  </div>

                  <div class = "question">
                  Quel est votre revenu fiscal de référence ?
                  <div>
                  
                  <MultiCondition condition={({question16, question81}) => (question16 == "1" || question81 == "1")}>
                  
                {(localStorage.hasOwnProperty("addresse") == true) && ((+localStorage.getItem("addresse").substring(0, 2) != 75) && (+localStorage.getItem("addresse").substring(0, 2) != 92) && (+localStorage.getItem("addresse").substring(0, 2) != 93) && (+localStorage.getItem("addresse").substring(0, 2) != 94) && (+localStorage.getItem("addresse").substring(0, 2) != 77) && (+localStorage.getItem("addresse").substring(0, 2) != 78) && (+localStorage.getItem("addresse").substring(0, 2) != 91) && (+localStorage.getItem("addresse").substring(0, 2) != 95)) && (
                <div>
                  
                  <label>
                        <Field
                        name="question69"
                        component="input"
                        type="radio"
                        value="--"
                        />{' '}
                        Jusqu'à 15 262 €
                        <span class="checkmark"></span>
                </label>
                <label>
                        <Field
                        name="question69"
                        component="input"
                        type="radio"
                        value="-"
                        />{' '}
                        Jusqu'à 19 565 €
                        <span class="checkmark"></span>
                    </label>
                    <label>
                        <Field
                        name="question69"
                        component="input"
                        type="radio"
                        value="+"
                        />{' '}
                        Jusqu'à 29 148 €
                        <span class="checkmark"></span>
                    </label>
                    <label>
                        <Field
                        name="question69"
                        component="input"
                        type="radio"
                        value="++"
                        />{' '}
                        Au dela de 29 148 €
                        <span class="checkmark"></span>
                    </label>
                  </div>
                )}
                {(localStorage.hasOwnProperty("addresse") == true) && ((+localStorage.getItem("addresse").substring(0, 2) == 75) || (+localStorage.getItem("addresse").substring(0, 2) ==  92) || (+localStorage.getItem("addresse").substring(0, 2) == 93) || (+localStorage.getItem("addresse").substring(0, 2) ==  94) || (+localStorage.getItem("addresse").substring(0, 2) == 77) || (+localStorage.getItem("addresse").substring(0, 2) == 78) || (+localStorage.getItem("addresse").substring(0, 2) ==  91) || (+localStorage.getItem("addresse").substring(0, 2) == 95)) && (
                <div>
                  
                  <label>
                        <Field
                        name="question69"
                        component="input"
                        type="radio"
                        value="--"
                        />{' '}
                        Jusqu'à 21 123 €
                        <span class="checkmark"></span>
                </label>
                <label>
                        <Field
                        name="question69"
                        component="input"
                        type="radio"
                        value="-"
                        />{' '}
                        Jusqu'à 25 714 €
                        <span class="checkmark"></span>
                    </label>
                    <label>
                        <Field
                        name="question69"
                        component="input"
                        type="radio"
                        value="+"
                        />{' '}
                        Jusqu'à 38 184 €
                        <span class="checkmark"></span>
                    </label>
                    <label>
                        <Field
                        name="question69"
                        component="input"
                        type="radio"
                        value="++"
                        />{' '}
                        Au dela de 38 184 €
                        <span class="checkmark"></span>
                    </label>
                  </div>
                )}
                </MultiCondition>
                <MultiCondition condition={({question16, question81}) => (question16 == "2" || question81 == "2")}>
                {(localStorage.hasOwnProperty("addresse") == true) && ((+localStorage.getItem("addresse").substring(0, 2) != 75) && (+localStorage.getItem("addresse").substring(0, 2) != 92) && (+localStorage.getItem("addresse").substring(0, 2) != 93) && (+localStorage.getItem("addresse").substring(0, 2) != 94) && (+localStorage.getItem("addresse").substring(0, 2) != 77) && (+localStorage.getItem("addresse").substring(0, 2) != 78) && (+localStorage.getItem("addresse").substring(0, 2) != 91) && (+localStorage.getItem("addresse").substring(0, 2) != 95)) && (
                <div>
                  
                  <label>
                        <Field
                        name="question69"
                        component="input"
                        type="radio"
                        value="--"
                        />{' '}
                        Jusqu'à 22 320 €
                        <span class="checkmark"></span>
                </label>
                <label>
                        <Field
                        name="question69"
                        component="input"
                        type="radio"
                        value="-"
                        />{' '}
                        Jusqu'à 28 614 €
                        <span class="checkmark"></span>
                    </label>
                    <label>
                        <Field
                        name="question69"
                        component="input"
                        type="radio"
                        value="+"
                        />{' '}
                        Jusqu'à 42 848 €
                        <span class="checkmark"></span>
                    </label>
                    <label>
                        <Field
                        name="question69"
                        component="input"
                        type="radio"
                        value="++"
                        />{' '}
                        Au dela de 42 848 €
                        <span class="checkmark"></span>
                    </label>
                  </div>
                )}
                {(localStorage.hasOwnProperty("addresse") == true) && ((+localStorage.getItem("addresse").substring(0, 2) == 75) || (+localStorage.getItem("addresse").substring(0, 2) ==  92) || (+localStorage.getItem("addresse").substring(0, 2) == 93) || (+localStorage.getItem("addresse").substring(0, 2) ==  94) || (+localStorage.getItem("addresse").substring(0, 2) == 77) || (+localStorage.getItem("addresse").substring(0, 2) == 78) || (+localStorage.getItem("addresse").substring(0, 2) ==  91) || (+localStorage.getItem("addresse").substring(0, 2) == 95)) && (
                <div>
                  
                  <label>
                        <Field
                        name="question69"
                        component="input"
                        type="radio"
                        value="--"
                        />{' '}
                        Jusqu'à 31 003 €
                        <span class="checkmark"></span>
                </label>
                <label>
                        <Field
                        name="question69"
                        component="input"
                        type="radio"
                        value="-"
                        />{' '}
                        Jusqu'à 37 739 €
                        <span class="checkmark"></span>
                    </label>
                    <label>
                        <Field
                        name="question69"
                        component="input"
                        type="radio"
                        value="+"
                        />{' '}
                        Jusqu'à 56 130 €
                        <span class="checkmark"></span>
                    </label>
                    <label>
                        <Field
                        name="question69"
                        component="input"
                        type="radio"
                        value="++"
                        />{' '}
                        Au dela de 56 130 €
                        <span class="checkmark"></span>
                    </label>
                  </div>
                )}
                </MultiCondition>
                <MultiCondition condition={({question16, question81}) => (question16 == "3" || question81 == "3")}>
                {(localStorage.hasOwnProperty("addresse") == true) && ((+localStorage.getItem("addresse").substring(0, 2) != 75) && (+localStorage.getItem("addresse").substring(0, 2) != 92) && (+localStorage.getItem("addresse").substring(0, 2) != 93) && (+localStorage.getItem("addresse").substring(0, 2) != 94) && (+localStorage.getItem("addresse").substring(0, 2) != 77) && (+localStorage.getItem("addresse").substring(0, 2) != 78) && (+localStorage.getItem("addresse").substring(0, 2) != 91) && (+localStorage.getItem("addresse").substring(0, 2) != 95)) && (
                <div>
                  
                  <label>
                        <Field
                        name="question69"
                        component="input"
                        type="radio"
                        value="--"
                        />{' '}
                        Jusqu'à 26 844 €
                        <span class="checkmark"></span>
                </label>
                <label>
                        <Field
                        name="question69"
                        component="input"
                        type="radio"
                        value="-"
                        />{' '}
                        Jusqu'à 34 411 €
                        <span class="checkmark"></span>
                    </label>
                    <label>
                        <Field
                        name="question69"
                        component="input"
                        type="radio"
                        value="+"
                        />{' '}
                        Jusqu'à 51 592 €
                        <span class="checkmark"></span>
                    </label>
                    <label>
                        <Field
                        name="question69"
                        component="input"
                        type="radio"
                        value="++"
                        />{' '}
                        Au dela de 51 592 €
                        <span class="checkmark"></span>
                    </label>
                  </div>
                )}
                {(localStorage.hasOwnProperty("addresse") == true) && ((+localStorage.getItem("addresse").substring(0, 2) == 75) || (+localStorage.getItem("addresse").substring(0, 2) ==  92) || (+localStorage.getItem("addresse").substring(0, 2) == 93) || (+localStorage.getItem("addresse").substring(0, 2) ==  94) || (+localStorage.getItem("addresse").substring(0, 2) == 77) || (+localStorage.getItem("addresse").substring(0, 2) == 78) || (+localStorage.getItem("addresse").substring(0, 2) ==  91) || (+localStorage.getItem("addresse").substring(0, 2) == 95)) && (
                <div>
                  
                  <label>
                        <Field
                        name="question69"
                        component="input"
                        type="radio"
                        value="--"
                        />{' '}
                        Jusqu'à 37 232 €
                        <span class="checkmark"></span>
                </label>
                <label>
                        <Field
                        name="question69"
                        component="input"
                        type="radio"
                        value="-"
                        />{' '}
                        Jusqu'à 45 326 €
                        <span class="checkmark"></span>
                    </label>
                    <label>
                        <Field
                        name="question69"
                        component="input"
                        type="radio"
                        value="+"
                        />{' '}
                        Jusqu'à 67 585 €
                        <span class="checkmark"></span>
                    </label>
                    <label>
                        <Field
                        name="question69"
                        component="input"
                        type="radio"
                        value="++"
                        />{' '}
                        Au dela de 67 585 €
                        <span class="checkmark"></span>
                    </label>
                  </div>
                )}
                </MultiCondition>
                <MultiCondition condition={({question16, question81}) => (question16 == "4" || question81 == "4")}>
                {(localStorage.hasOwnProperty("addresse") == true) && ((+localStorage.getItem("addresse").substring(0, 2) != 75) && (+localStorage.getItem("addresse").substring(0, 2) != 92) && (+localStorage.getItem("addresse").substring(0, 2) != 93) && (+localStorage.getItem("addresse").substring(0, 2) != 94) && (+localStorage.getItem("addresse").substring(0, 2) != 77) && (+localStorage.getItem("addresse").substring(0, 2) != 78) && (+localStorage.getItem("addresse").substring(0, 2) != 91) && (+localStorage.getItem("addresse").substring(0, 2) != 95)) && (
                <div>
                  
                  <label>
                        <Field
                        name="question69"
                        component="input"
                        type="radio"
                        value="--"
                        />{' '}
                        Jusqu'à 31 359 €
                        <span class="checkmark"></span>
                </label>
                <label>
                        <Field
                        name="question69"
                        component="input"
                        type="radio"
                        value="-"
                        />{' '}
                        Jusqu'à 40 201 €
                        <span class="checkmark"></span>
                    </label>
                    <label>
                        <Field
                        name="question69"
                        component="input"
                        type="radio"
                        value="+"
                        />{' '}
                        Jusqu'à 60 336 €
                        <span class="checkmark"></span>
                    </label>
                    <label>
                        <Field
                        name="question69"
                        component="input"
                        type="radio"
                        value="++"
                        />{' '}
                        Au dela de 60 336 €
                        <span class="checkmark"></span>
                    </label>
                  </div>
                )}
                {(localStorage.hasOwnProperty("addresse") == true) && ((+localStorage.getItem("addresse").substring(0, 2) == 75) || (+localStorage.getItem("addresse").substring(0, 2) ==  92) || (+localStorage.getItem("addresse").substring(0, 2) == 93) || (+localStorage.getItem("addresse").substring(0, 2) ==  94) || (+localStorage.getItem("addresse").substring(0, 2) == 77) || (+localStorage.getItem("addresse").substring(0, 2) == 78) || (+localStorage.getItem("addresse").substring(0, 2) ==  91) || (+localStorage.getItem("addresse").substring(0, 2) == 95)) && (
                <div>
                  
                  <label>
                        <Field
                        name="question69"
                        component="input"
                        type="radio"
                        value="--"
                        />{' '}
                        Jusqu'à 43 472 €
                        <span class="checkmark"></span>
                </label>
                <label>
                        <Field
                        name="question69"
                        component="input"
                        type="radio"
                        value="-"
                        />{' '}
                        Jusqu'à 52 925 €
                        <span class="checkmark"></span>
                    </label>
                    <label>
                        <Field
                        name="question69"
                        component="input"
                        type="radio"
                        value="+"
                        />{' '}
                        Jusqu'à 79 041 €
                        <span class="checkmark"></span>
                    </label>
                    <label>
                        <Field
                        name="question69"
                        component="input"
                        type="radio"
                        value="++"
                        />{' '}
                        Au dela de 79 041 €
                        <span class="checkmark"></span>
                    </label>
                  </div>
                )}
                </MultiCondition>
                <MultiCondition condition={({question16, question81}) => (question16 == "5" || question81 == "5" )}>
                {(localStorage.hasOwnProperty("addresse") == true) && ((+localStorage.getItem("addresse").substring(0, 2) != 75) && (+localStorage.getItem("addresse").substring(0, 2) != 92) && (+localStorage.getItem("addresse").substring(0, 2) != 93) && (+localStorage.getItem("addresse").substring(0, 2) != 94) && (+localStorage.getItem("addresse").substring(0, 2) != 77) && (+localStorage.getItem("addresse").substring(0, 2) != 78) && (+localStorage.getItem("addresse").substring(0, 2) != 91) && (+localStorage.getItem("addresse").substring(0, 2) != 95)) && (
                <div>
                  
                  <label>
                        <Field
                        name="question69"
                        component="input"
                        type="radio"
                        value="--"
                        />{' '}
                        Jusqu'à 35 894 €
                        <span class="checkmark"></span>
                </label>
                <label>
                        <Field
                        name="question69"
                        component="input"
                        type="radio"
                        value="-"
                        />{' '}
                        Jusqu'à 46 015 €
                        <span class="checkmark"></span>
                    </label>
                    <label>
                        <Field
                        name="question69"
                        component="input"
                        type="radio"
                        value="+"
                        />{' '}
                        Jusqu'à 69 081 €
                        <span class="checkmark"></span>
                    </label>
                    <label>
                        <Field
                        name="question69"
                        component="input"
                        type="radio"
                        value="++"
                        />{' '}
                        Au dela de 69 081 €
                        <span class="checkmark"></span>
                    </label>
                  </div>
                )}
                {(localStorage.hasOwnProperty("addresse") == true) && ((+localStorage.getItem("addresse").substring(0, 2) == 75) || (+localStorage.getItem("addresse").substring(0, 2) ==  92) || (+localStorage.getItem("addresse").substring(0, 2) == 93) || (+localStorage.getItem("addresse").substring(0, 2) ==  94) || (+localStorage.getItem("addresse").substring(0, 2) == 77) || (+localStorage.getItem("addresse").substring(0, 2) == 78) || (+localStorage.getItem("addresse").substring(0, 2) ==  91) || (+localStorage.getItem("addresse").substring(0, 2) == 95)) && (
                <div>
                  
                  <label>
                        <Field
                        name="question69"
                        component="input"
                        type="radio"
                        value="--"
                        />{' '}
                        Jusqu'à 49 736 €
                        <span class="checkmark"></span>
                </label>
                <label>
                        <Field
                        name="question69"
                        component="input"
                        type="radio"
                        value="-"
                        />{' '}
                        Jusqu'à 60 546 €
                        <span class="checkmark"></span>
                    </label>
                    <label>
                        <Field
                        name="question69"
                        component="input"
                        type="radio"
                        value="+"
                        />{' '}
                        Jusqu'à 90 496 €
                        <span class="checkmark"></span>
                    </label>
                    <label>
                        <Field
                        name="question69"
                        component="input"
                        type="radio"
                        value="++"
                        />{' '}
                        Au dela de 90 496 €
                        <span class="checkmark"></span>
                    </label>
                  </div>
                )}
                </MultiCondition>
                <MultiCondition condition={({question16, question81}) => (question16 == "6" || question81 == "6")}>
                {(localStorage.hasOwnProperty("addresse") == true) && ((+localStorage.getItem("addresse").substring(0, 2) != 75) && (+localStorage.getItem("addresse").substring(0, 2) != 92) && (+localStorage.getItem("addresse").substring(0, 2) != 93) && (+localStorage.getItem("addresse").substring(0, 2) != 94) && (+localStorage.getItem("addresse").substring(0, 2) != 77) && (+localStorage.getItem("addresse").substring(0, 2) != 78) && (+localStorage.getItem("addresse").substring(0, 2) != 91) && (+localStorage.getItem("addresse").substring(0, 2) != 95)) && (
                <div>
                  
                  <label>
                        <Field
                        name="question69"
                        component="input"
                        type="radio"
                        value="--"
                        />{' '}
                        Jusqu’à {35894+4526} €
                        <span class="checkmark"></span>
                </label>
                <label>
                        <Field
                        name="question69"
                        component="input"
                        type="radio"
                        value="-"
                        />{' '}
                        Jusqu’à {46015+5797} €
                        <span class="checkmark"></span>
                    </label>
                    <label>
                        <Field
                        name="question69"
                        component="input"
                        type="radio"
                        value="+"
                        />{' '}
                        Jusqu’à {69081+8744} €
                        <span class="checkmark"></span>
                    </label>
                    <label>
                        <Field
                        name="question69"
                        component="input"
                        type="radio"
                        value="++"
                        />{' '}
                        Au dela de {69081+8744} €
                        <span class="checkmark"></span>
                    </label>
                  </div>
                )}
                {(localStorage.hasOwnProperty("addresse") == true) && ((+localStorage.getItem("addresse").substring(0, 2) == 75) || (+localStorage.getItem("addresse").substring(0, 2) ==  92) || (+localStorage.getItem("addresse").substring(0, 2) == 93) || (+localStorage.getItem("addresse").substring(0, 2) ==  94) || (+localStorage.getItem("addresse").substring(0, 2) == 77) || (+localStorage.getItem("addresse").substring(0, 2) == 78) || (+localStorage.getItem("addresse").substring(0, 2) ==  91) || (+localStorage.getItem("addresse").substring(0, 2) == 95)) && (
                <div>
                  
                  <label>
                        <Field
                        name="question69"
                        component="input"
                        type="radio"
                        value="--"
                        />{' '}
                        Jusqu’à {49736+6253}
                        <span class="checkmark"></span>
                </label>
                <label>
                        <Field
                        name="question69"
                        component="input"
                        type="radio"
                        value="-"
                        />{' '}
                        Jusqu'à {60546+7613} €
                        <span class="checkmark"></span>
                    </label>
                    <label>
                        <Field
                        name="question69"
                        component="input"
                        type="radio"
                        value="+"
                        />{' '}
                        Jusqu'à {90496+11455} € 
                        <span class="checkmark"></span>
                    </label>
                    <label>
                        <Field
                        name="question69"
                        component="input"
                        type="radio"
                        value="++"
                        />{' '}
                        Au dela de {90496+11455} €
                        <span class="checkmark"></span>
                    </label>
                  </div>
                )}
                </MultiCondition>
                  </div>
                  </div>

                <div class = "question">
                Votre habitation est-elle raccordée au gaz ?
                <div>
                <label>
                <Field 
                       name="question8" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Oui">
            {({ input, meta }) => (
            
                <label>
                 Oui
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  
                </label>
               
              )
            }
          </Field>
                </label>
                <label>
                <Field 
                       name="question8" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Non">
            {({ input, meta }) => (
            
                <label>
                 Non
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  {meta.error && (meta.modified || meta.touched) && (
                <div className='alerte'>
                {meta.error}
                </div>
              )}
                </label>
               
              )
            }
          </Field>
              
                    </label>
                </div>
                </div>

                <MultiCondition condition={({question3, question1, question49}) => (question3 === "Solution Principale" && (question1 === "Chauffage" || question1 === 'Eau Chaude Chauffage')  && question49 !== "Radiateur Electrique")}>
                <div class = "question">
                Disposez vous d’un espace de stockage couvert et peu humide pour du bois ou des granulés de bois (en sac ou en vrac) ?
                <div>
                <label>
                <Field 
                       name="question77" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Oui mais">
            {({ input, meta }) => (
            
                <label>
                 Oui (espace séparé par un mur du système de chauffage)
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  
                </label>
               
              )
            }
          </Field>
                
                </label>
                <label>
                <Field 
                       name="question77" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Oui">
            {({ input, meta }) => (
            
                <label>
                  Oui (espace dans la même pièce que le système de chauffage) 
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  
                </label>
               
              )
            }
          </Field>
                      
                    </label>
                    <label>
                    <Field 
                       name="question77" 
                       component="input"
                        validate={required} 
                        type="radio"
                        value="Non">
            {({ input, meta }) => (
            
                <label>
                  Non ou Autre
                  <input {...input} type="radio" />
                  <span class="checkmark"></span>
                  {meta.error && (meta.modified || meta.touched) && (
                <div className='alerte'>
                {meta.error}
                </div>
              )}
                </label>
               
              )
            }
          </Field>
                        
                    </label>
                </div>
                </div>
                </MultiCondition>
                
                
                <div class = "question">
                Température ambiante souhaitée dans la maison ?
                <div>
                <label>
                <Field
                        name="question80"
                        component="input"
                        type="range"
                        min="0" max="30"
                        />{' '}
                        
                    </label >  
                    <Field name="question80" value="0" validate={required} component="input" type="text" class="textrange">
                    {({ input, meta }) => (
            <label>
              <input {...input} type="text" class="textrange" />
              {meta.error && (meta.modified || meta.touched) && (
                <div className='alerte'>
                {meta.error}
                </div>
              )}
            </label>
          )}
      </Field>
                    
                </div>
                </div>

               
              
                
                
      </Wizard.Page>

      <Wizard.Page>
            
                  <div class = "question">
                    Parmi les solutions proposées, pour quel(s) type(s) de solutions souhaitez vous recevoir un devis ?
                  <div>
                  <label class="check">
                  <Field 
                       name="questionfinal" 
                       component="input"
                        validate={required} 
                        type="checkbox"
                        value="Chaudiere Bois/granule">
            {({ input, meta }) => (
            
                <label>
               Chaudiere Bois/granule
                  <input {...input} type="checkbox" />
                  <span class="checkmar"></span>
                  
                </label>
               
              )
            }
          </Field>
                        
                </label>
                <label class="check">
                <Field 
                       name="questionfinal" 
                       component="input"
                        validate={required} 
                        type="checkbox"
                        value="cet/cumulus">
            {({ input, meta }) => (
            
                <label>
               Chauffe-eau thermodynamique / Ballon eau chaude
                  <input {...input} type="checkbox" />
                  <span class="checkmar"></span>
                 
                </label>
               
              )
            }
          </Field>
                      
                    </label>
                    <label class="check">
                    <Field 
                       name="questionfinal" 
                       component="input"
                        validate={required} 
                        type="checkbox"
                        value="Inserts/Foyers/Poeles">
            {({ input, meta }) => (
            
                <label>
               Inserts/Foyers/Poeles
                  <input {...input} type="checkbox" />
                  <span class="checkmar"></span>
                  
                </label>
               
              )
            }
          </Field>
                      
                    </label>
                    <label class="check" >
                    <Field 
                       name="questionfinal" 
                       component="input"
                        validate={required} 
                        type="checkbox"
                        value="Chaudiere gaz">
            {({ input, meta }) => (
            
                <label>
               Chaudiere gaz condensation
                  <input {...input} type="checkbox" />
                  <span class="checkmar"></span>
                  
                </label>
               
              )
            }
          </Field>
                        
                    </label>
                    <label class="check">
                    <Field 
                       name="questionfinal" 
                       component="input"
                        validate={required} 
                        type="checkbox"
                        value="Pompe a chaleur">
            {({ input, meta }) => (
            
                <label>
               Pompe a chaleur
                  <input {...input} type="checkbox" />
                  <span class="checkmar"></span>
                  {meta.error && (meta.modified || meta.touched) && (
                <div className='alerte'>
                {meta.error}
                </div>
              )}
                </label>
               
              )
            }
          </Field>
                        
                    </label>
                  </div>
                  </div>

              <div class = "question">
            <label><b>Prenom </b> </label>
            <Field 
                       name="firstName" 
                       component="input"
                        validate={required} 
                        type="text"
                        placeholder="Prenom"
                        >
            {({ input, meta }) => (
            
                <label>
                  <input {...input} type="text" />
                  
                  {meta.error && (meta.modified || meta.touched) && (
                <div className='alerte'>
                {meta.error}
                </div>
              )}
                </label>
               
              )
            }
          </Field>
           
          </div>
          <div class = "question">
            <label><b>Nom </b></label>
            <Field 
                       name="lastName" 
                       component="input"
                        validate={required} 
                        type="text"
                        placeholder="Nom"
                        >
            {({ input, meta }) => (
            
                <label>
                  <input {...input} type="text" />
                  
                  {meta.error && (meta.modified || meta.touched) && (
                <div className='alerte'>
                {meta.error}
                </div>
              )}
                </label>
               
              )
            }
          </Field>
            
          </div>
          <div class = "question">
          <label> <b>Email </b> </label>
          <Field 
                       name="email" 
                       component="input"
                        validate={required} 
                        type="email"
                        placeholder="Email"
                        >
            {({ input, meta }) => (
            
                <label>
                  <input {...input} type="email" />
                  
                  {meta.error && (meta.modified || meta.touched) && (
                <div className='alerte'>
                {meta.error}
                </div>
              )}
                </label>
               
              )
            }
          </Field>
        </div>
                  
          <div class = "question">        
        <label class="check">
        <Field 
                       name="RGPD" 
                       component="input"
                        type="checkbox"
                        validate={required} 
                        value = "true"> 
            {({ input, meta }) => (
            
                <label>
               Autorisation stockage & envoie des données aux installateurs locaux RGE
                  <input {...input} type="checkbox" />
                  <span class="checkmar"></span>
                  {meta.error && (meta.modified || meta.touched) && (
                <div className='alerte'>
                {meta.error}
                </div>
              )}
                </label>
               
              )
            }
          </Field>
            
            </label> 
            </div>  
                  


      </Wizard.Page>
      
    </Wizard>
   
)
  }}

export default Home;
