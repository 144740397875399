import React from 'react';
import {makeStyles} from '@material-ui/core'

import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import EmailIcon from '@mui/icons-material/Email';
import Divider from '@material-ui/core/Button';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const useStyles = makeStyles({

  footerStyles: {
    color: 'white',
    background: '#2196F3',
    textAlign: 'center',
    
  },
  linkContainer: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    marginBottom: "-2%",
  
  },
  links: {
    display: 'flex',
    flexDirection: 'column',
    padding: '2%',
    paddingTop: '1%'
  },
  aStyles: {
    color:'white',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    },
    textAlign: 'center',
    marginBottom: '2%',
    lineHeight: '150%'
  },
  bottom: {
    textAlign: 'center',
    paddingBottom: '0.5%',
    marginTop: "-0.01%"
    
    
  }
})

export default function Foot() {

  const classes = useStyles();

  return (
    <footer className={classes.footerStyles}>
      <div className={classes.linkContainer}>
        <div className={classes.links}>
          <h3>Liens</h3>
          <a href='/APropos' className={classes.aStyles}>A Propos</a>
          <a href='/Contact' className={classes.aStyles}><EmailIcon></EmailIcon>  Email</a>
          <a href='/' className={classes.aStyles}>Accueil</a>
        </div>
        <div className={classes.links}>
          <h3>Reseaux</h3>
          <a href='https://www.linkedin.com/in/benjamin-audidier-8806081a1/' className={classes.aStyles}> <LinkedInIcon></LinkedInIcon></a>
          <a href='https://www.instagram.com/benjfr/?hl=fr' className={classes.aStyles}><InstagramIcon></InstagramIcon></a>
          <a href='https://twitter.com/benjam1audidier?lang=fr' className={classes.aStyles}><TwitterIcon></TwitterIcon></a>
        </div>
        <div className={classes.links}>
          <h3>Articles</h3>
          <a href='/pompe_a_chaleur/pompe_à_chaleur_air_eau' className={classes.aStyles}>Pompe à Chaleur</a>
          <a href='/poele/poêle_et_inserts_à_bois' className={classes.aStyles}> Poele</a>
          <a href='/chauffe_eau/chauffe_eau_thermodynamique' className={classes.aStyles}> Chauffe-Eau </a>
          <a href='/chaudiere/chaudière_à_bois' className={classes.aStyles}> Chaudière</a>
          <a href='/radiateur/convecteur_electrique' className={classes.aStyles}>Radiateur</a>
        </div>
      </div>
      <Divider
          flexItem
          align="center"
          size="small"
          style={{backgroundColor: "#fff",width:'50%', height:"150%"}} >
      </Divider>
      <br/>
      <h6 className={classes.bottom}>SIMUCHO © Company 2022</h6>
    </footer>
  );
}