import React, { Component } from "react";
import M  from "materialize-css";
import "./carousel.css";
import'../Style.css'
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';

function shuffle(array) {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

class Carousel extends Component {
  constructor(props) {
    super(props);
    

  }
  componentDidMount() {
   
    const options = {
      duration: 300,
      onCycleTo: () => {
        console.log("New Slide");
      }
    };
    M.Carousel.init(this.Carousel, options);
    
  }


   items = this.props.name.map((subArray, index) =>

   <a className="carousel-item">
      <div  className="containerbis">
        <img src={(this.props.photo[index])} alt={index} className="container"  />
        <div className="overlay">
        <div className="text">{(this.props.name[index].substring(0,this.props.name[index].length - (this.props.name[index].length/2)))}</div>
      <div className="text4">{(this.props.name[index].substring((this.props.name[index].length/2),))}</div>
          <div className="text1">{(this.props.rendement[index])} </div>
        <div className="text2">{(this.props.emissionCO[index])} </div>
        <div className="text3">{(this.props.emissionNox[index])} </div>
        </div>
        </div>
        
        </a>
        
       
        )

        

  render() {
    return (
   
      <div
        ref={(Carousel) => {
          this.Carousel = Carousel;
        }}
       
        className="carousel"
      >
         <h2 > {this.props.sol} 
        <Tooltip title={ <div>1er : {this.props.premier} <br/> 2ème: {this.props.deuxieme}  <br/> 3ème : {this.props.troisieme}  <br/> 4ème :{this.props.quatrieme} <br/> {this.props.cinquieme ? <div> 5ème : {this.props.cinquieme} </div> : null} </div>} placement="top" enterTouchDelay={0}>
               <InfoIcon style={{color: "#1976D2"}}></InfoIcon>
            </Tooltip> </h2>
      {shuffle(this.items)}
      
      
      </div>
    );
  }
}

export default Carousel;
