import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { styled } from '@mui/material/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#1965D2"
    },
    secondary: {
      main: "#EF2F29"
    }
  }
});
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  borderRadius: 5,
}));

const styles = {
  primary: {
    main: "#ff0000" // very red
  },
  secondary: {
    main: "#00fff0" // very cyan
  }
};

class LinearDeterminate extends React.Component {
  state = {
    completed: 0,
    color: "primary"
  };

  componentDidMount() {
    this.timer = setInterval(this.progress, 500);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  progress = () => {
    const { completed } = this.state;
    if (completed === 100) {
      this.setState({ completed: 0 });
      
    } else {
      this.setState({ completed: completed + 10});
      completed < 50
        ? this.setState({ color: "primary" })
        : this.setState({ color: "secondary" });
        
    }
  };

  render() {
    return (
      <ThemeProvider theme={theme}>
        <div class="containerthermo">
        <div class="left" style={{color: this.state.color == "primary" ? "#1965D2" :"#EF2F29"}}>
          </div>
          <div class="thermoelement">
          <BorderLinearProgress
            
            variant="determinate"
            value={this.state.completed}
            color={this.state.color}
            
          />
          </div>
        </div>
      </ThemeProvider>
    );
  }
}

LinearDeterminate.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(LinearDeterminate);
