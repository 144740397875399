import * as React from 'react';
import { useRef, useState, useEffect} from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import CameraIcon from '@mui/icons-material/PhotoCamera';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ResponsiveAppBar from './header/header.js';
import Foot from "./header/footer.js"
import { useNavigate } from "react-router-dom";
import { palette } from '@mui/system';
import ShowerIcon from '@mui/icons-material/Shower';
import SavingsIcon from '@mui/icons-material/Savings';
import Divider from '@material-ui/core/Button';
import NewsCard2Demo from './solutions/heatpump.js';
import TimerSharpIcon from '@mui/icons-material/TimerSharp';
import HowToRegSharpIcon from '@mui/icons-material/HowToRegSharp';
import EnergySavingsLeafSharpIcon from '@mui/icons-material/EnergySavingsLeafSharp';
import Icon from '@mui/material/Icon';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { StyledEngineProvider } from "@mui/material/styles";
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import LinearDeterminate from './thermo.js'
import {
  IconFlagFR
} from 'material-ui-flags';
import EuroIcon from '@mui/icons-material/Euro';
import piggy from "./453-savings-pig-flatone.gif";
import'./Style.css'
import ProductCategories from "./ProductCate.js"



const useStyles = makeStyles(theme => ({
  btn: {
     
     borderColor: "#1976D2",
     color: "#1976D2",
     backgroundColor: "white", 
     "&:hover": {
         color: "#1976D2",
         borderColor:"#1976D2"
     },
  },

}));


const TestComponent = () => {
    const navigate = useNavigate();
    

    return (
        <Button variant="contained" size="large"  style={{
          
          backgroundColor: "#2196F3", borderRadius: 28
          
      }}  onClick={() => navigate('/test')}>Découvrir vos solutions</Button>
    );
};


const theme = createTheme({
  palette: {
    primary: {
      main: '#1976D2'
    },
}});


export default function Album() {
  const test = useRef(null);
  const articles = useRef(null);
  const engagement = useRef(null);
  const classes = useStyles();
  const animationbox = useRef();
  const isAnimationbox = useIsVisible(animationbox);

  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  };
  function useIsVisible(ref) {
    const [isIntersecting, setIntersecting] = useState(false);
  
    useEffect(() => {
      const observer = new IntersectionObserver(([entry]) =>
        setIntersecting(entry.isIntersecting)
      );
  
      observer.observe(ref.current);
      return () => {
        observer.disconnect();
      };
    }, [ref]);
  
    return isIntersecting;
  }
  



  return (
    <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <header>
      <AppBar position="static" color="primary" sx={{ bgcolor: "#1976D2" }}>
        <ResponsiveAppBar></ResponsiveAppBar>
      </AppBar>
      </header>
      <main>
        {/* Hero unit */}
        <Box
        
          sx={{
            bgcolor:'#F4F9F9',
            pt: 6,
            pb: 6,
            
          }}
          className = "backgroundpic"
        >
        
        <Box
          sx={{
            bgcolor:'#fff',
            padding: "2.5%",
            borderRadius: 6,
            boxShadow: 1
          }}
          className="animatedbox"
        >
          <Container maxWidth="sm">
          
            <Typography
              component="h1"
              variant="h1"
              align="center"
              color="#1976D2"
              size="large"
              fontFamily="monospace"
              marginBottom={1}
              className="titrepageprincipale" 

            >
              <b >SIMULATION </b>
              </Typography>
              <Typography
              component="h2"
              variant="h2"
              align="center"
              color="#1976D2"
              size="large"
              fontFamily="monospace"
              className="soustitrepageprincipale"
              
            >Chauffage & ECS</Typography>
             <Box className="thermo"  sx={{justifyContent:'center', width: '70%', m: 2.25}}>
             <LinearDeterminate></LinearDeterminate>
              </Box>
            
           
            <Stack
              
              direction="column"
              justifyContent="center"
            >
            <Typography letterSpacing={2} marginBottom={2.25} variant="h6" fontFamily="sans-serif" align="left" color="text.secondary" >
              <AddCircleOutlineRoundedIcon size="small" style={{color: "#1976D2", paddingTop:"5px", alignItems: 'center'}}></AddCircleOutlineRoundedIcon> précis, rapide et sans création de compte {"\n"}
              </Typography>
              <Typography variant="h6" letterSpacing={2} marginBottom={2.25}  align="left" fontFamily="sans-serif" color="text.secondary" >
               <AddCircleOutlineRoundedIcon size="small" style={{color: "#1976D2", paddingTop:"5px", alignItems: 'center'}}></AddCircleOutlineRoundedIcon> de <b className='bold'>5000 </b> solutions chauffage & eau chaude sanitaire
            </Typography>
            <Typography variant="h6" letterSpacing={2} fontFamily="sans-serif" align="left" color="text.secondary" >
              <AddCircleOutlineRoundedIcon size="small" style={{color: "#1976D2", paddingTop:"5px", alignItems: 'center'}}></AddCircleOutlineRoundedIcon> de <b className='bold'>50 000 </b> installateurs RGE partout en <IconButton  style={{ borderRadius: '30%' ,  fontSize: "150%", marginLeft:"-5px", alignItems: 'center'}}><IconFlagFR style={{ borderRadius: '30%', fontSize: "90%", alignItems: 'center'}}/></IconButton>
              </Typography>
              </Stack>
            <Stack
              sx={{ pt: 4 }}
              direction="row"
            
              justifyContent="center"
            >
              <Box>
              <TestComponent></TestComponent>
              
              </Box>
            </Stack>
          </Container>
          
        </Box>
        <Stack
              sx={{ pt: 7 }}
              direction="row"
              spacing={2}
              display= "flex" alignItems="center" justifyContent="center"
            >
               <Divider
          flexItem
          
          style={{backgroundColor: "#1976D2", width:'50%',height:"50%" }} > </Divider>
        <Button variant="outlined" size="large" className={classes.btn} style={{ borderRadius:"5%" }} onClick={() => scrollToSection(test)}>  Nos Solutions </Button>
        <Divider
          flexItem
          style={{backgroundColor: "#1976D2", width:'50%', height:"50%"  }} > </Divider>
        </Stack>
        </Box>
        <Box
          sx={{
            bgcolor:'#F4F9F9',
            pt: 10,
            pb: 8,
          }}
          
        ref={test}  className="test"
        >
         <Box  component="div" sx={{ display: 'inline',}}>
          <NewsCard2Demo></NewsCard2Demo> 
         
          </Box>
          <Stack
              sx={{ pt: 7 }}
              direction="row"
              spacing={3}
              display= "flex" alignItems="center" justifyContent="center"
            >
            <Divider
          flexItem
          
          style={{backgroundColor: "#1976D2", width:'50%',height:"50%" }} > </Divider>
        <Button variant="outlined"  size="large" className={classes.btn} style={{ borderRadius:"5%" }} onClick={() => scrollToSection(engagement)}> Nos Engagements </Button>
        <Divider
          flexItem
          
          style={{backgroundColor: "#1976D2", width:'50%',height:"50%" }} > </Divider>
        </Stack>
        </Box>


        <Box
        
          sx={{
            bgcolor:'#F4F9F9',
            pt: 7,
            pb: 6,
            
          }}
        ref={engagement}  className="backgroundpicbath" 
        >
          
        <Box
        
          sx={{
            bgcolor:'transparent',
            width: '80%',
            marginLeft: "10%",
            paddingLeft: "5%",
            paddingRight: "5%",
            paddingTop: "5%",
            paddingBottom: "5%",
            borderRadius: 6,
            justifyContent:'center',
            

          
          }}
          
        >
            <div ref={animationbox}></div>
          <Grid container spacing={4} display="flex"  alignItems="stretch" justifyContent="center">
         
          <Grid item  xs sm md lg>
          
          <Box
          
        className={isAnimationbox ? 'animatedbottombox' : ""}
          sx={{
            bgcolor:'#fff',
            padding: "2%",
            borderRadius: 6,
            boxShadow: 4,
            height:290,
            minWidth:260
           
          }}>
         
          <div class="clock"  ></div>
         
            <Typography
              variant="h4"
              align="center"
              color="#1976D2"
              size="large">Efficace</Typography>
              <Divider
          flexItem
          size="small"
          style={{backgroundColor: "#1976D2",width:'50%', marginLeft:"25%"}} >
        
      </Divider>

          <Typography mt={1.5} variant="h6" align="center"  paragraph>
            Obtenez vos résultats en 5 minutes et recevez des devis par email sans créer de compte    
            </Typography>
            
            
            
            
            </Box>
            </Grid>
          
            <Grid item xs sm md lg>
            <Box
          className={isAnimationbox ? 'animatedbottombox' : ""}
          sx={{
            bgcolor:'#fff',
            padding: "2%",
            borderRadius: 6,
            boxShadow: 4,
            height:290,
            minWidth:260
            
          
          }}
        >
          <Grid container justifyContent="center"  alignItems="center" >
            <Grid item  >
              
          <HowToRegSharpIcon className="animationiconcheck"  style={{fontSize: "400%", color: "#1976D2",alignItems: 'center',  }} ></HowToRegSharpIcon>
          </Grid>
          </Grid>
          
            <Typography
              variant="h4"
              align="center"
              color="#1976D2"
              size="large">Qualitatif</Typography>
              <Divider
          flexItem
          size="small"
          style={{backgroundColor: "#1976D2",width:'50%', marginLeft:"25%"}} >
        
      </Divider>
   

          <Typography mt={1.5} variant="h6" align="center"  paragraph>
          Des installateurs certifiés RGE, gage de qualité et condition pour recevoir les aides d'Etat
            </Typography>
            
            
            </Box>
            </Grid>

            <Grid item xs sm md lg>
            <Box
          className={isAnimationbox ? 'animatedbottombox' : ""}
          sx={{
            bgcolor:'#fff',
            padding: "2%",
            borderRadius: 6,
            boxShadow: 4,
            height:290,
            minWidth:260
          }}
        >
          <Grid container justifyContent="center"  alignItems="center" >
            <Grid item  >
            <img src={piggy}  class="piggygif"></img>
            </Grid>
            </Grid>
            <Typography
              variant="h4"
              align="center"
              color="#1976D2"
              size="large">Transparent</Typography>
              <Divider
          flexItem
          size="small"
          style={{backgroundColor: "#1976D2",width:'50%', marginLeft:"25%"}} >

        
      </Divider>
      

          <Typography mt={1.5} variant="h6" align="center"  paragraph>
          Un réseau d'installateurs RGE indépendants pour garantir un service de qualité au meilleur prix
            </Typography>
            
          
            
            
            </Box>
            </Grid>
          
           
            </Grid>
          

          </Box>
          <Stack
              sx={{ pt: 7 }}
              direction="row"
              spacing={2}
              display= "flex" alignItems="center" justifyContent="center"
            >
               <Divider
          flexItem
          
          style={{backgroundColor: "#1976D2", width:'50%',height:"50%" }} > </Divider>
        <Button variant="outlined" size="large" className={classes.btn} style={{ borderRadius:"5%" }} onClick={() => scrollToSection(articles)}>  Nos Derniers Articles </Button>
        <Divider
          flexItem
          style={{backgroundColor: "#1976D2", width:'50%', height:"50%"  }} > </Divider>
          </Stack>
        </Box>
        
        <Box
          sx={{
            bgcolor:'#F4F9F9',
            pt: 10,
            pb: 8,
          }}
          ref={articles}  className="articles"
        > 
        <ProductCategories></ProductCategories>
        </Box>
      </main>
      {/* Footer */}
      <Box  component="footer">
        <Foot></Foot>
      </Box>
      {/* End footer */}
    </ThemeProvider>
    </StyledEngineProvider>
  );
}