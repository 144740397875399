import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
} from "react-router-dom";
import './index.css';
import App from './App';
import Formsa from './Formsa';
import Contact from "./Contact";
import Apropos from "./Apropos";
import Articles from "./Articles"
import Pompe from "./ktgories/pompe.js";
import Poele from "./ktgories/poele.js"
import Chaudiere from "./ktgories/chaudiere.js";
import Radiateur from "./ktgories/radiateur.js";
import Chauffe from "./ktgories/chauffeeau.js";
import Articletemplate from "./ktgories/articletemplate.js";
import Charte from "./Charte";
import Timer from "./Timer.js"
import Home from './Home';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={ <App /> }>
          <Route path="/test" element={<Formsa />} />
          <Route path="/apropos" element={<Apropos />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/Articles" element={<Articles />} />
          <Route path="/donnees" element={<Charte/>} />
          <Route path="/timerBessie" element={<Timer/>} />
          <Route path="/pompe_a_chaleur" element={<Pompe/>} />
            <Route path="/pompe_a_chaleur/:id" element={<Pompe categorie="pompe_a_chaleur"/>} />
            <Route path="/pompe_a_chaleur/:firstid/:id" element={<Articletemplate categorie="pompe_a_chaleur" />} />
          <Route path="/poele" element={<Poele/>} />
          <Route path="/poele/:id" element={<Poele/>} />
          <Route path="/poele/:firstid/:id" element={<Articletemplate categorie="poele" />} />
          <Route path="/chauffe_eau" element={<Chauffe/>} />
          <Route path="/chauffe_eau/:id" element={<Chauffe categorie="chauffe_eau"/>} />
          <Route path="/chauffe_eau/:firstid/:id" element={<Articletemplate categorie="chauffe_eau" />} />
          <Route path="/chaudiere" element={<Chaudiere/>} />
          <Route path="/chaudiere/:id" element={<Chaudiere/>} />
          <Route path="/chaudiere/:firstid/:id" element={<Articletemplate categorie="chaudiere" />} />
          <Route path="/radiateur" element={<Radiateur/>} />
          <Route path="/radiateur/:id" element={<Radiateur/>} />
          <Route path="/radiateur/:firstid/:id" element={<Articletemplate categorie="radiateur" />} />

        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
