import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
} from "react-router-dom";
import Formsa from './Formsa';
import Home from './Home';
import Contact from "./Contact";
import Apropos from "./Apropos";
import Charte from "./Charte";
import Articles from "./Articles"
import Timer from "./Timer.js"
import Pompe from "./ktgories/pompe.js";
import Poele from "./ktgories/poele.js"
import Chaudiere from "./ktgories/chaudiere.js";
import Radiateur from "./ktgories/radiateur.js";
import Chauffe from "./ktgories/chauffeeau.js"
import Articletemplate from "./ktgories/articletemplate.js"


export default class App extends Component {
  render() {
    return (
    
      <div className="App-intro">
          <Routes>
          <Route path="/" element={<Formsa />} />
            <Route path="/test" element={<Home />} />
            <Route exact path="/apropos" element={<Apropos />} />
            <Route exact path="/contact" element={<Contact />} />
            <Route exact path="/Articles" element={<Articles />} />
            <Route path="/timerBessie" element={<Timer/>} />
            <Route exact path="/donnees" element={<Charte />} />
            <Route path="/pompe_a_chaleur" element={<Pompe/>} />
            <Route path="/pompe_a_chaleur/:id" element={<Pompe categorie="pompe_a_chaleur"/>} />
            <Route path="/pompe_a_chaleur/:firstid/:id" element={<Articletemplate categorie="pompe_a_chaleur" />} />
          <Route path="/poele" element={<Poele/>} />
          <Route path="/poele/:id" element={<Poele/>} />
          <Route path="/poele/:firstid/:id" element={<Articletemplate categorie="poele" />} />
          <Route path="/chauffe_eau" element={<Chauffe/>} />
          <Route path="/chauffe_eau/:id" element={<Chauffe categorie="chauffe_eau"/>} />
          <Route path="/chauffe_eau/:firstid/:id" element={<Articletemplate categorie="chauffe_eau" />} />
          <Route path="/chaudiere" element={<Chaudiere/>} />
          <Route path="/chaudiere/:id" element={<Chaudiere/>} />
          <Route path="/chaudiere/:firstid/:id" element={<Articletemplate categorie="chaudiere" />} />
          <Route path="/radiateur" element={<Radiateur/>} />
          <Route path="/radiateur/:id" element={<Radiateur/>} />
          <Route path="/radiateur/:firstid/:id" element={<Articletemplate categorie="radiateur" />} />
           
          </Routes>
        </div>
      
    );
  }
}

ReactDOM.render(
  <BrowserRouter>
      <App />
  </BrowserRouter>
  , document.getElementById('root')
);
