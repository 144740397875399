const reno = {
    0.28: '2012 et 2020',
    0.75: '2007 et 2012',
    0.8: '2001 et 2006',
    1.1: '1990 et 2000',
    1.15: '1983 et 1989',
    1.41:'1978 et 1982',
    1.4: '1975 et 1977',
    1.5: '1970 et 1974',
    1.6: 'Années 60',
    1.8: 'Années 50',
    1.99:'Avant années 50'

  }
  export default Object.keys(reno).map(value => ({
    value: value,
    label: reno[value]
  }))