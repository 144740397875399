import React, { useState, useRef, useEffect } from 'react'
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import CameraIcon from '@mui/icons-material/PhotoCamera';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ResponsiveAppBar from './header/header.js';
import Foot from "./header/footer.js"
import { useNavigate } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import CustomizedTables from './table_charte.js';
import'./Style.css';
import CustomizedTablesbis from './table_chartebis.js';
import goose from "./sillygoose.jpg";


const Time = () => {
    const [seconds, setSeconds] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [days, setDays] = useState(0);
  
    useEffect(() => {
      const interval = setInterval(() => {
        var now = new Date()
        const statiDate = new Date("2023/02/27 12:30:00")
        
        var distance = statiDate - now
        console.log(distance)
        setDays(Math.floor(distance / (1000 * 60 * 60 * 24)));
        
        setHours(Math.floor(
    (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  ));
        setMinutes(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
        setSeconds(Math.floor((distance % (1000 * 60)) / 1000));
        
      }, 1000);
      return () => clearInterval(interval);
    }, []);
  
    return (
      <div class="centered" >
        <img src={goose}  class="sillygoose"></img>
      <h1>
          { days + " Days " + hours + " Hours " + minutes + " Min " + seconds } 
      </h1>
      </div>
    );
  };

const useStyles = makeStyles(theme => ({
  btn: {
     
     borderColor: "#2196F3",
     color: "#2196F3",
     backgroundColor: "white", 
     "&:hover": {
         color: "#2196F3",
         borderColor:"#2196F3"
     },
  },

}));

const theme = createTheme({});

const TestComponent = () => {
    const navigate = useNavigate();

    return (
        <Button variant="contained"style={{
          
          backgroundColor: "#2196F3", borderRadius:"5%"
          
      }} onClick={() => navigate('/test')}>Passer le test</Button>
    );
};

const Aproposbutton = () => {
  const navigate = useNavigate();
  const classes = useStyles();


  return (
    <ThemeProvider theme={theme}>
      <Button variant="outlined" className={classes.btn} onClick={() => navigate('/Apropos')}>A Propos</Button>
      </ThemeProvider>
  );
};




export default function Timer() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="relative">
        <ResponsiveAppBar></ResponsiveAppBar>
      </AppBar>
      <main>
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: '#F4F9F9',
            pt: 8,
            pb: 6,
          }}
        >
          <Box
          sx={{
            bgcolor:'#fff',
            width: '60%',
            padding: "3%",
            marginLeft: "20%",
            borderRadius: 6,
            boxShadow: 1
          }}
        >
          <Time></Time>
          </Box>
        </Box>
      </main>
      {/* Footer */}
      <Box  component="footer">
        <Foot></Foot>
      </Box>
      {/* End footer */}
    </ThemeProvider>
  );
}